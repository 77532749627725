import * as React from "react"

import { Button, Col, Form, FormControl, FormGroup, Modal } from "react-bootstrap"

interface AddStockLocationModalProps {
    addStockLocation: (stockLocationId: string, shopifyLocationId: string) => void
    hideAddStockLocationModal: () => void
}

interface AddStockLocationModalState {
    isAddStockLocationInputValid: boolean
    newStockLocationId: string
    newShopifyLocationId: string
}

export class AddStockLocationModal extends React.Component<AddStockLocationModalProps, AddStockLocationModalState> {

    constructor(props: AddStockLocationModalProps) {
        super(props)
        this.state = {
            isAddStockLocationInputValid: false,
            newStockLocationId: "",
            newShopifyLocationId: ""
        }
    }

    // Helpers

    handleStockLocationIdChange = (event: any) => {
        const target = event.target
        const value: string = target.value || ""
        const valid = value.length > 0 && this.state.newShopifyLocationId.length > 0
        this.setState({ newStockLocationId: value, isAddStockLocationInputValid: valid })
    }

    handleShopifyLocationIdChange = (event: any) => {
        const target = event.target
        const value: string = target.value ?? ""
        const valid = value.length > 0 && this.state.newStockLocationId.length > 0
        this.setState({ newShopifyLocationId: value, isAddStockLocationInputValid: valid })
    }

    handleAddButtonClick = () => {
        this.props.addStockLocation(this.state.newStockLocationId, this.state.newShopifyLocationId)
        this.setState({ isAddStockLocationInputValid: false, newShopifyLocationId: "", newStockLocationId: "" })
    }

    // Componenet

    render() {
        return (
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>
                        Add stock location
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={this.state.newStockLocationId.length > 0}>
                        <FormGroup >
                            <Col sm={3}>Ka-ching stock location id</Col>
                            <Col sm={9}>
                                <FormControl
                                    type="text"
                                    name="new_stock_location_id"
                                    value={this.state.newStockLocationId}
                                    placeholder={`Enter Ka-ching stock location id`}
                                    onChange={event => { this.handleStockLocationIdChange(event) }}
                                    autoComplete="off"
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                    <br /> <br />
                    <Form noValidate validated={this.state.newShopifyLocationId.length > 0}>
                        <FormGroup>
                            <Col sm={3}>Shopify location id</Col>
                            <Col sm={9}>
                                <FormControl
                                    type="text"
                                    name="new_shopify_location_id"
                                    value={this.state.newShopifyLocationId}
                                    placeholder={`Enter Shopify location id`}
                                    onChange={event => { this.handleShopifyLocationIdChange(event) }}
                                    autoComplete="off"
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                    <br />
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { this.handleAddButtonClick() }} disabled={!this.state.isAddStockLocationInputValid}>Add</Button>
                    <Button onClick={this.props.hideAddStockLocationModal}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}