import React, { useState } from "react";
import { PDFTemplateLine, PDFTemplateLineType } from "src/models/pdf_template/pdf_template_line";
import _ from "lodash";
import { EditBody, UpdateLineItemCallback } from "./ComponentEdit/ComponentEdit";
import { MenuItem, Button, DropdownButton, Modal, Col, Form, Row } from "src/components/wrappers";
import { FormGroup } from "react-bootstrap";

interface AddLineItemProps {
    customFonts: string[]
    onOK: (line: PDFTemplateLine) => void;
    onCancel: () => void;
}
export function AddLineItem(props: AddLineItemProps) {
    const [selectedType, setSelectedType] = useState<PDFTemplateLineType>()
    const [lineItem, setLineItem] = useState<PDFTemplateLine>();

    return (
        <Modal show={true} size="lg">
            <Modal.Header>
                <Modal.Title> {getEditTitle(selectedType)} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <DropdownButton 
                    id="dropdown-basic-button"
                    style={{float:"right"}}
                    title={menuItemTitle(selectedType)}
                    key="attribute_type"
                    >
                        {getMenuItems()}
                </DropdownButton>
                <br></br>
                <br></br>
                {!_.isNil(lineItem) ?
                
                <EditBody updateLineItem={updateLineItem} lineItem={lineItem} customFonts={props.customFonts}></EditBody>
                : <div></div>}
                
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={props.onCancel}>Cancel</Button>
                {
                    !_.isNil(lineItem) ? 
                    <Button variant="primary" disabled={false} onClick={() => { 
                        props.onOK(lineItem)
                    }}> OK </Button>
                    : <div></div>
                }
                
            </Modal.Footer>

        </Modal>
    );

    function updateLineItem(callback: UpdateLineItemCallback) {
        let updatedLine = _.cloneDeep(lineItem);
        if(_.isNil(updatedLine)) { 
            updatedLine = new PDFTemplateLine(PDFTemplateLineType.text)
         }
        updatedLine = callback(updatedLine);
        setLineItem(updatedLine);
    }

    function getMenuItems(): React.ReactNode[] {
        const types = [
            PDFTemplateLineType.text, 
            PDFTemplateLineType.spacer,
            PDFTemplateLineType.wallet_url,
            PDFTemplateLineType.image,
            PDFTemplateLineType.barcode
        ]
        return types.map((value: PDFTemplateLineType) => {
            return (
                <MenuItem
                    key={value}
                    onClick={() => {
                        const updatedLineItem = new PDFTemplateLine(value)
                        setSelectedType(value)
                        updateLineItem((lineItem) => {
                            return updatedLineItem
                        })
                    } }
                >
                {menuItemTitle(value)}
                </MenuItem>
            );
        })
    }
}

function menuItemTitle(type: PDFTemplateLineType | undefined): string {
    if (_.isNil(type)) return "No value selected"

    switch (type) {
        case PDFTemplateLineType.text:
            return "Text"
        case PDFTemplateLineType.wallet_url:
            return "Wallet"
        case PDFTemplateLineType.barcode:
            return "Barcode"
        case PDFTemplateLineType.image:
            return "Image"
        case PDFTemplateLineType.spacer:
            return "Spacer"
    }
}

function getEditTitle(type: PDFTemplateLineType | undefined) {
    let title = "Add new "
    if (_.isNil(type)) { return title }
    switch (type) {
        case PDFTemplateLineType.text:
            return title + "text component"
        case PDFTemplateLineType.wallet_url:
            return title + "Wallet component";
        case PDFTemplateLineType.barcode:
            return title + "barcode component";
        case PDFTemplateLineType.image:
            return title + "image component";
        case PDFTemplateLineType.spacer:
            return title + "spacer component";
    }
}
