import React from "react";
import _ from "lodash";
import { LineItemProps } from "./TextLineItem";
import { DeleteButton } from "src/components/DeleteButton";

export function WalletLineItem(props: LineItemProps) {
    return <div className="component-container">
        <div className="info-container">
            <div className="container">
                <span className="label">Type:</span>
                <span className="value">Wallet URL</span>
            </div>
            <div className="container">
                <span className="label">Value:</span>
                <span className="value">{props.lineItem.text}</span>
            </div>
            {!_.isNil(props.lineItem.alignment) ?
                <div className="container">
                    <span className="label">Alignment:</span>
                    <span className="value">{props.lineItem.alignment}</span>
                </div> : null}
            {!_.isNil(props.lineItem.spacing) ?
                <div className="container">
                    <span className="label">Spacing:</span>
                    <span className="value">{props.lineItem.spacing}</span>
                </div> : null}
            {!_.isNil(props.lineItem.barcodeType) ?
                <div className="container">
                    <span className="label">Barcode type:</span>
                    <span className="value">{props.lineItem.barcodeType}</span>
                </div> : null}
        </div>
        <div className="button-container">
            <DeleteButton onDelete={props.onDelete}></DeleteButton>
        </div>
    </div>;
}
