import React from "react";
import { PDFTemplateLine } from "src/models/pdf_template/pdf_template_line";
import { DescriptionCol, Col, ToggleButtonGroup,FormGroup, Row, ToggleButton } from "../../../../../../wrappers";
import { barcodeTypes, barcodeTypePrettyName } from "../../../shared";
import { ValueEditProps } from "../../ComponentEdit";


export function BarcodeValueEdit(props: ValueEditProps) {
    return <FormGroup className="mb-3" as={Row}>
        <DescriptionCol sm={3}>Barcode type</DescriptionCol>
        <Col sm={9}>
            <ToggleButtonGroup
                type="radio"
                name="barcode_type"
                value={props.lineItem.barcodeType ?? ""}
                onChange={(value) => {
                    console.log("DINO ON CHANGE")
                    props.updateLineItem((lineItem) => {
                        lineItem.barcodeType = value;
                        return lineItem;
                    });
                }}
            >
                {barcodeTypes.map((barcodeType) => {
                    return <ToggleButton id={barcodeType} key={barcodeType} value={barcodeType}>{barcodeTypePrettyName(barcodeType)}</ToggleButton>;
                })}
            </ToggleButtonGroup>
        </Col>
    </FormGroup>;
}
