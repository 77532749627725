import { firestore, ref } from "../config/constants"
import { Language, LanguageCode, L10nString } from "./L10n"
import { Currency, Market, Channel, Tax, TaxType } from "../models/MarketModels"
import { Dictionary } from "lodash"
import _ from "lodash"

let accountKey: string | null = null
let _markets: Market[] = []
let _marketsLoaded: boolean = false
let _shops: _.Dictionary<string> = {}
let _shopsLoaded: boolean = false
let _shopsCallbacks: ((value: _.Dictionary<string>) => void)[] = []
let _currencies: Currency[] = []
let _currenciesLoaded: boolean = false
let _languages: Dictionary<Language> = {}
let _languagesLoaded: boolean = false
let _languagesCallbacks: ((value: Dictionary<Language>) => void)[] = []
let _currenciesCallbacks: ((value: Currency[]) => void)[] = []
let _marketsCallbacks: ((value: Market[]) => void)[] = []
let _channels: Channel[] = []
let _channelsLoaded: boolean = false
let _channelsCallbacks: ((value: Channel[]) => void)[] = []
let _taxes: Tax[] = []
let _taxesLoaded: boolean = false
let _taxesCallbacks: ((value: Tax[]) => void)[] = []

const channelNames = { online: "E-com", pos: "POS", "cc": "Customer Centric" }

export class Globals {

    private static _instance: Globals

    private constructor() {
    }

    public static get shared() {
        return this._instance || (this._instance = new this())
    }

    unsubscribeCurrencies?: (() => void)
    unsubscribeLanguages?: (() => void)

    setAccount(key: string | null) {
        _marketsLoaded = false
        _currenciesLoaded = false
        _languagesLoaded = false
        _channelsLoaded = false
        _taxesLoaded = false
        _shopsLoaded = false
        _marketsCallbacks = []
        _currenciesCallbacks = []
        _languagesCallbacks = []
        _channelsCallbacks = []
        _taxesCallbacks = []
        _shops = {}

        if (accountKey) {
            ref().child(`v1/accounts/${accountKey}/markets`).off()
            ref().child(`v1/accounts/${accountKey}/channels`).off()
            ref().child(`v1/accounts/${accountKey}/taxes`).off()
            ref().child(`v1/accounts/${accountKey}/shop_index`).off()
        }

        accountKey = key
        if (accountKey) {
            ref().child(`v1/accounts/${accountKey}/markets`).on("value", snapshot => {
                if (!snapshot) {
                    return
                }
                _markets = []
                const marketsDict = snapshot.val() || {}
                
                for (const marketKey in marketsDict) {
                    const value = marketsDict[marketKey]
                    let productGroupTaxesMap: Map<string, string[]> = new Map()
                    if (!_.isNil(value.product_group_taxes)) {
                        for (const key of Object.keys(value.product_group_taxes)) {
                            productGroupTaxesMap.set(key, Object.keys(value.product_group_taxes[key]))
                        }
                    }
                    
                    _markets.push({
                        id: marketKey,
                        name: value.name,
                        currency: value.currency,
                        taxes: new Set(Object.keys(value.taxes || {})),
                        product_group_taxes: productGroupTaxesMap
                    })
                }
                _marketsLoaded = true
                for (const callback of _marketsCallbacks) {
                    callback(_markets)
                }
                _marketsCallbacks = []
            })

            ref().child(`v1/accounts/${accountKey}/shop_index`).on("value", snapshot => {
                if (!snapshot) {
                    return
                }
                _shops = {}
                const shopsDict = snapshot.val() || {}
                for (const shopKey in shopsDict) {
                    const value = shopsDict[shopKey]
                    if (value.deactivated === true) {
                        continue
                    }
                    _shops[shopKey] = value.name
                }
                _shopsLoaded = true
                for (const callback of _shopsCallbacks) {
                    callback(_shops)
                }
                _shopsCallbacks = []
            })

            ref().child(`v1/accounts/${accountKey}/channels`).on("value", snapshot => {
                if (!snapshot) {
                    return
                }
                _channels = []
                const channelsDict = snapshot.val() || {}
                for (const channelKey in channelsDict) {
                    _channels.push({
                        id: channelKey,
                        name: channelNames[channelKey],
                    })
                }
                _channelsLoaded = true
                for (const callback of _channelsCallbacks) {
                    callback(_channels)
                }
                _channelsCallbacks = []
            })

            ref().child(`v1/accounts/${accountKey}/taxes`).on("value", snapshot => {
                if (!snapshot) {
                    return
                }
                _taxes = []
                const taxesDict = snapshot.val() || {}
                for (const taxKey in taxesDict) {
                    const value = taxesDict[taxKey]
                    _taxes.push(new Tax({
                        id: taxKey,
                        rate: value.rate,
                        name: value.name,
                        type: value.type
                    }))
                }

                _taxes = _taxes.sort((a: Tax, b: Tax) => {
                    return a.name.localeCompare(b.name)
                })
                _taxesLoaded = true
                for (const callback of _taxesCallbacks) {
                    callback(_taxes)
                }
                _taxesCallbacks = []

            })
        }

        if (this.unsubscribeCurrencies) {
            this.unsubscribeCurrencies()
        }
        this.unsubscribeCurrencies = firestore.collection("currencies").onSnapshot(snapshot => {
            if (!snapshot) {
                return
            }
            _currencies = []
            for (const doc of snapshot.docs) {
                _currencies.push({
                    currencyCode: doc.id
                })
            }

            _currencies = _currencies.sort((a: Currency, b: Currency) => {
                return a.currencyCode.localeCompare(b.currencyCode)
            })
            _currenciesLoaded = true
            for (const callback of _currenciesCallbacks) {
                callback(_currencies)
            }
            _currenciesCallbacks = []
        })

        if (this.unsubscribeLanguages) {
            this.unsubscribeLanguages()
        }
        this.unsubscribeLanguages = firestore.collection("languages").onSnapshot(snapshot => {
            if (!snapshot) {
                return
            }
            _languages = {}
            for (const languageSnapshot of snapshot.docs) {
                const value = languageSnapshot.data() ?? {}
                const code = LanguageCode[languageSnapshot.id]
                if (code !== undefined) {
                    _languages[languageSnapshot.id] = {
                        languageCode: LanguageCode[languageSnapshot.id],
                        name: new L10nString(value)
                    }
                }
            }
            console.log("LANGS", _languages)
            _languagesLoaded = true
            for (const callback of _languagesCallbacks) {
                callback(_languages)
            }
            _languagesCallbacks = []
        })
    }

    getMarkets(): Promise<Market[]> {
        return new Promise<Market[]>((resolve, reject) => {
            if (_marketsLoaded) {
                resolve(_markets)
            } else {
                _marketsCallbacks.push(resolve)
            }
        })
    }

    getShops(): Promise<_.Dictionary<string>> {
        return new Promise<_.Dictionary<string>>((resolve, reject) => {
            if (_shopsLoaded) {
                resolve(_shops)
            } else {
                _shopsCallbacks.push(resolve)
            }
        })
    }

    getChannels(): Promise<Channel[]> {
        return new Promise<Channel[]>((resolve, reject) => {
            if (_channelsLoaded) {
                resolve(_channels)
            } else {
                _channelsCallbacks.push(resolve)
            }
        })
    }

    getCurrencies(): Promise<Currency[]> {
        return new Promise<Currency[]>((resolve, reject) => {
            if (_currenciesLoaded) {
                resolve(_currencies)
            } else {
                _currenciesCallbacks.push(resolve)
            }
        })
    }

    getLanguages(): Promise<Dictionary<Language>> {
        return new Promise<Dictionary<Language>>((resolve, reject) => {
            if (_languagesLoaded) {
                resolve(_languages)
            } else {
                _languagesCallbacks.push(resolve)
            }
        })
    }

    getTaxes(): Promise<Tax[]> {
        return new Promise<Tax[]>((resolve, reject) => {
            if (_taxesLoaded) {
                resolve(_taxes)
            } else {
                _taxesCallbacks.push(resolve)
            }
        })
    }

    static async getMarket(shopId: string): Promise<string> {
        const shopMarketPath = `v1/accounts/${accountKey}/shops/${shopId}/market`
        const stockLocationMarketPath = `v1/accounts/${accountKey}/stock_locations/${shopId}/market`
        const shopMarketSnap = await ref().child(shopMarketPath).get()
        if (shopMarketSnap.exists()) {
            return shopMarketSnap.val()
        } else {
            const stockLocationMarketSnap = await ref().child(stockLocationMarketPath).get()
            return stockLocationMarketSnap.val()
        }
    }
}
