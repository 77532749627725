import * as _ from "lodash"
import firebase from "firebase/compat"
import { firestore, ref } from "../config/constants"
import { sortLikeFirebaseOrderByKey } from "../helpers/sorting"
import { StockCountLineItem } from "../models/StockCountModels"
import { StockCountProductInfo } from "../models/StockCountModels"
import { Globals } from "../helpers/globals"

export enum StockCountFilterType {
    ALL = "all",
    DIFF_ONLY = "diff_only",
    NOT_COUNTED = "not_counted",
    NEGATIVE_EXPECTED_COUNT = "negative_expected_count"
}


export async function fetchPage(path: string, limit: number, type: StockCountFilterType, fromLineItemId?: string): Promise<any> {
    let query = ref().child(path).limitToFirst(limit + 1)
    switch (type) {
        case StockCountFilterType.ALL: {
            query = query.orderByKey()
            if (fromLineItemId) {
                query = query.startAt(fromLineItemId)
            }
            return (await query.once("value")).val()
        }
        case StockCountFilterType.DIFF_ONLY: {
            const over = (await query.orderByChild("diff").startAt(-2147483648, fromLineItemId).endAt(-1).once("value")).val() || {}
            const under = (await query.orderByChild("diff").startAt(1, fromLineItemId).endAt(2147483648).once("value")).val() || {}
            const allKeys = Object.keys(over).concat(Object.keys(under))
            const sortedToLimit = _.take(sortLikeFirebaseOrderByKey(allKeys), limit)
            const result = {}
            for (const key of sortedToLimit) {
                const value = over[key] || under[key]
                if (!_.isNil(value)) {
                    result[key] = value
                }
            }
            return result
        }
        case StockCountFilterType.NOT_COUNTED:
            return (await query.orderByChild("has_count").startAt(false, fromLineItemId).endAt(false).once("value")).val()
    }
}

export class StockCountLinesQueryService {

    private pageLimit: number
    private filter: StockCountFilterType
    private accountId: string
    private shopId: string
    private stockCountId: string
    private query?: firebase.database.Query
    private query2?: firebase.database.Query
    private productCache: _.Dictionary<any> = {}

    linesChangedCallback: (values: StockCountLineItem[]) => void = () => { }

    constructor(pageLimit: number, filterType: StockCountFilterType, accountId: string, shopId: string, stockCountId: string) {
        this.pageLimit = pageLimit
        this.filter = filterType
        this.accountId = accountId
        this.shopId = shopId
        this.stockCountId = stockCountId
    }

    start(lineItem: StockCountLineItem | undefined) {
        // turn off current query if present
        this.stop()

        // configure query set
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/counts/${this.stockCountId}/lines`
        switch (this.filter) {
            case StockCountFilterType.ALL: {
                let allQuery = ref().child(path).limitToFirst(this.pageLimit + 1)
                allQuery = allQuery.orderByKey()
                if (lineItem) {
                    allQuery = allQuery.startAt(lineItem.id)
                }
                this.query = allQuery
                this.query2 = undefined
                break
            }

            case StockCountFilterType.DIFF_ONLY: {
                const startAt = -2147483648
                const diffQueryUnder = ref().child(path).limitToFirst(this.pageLimit + 1).orderByChild("diff").startAt(startAt, lineItem?.id).endAt(-1)
                const diffQueryOver = ref().child(path).limitToFirst(this.pageLimit + 1).orderByChild("diff").startAt(1, lineItem?.id).endAt(2147483648)
                this.query = diffQueryUnder
                this.query2 = diffQueryOver
                break
            }

            case StockCountFilterType.NOT_COUNTED: {
                const ncQuery = ref().child(path).limitToFirst(this.pageLimit + 1).orderByChild("has_count").startAt(false, lineItem?.id).endBefore(true)
                this.query = ncQuery
                this.query2 = undefined
                break
            }
            case StockCountFilterType.NEGATIVE_EXPECTED_COUNT: {
                const startAt = lineItem?.expected ?? -2147483648
                const negativeExpectedCountQuery = ref().child(path).limitToFirst(this.pageLimit + 1).orderByChild("expected").startAt(startAt, lineItem?.id).endAt(-1)
                this.query = negativeExpectedCountQuery
                this.query2 = undefined
                break
            }
        }

        // Calling async from sync code. This is ok as we don't return any sync response
        this.executeQuery()
    }

    async executeQuery() {
        const marketId = await Globals.getMarket(this.shopId)
        let lineItems: StockCountLineItem[] = []
        if (!_.isNil(this.query)) {
            const productIds: Set<string> = new Set()
            this.query.on("value", (snapshot: firebase.database.DataSnapshot) => {
                this.handleSnapshot(lineItems, snapshot, productIds, marketId)
            })
        }
        if (!_.isNil(this.query2)) {
            const productIds: Set<string> = new Set()
            this.query2.on("value", (snapshot: firebase.database.DataSnapshot) => {
                this.handleSnapshot(lineItems, snapshot, productIds, marketId)
            })
        }
    }

    private handleSnapshot(lineItems: StockCountLineItem[], snapshot: firebase.database.DataSnapshot, productIds: Set<string>, marketId: string) {
        snapshot.forEach((child) => {
            const value = child.val()
            if (_.isObject(value.product)) {
                productIds.add(value.product.product_id)
                const lineItem = new StockCountLineItem(value)
                const existingIndex = lineItems.findIndex(existing => {
                    return existing.id == lineItem.id
                })
                if (existingIndex !== -1) {
                    lineItems[existingIndex] = lineItem
                } else {
                    lineItems.push(lineItem)
                }
            }
        })
        this.linesChangedCallback(lineItems)
        for (const productId of productIds) {
            // Calling async from sync code. This is ok as we don't return any sync response
            this.fetchProduct(productId, marketId, lineItems)
        }
    }

    async fetchProduct(productId: string, marketId: string, lineItems: StockCountLineItem[]) {
        if (!_.isNil(this.productCache[productId])) {
            this.addProduct(productId, this.productCache[productId], lineItems)
        } else {
            const path = `accounts/${this.accountId}/inventory/${marketId}.pos/product_search_index/${productId}`
            const snapshot = await firestore.doc(path).get()
            if (snapshot.exists) {
                this.productCache[snapshot.id] = snapshot.data()?.product
                this.addProduct(snapshot.id, this.productCache[snapshot.id], lineItems)
            } else {
                // Find name if product deleted
                const rtdbPath = `v1/accounts/${this.accountId}/inventory/products/pos/${marketId}/${productId}`
                const snap = await ref().child(rtdbPath).get()
                const val = snap.val() ?? {}
                val.deleted = true
                this.productCache[snapshot.id] = val
                this.addProduct(snapshot.id, val, lineItems)
            }
        }
    }

    addProduct(productId: string, product: any, lineItems: StockCountLineItem[]) {
        for (const lineItem of lineItems) {
            if (lineItem.product.productId === productId) {
                const variantId = lineItem.product.variantId
                lineItem.product = new StockCountProductInfo(product ?? {}, productId, variantId)
            }
        }

        this.linesChangedCallback(lineItems)
    }

    stop() {
        this.query?.off()
        this.query2?.off()
    }
}
