import _ from "lodash"
import { AttributeSelection } from "../components/protected/DiscountRules/AppliesToSelector"
import { StockCountFilter } from "./StockCountFilter"

export interface StockCountRequest {
    name: string
    filter: StockCountFilter
    shops: string[]
    dueDate: Date
    creationDate: Date
    id: string
    createdBy: string
    status: "completed" | "cancelled" | "in_progress" | "created" | "completed_with_cancellations"
}

function attributeSelectionToJSON(attributeSelection: AttributeSelection) {
    const val: any = {
        id: attributeSelection.attributeId,
        value: attributeSelection.optionId,
    }
    if (attributeSelection.comparison !== undefined) {
        val.comparison = attributeSelection.comparison
    }
    return val
}

export function stockCountFilterToJSON(filter: StockCountFilter): any {
    const result: any = {}
    if (!_.isNil(filter.filterDate)) {
        result.filter_date = filter.filterDate
    }
    if (!_.isNil(filter.filterAttributes)) {
        const attrs: any[] = filter.filterAttributes.map(attr => { return attributeSelectionToJSON(attr) })
        result.filter_attributes = attrs
    }
    if (filter.filterDeletedOnly === true) {
        result.filter_deleted_only = true
    }
    return result
}

function attributeSelectionFromJSON(json: any): AttributeSelection {
    const attr: AttributeSelection = {
        attributeId: json.id,
        optionId: json.value
    }
    if (!_.isNil(json.comparison)) {
        attr.comparison = json.comparison
    }
    return attr
}

function stockCountFilterFromJSON(json: any): StockCountFilter {
    const filter: StockCountFilter = {}
    if (!_.isNil(json.filter_date)) {
        filter.filterDate = json.filter_date.toDate()
    }
    if (!_.isNil(json.filter_attributes)) {
        const attrs: any[] = json.filter_attributes.map((attr: any) => { return attributeSelectionFromJSON(attr) })
        filter.filterAttributes = attrs
    }
    if (json.filter_deleted_only === true) {
        filter.filterDeletedOnly = true
    }
    return filter
}

export function stockCountRequestToJSON(request: StockCountRequest): any {
    return {
        name: request.name,
        filter: stockCountFilterToJSON(request.filter),
        shops: request.shops,
        due_date: request.dueDate,
        creation_date: request.creationDate,
        id: request.id,
        created_by: request.createdBy,
        status: request.status
    }
}

export function stockCountRequestFromJSON(json: any): StockCountRequest {
    return {
        name: json.name,
        filter: stockCountFilterFromJSON(json.filter),
        shops: json.shops,
        dueDate: json.due_date.toDate(),
        creationDate: json.creation_date.toDate(),
        id: json.id,
        createdBy: json.created_by,
        status: json.status
    }
}
