//
//  StockRemovalReasonCodeList.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 6/08/2021.
//  Copyright © 2021 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button, Card } from "../../wrappers"
import { forEach } from "lodash"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { StripedTable } from "../../StripedTable"
import { StockRemovalReasonCode } from "../../../models/StockRemovalReasonCode"
import { LanguageCode } from "../../../helpers/L10n"
import { RoleRouterProps, withRouter } from "src/routes"

interface StockRemovalReasonCodeListState {
    codes: StockRemovalReasonCode[]
    currentLanguage: LanguageCode | null
    loaded: boolean
}

class StockRemovalReasonCodeList extends React.Component<RoleRouterProps, StockRemovalReasonCodeListState> {

    // Properties

    initialState: StockRemovalReasonCodeListState = {
        codes: [],
        currentLanguage: LanguageCode.en,
        loaded: false
    }

    // Component

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.loadState()
    }

    componentWillUnmount() {
        this.reasonCodesRef().off()
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="stock removal reason codes">
                <Card className="my-4">
                    <Card.Header>
                        {"Stock removal reason codes configuration"}
                    </Card.Header>

                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.codes.map(code => {
                                return (
                                    <tr key={code.id} onClick={() => { this.editStockRemovalReasonCode(code.id) }}>
                                        <td>{code.id}</td>
                                        <td>{code.description.localized(this.state.currentLanguage) ?? code.description.value}</td>
                                        <td className="narrow">
                                            <ConfirmDeleteButton
                                                message={`Really delete ${code.description.localized(this.state.currentLanguage)} code? This operation will not modify any products using the tag. Only the display name will be removed.`}
                                                onDelete={async () => { await this.removeStockRemovalReasonCode(code.id) }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </StripedTable>
                    <Card.Footer>
                        <Button onClick={this.newStockRemovalReasonCode}>New reason code</Button>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }

    // State

    loadState() {
        this.reasonCodesRef()
            .on("value", snapshot => {
                if (!snapshot || (snapshot && !snapshot.exists())) {
                    this.setState({ codes: [], loaded: true })
                    return
                }

                const codes: StockRemovalReasonCode[] = []
                forEach(snapshot.val(), (value) => {
                    const code = new StockRemovalReasonCode(value)
                    codes.push(code)
                })

                const sorted = codes.sort((lhs, rhs) => {
                    return lhs.description.localized(this.state.currentLanguage) < rhs.description.localized(this.state.currentLanguage) ? -1 : 1
                })

                this.setState({
                    codes: sorted,
                    loaded: true
                })
            })
    }

    // StockRemovalReasonCode

    newStockRemovalReasonCode = () => {
        this.props.router.navigate("/stock_removal_reason_code/new")
    }

    editStockRemovalReasonCode = (key: string) => {
        this.props.router.navigate(`/stock_removal_reason_code/${key}`)
    }

    async removeStockRemovalReasonCode(key: string) {
        await this.reasonCodesRef().child(key).set(null)
    }

    // Helpers

    reasonCodesRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("app_data/stock/stock_removal_reason_codes")
    }
}

export default withRouter(StockRemovalReasonCodeList)
