import * as _ from "lodash"
import * as React from "react"
import firebase from "firebase/compat/app"
import { CallableDropzoneComponent, FunctionName } from "../CallableDropzoneComponent"

import {
    Button,
    FormControl,
    InputGroup,
    Pager,
    Card,
    Modal,
    FormGroup,
    Col,
    Label,
    Row
} from "src/components/wrappers"
import { PageState } from "../PageState"
import { firestore, ref } from "../../config/constants"
import { StripedTable } from "../StripedTable"
// import "react-dates/initialize" // tslint:disable-line
import { Customer } from "../../models/Customer"
import { Pagination } from "src/helpers/Pagination"
import { RoleProps, RoleRouterProps, withRoleRouter } from 'src/routes';
import { Pagination as CompPagination } from "react-bootstrap"
import { NavigateFunction } from "react-router"
import { SendEmailButton } from "./SendEmailButton"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { isValid } from "date-fns"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"

type PaginationDirection = "next" | "previous" | "initial"

interface CustomersState {
    customers: Customer[]
    loaded: boolean
    firstCustomerOnPage: string,
    lastCustomerOnPage: string,
    errorMessage?: string
    useLocalCustomerDatabase: boolean
    loyaltyCardsEnabled: boolean
    searchTerm?: string
    searching: boolean,
    newCustomerId: string,
    isNewCustomerIdValid: boolean,
    showIdModal: boolean
}

const customerFetchLimit = 50

function getCustomerNameOrId(customer: Customer) {
    return !_.isNil(customer.name) && customer.name?.length > 0 ? customer.name : customer.identifier
}
class Customers extends React.Component<RoleRouterProps, CustomersState> {
    pagination: Pagination

    constructor(props: RoleRouterProps) {
        super(props)
        this.pagination = new Pagination(this.query(), customerFetchLimit)
        // if (!_.isNil(this.props.location.state)) {
        //     this.state = { ...this.props.location.state }
        // } else {
        this.state = {
            customers: [],
            loaded: false,
            loyaltyCardsEnabled: false,
            useLocalCustomerDatabase: false,
            firstCustomerOnPage: "",
            lastCustomerOnPage: "",
            searchTerm: undefined,
            searching: false,
            newCustomerId: "",
            isNewCustomerIdValid: true,
            showIdModal: false
        }
    }

    query() {
        return this.customersRef()
            .orderBy("identifier")
    }

    async componentDidMount() {
        await this.getAreLoyaltyCardsEnabled()
        const useLocalDB = await this.useLocalCustomerDatabase()
        await this.loadCustomers(useLocalDB)
    }

    async useLocalCustomerDatabase(): Promise<boolean> {
        const snapshot = await this.customerLookupRef().get()
        const useLocalCustomerDatabase = snapshot.val()
        this.setState({ useLocalCustomerDatabase: useLocalCustomerDatabase })
        return useLocalCustomerDatabase
    }

    async loadCustomers(useLocalDB: boolean) {
        if (useLocalDB) {
            this.setupPaginationCallbacks()
            this.pagination.initial()
        } else {
            this.setState({ loaded: true })
        }
    }

    setupPaginationCallbacks() {
        this.pagination.values = (values) => {
            const customers = this.mapCustomers(values)
            this.setState({
                customers: customers,
                firstCustomerOnPage: customers[0].identifier,
                lastCustomerOnPage: customers[customers.length - 1].identifier
            })
        }

        this.pagination.valuesLoaded = (valuesLoaded) => {
            this.setState({
                loaded: valuesLoaded
            })
        }
    }

    async getValues(direction: PaginationDirection) {
        switch (direction) {
            case "initial":
                return await this.pagination.initial()
            case "next":
                return await this.pagination.next()
            case "previous":
                return await this.pagination.previous()
        }
    }

    loyaltyClubModuleRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/modules/loyalty_club`)
    }

    async getAreLoyaltyCardsEnabled() {
        const snapshot = await this.loyaltyClubModuleRef().get()
        if (snapshot.exists()) {
            const loyaltyClubModule = snapshot.val()
            this.setState({ loyaltyCardsEnabled: loyaltyClubModule.enable_loyalty_cards })
        }
    }

    customersRef() {
        const account = this.props.role.account_id
        return firestore
            .collection(`accounts/${account}/customers`)
    }

    mapCustomers(dataArray: any[]) {
        const customers: Customer[] = []
        for (const data of dataArray) {
            const customer = new Customer(data)
            customers.push(customer)
        }
        return customers
    }

    customerLookupRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/modules/customer_lookup/use_local_customer_database`)
    }

    private handlesearchTermChange = (event: any) => {
        const value: string = event.target.value

        this.setState({ searchTerm: value })
    }

    private handleSearchKeyPress(value: any) {
        if (value.charCode === 13) {
            this.performSearch()
        }
    }

    performSearch() {
        this.searchTerm = this.state.searchTerm
        if (this.state.useLocalCustomerDatabase === true) {
            this.loadCustomersFromSearch(this.state.searchTerm ?? "")
        } else {
            this.searchCustomersThroughIntegration(this.state.searchTerm ?? "")
        }
    }

    closeSearch() {
        this.setState({ searchTerm: undefined, loaded: true, customers: [], searching: false })
        this.searchTerm = undefined
        if (this.state.useLocalCustomerDatabase) {
            this.pagination.initial()
        }
    }

    async loadCustomersFromSearch(searchTerm: string) {
        this.setState({ customers: [], loaded: false, searching: true })
        let customers = new Map<string, Customer>()
        const indices = ["identifier", "index.email", "index.name", "phone"]
        const arrayOfCustomerMaps = await Promise.all(indices.map((index) => {
            return this.search(index, searchTerm)
        }))

        for (const customerMap of arrayOfCustomerMaps) {
            customers = new Map([...customers, ...customerMap])
        }

        const array = Array.from(customers.values())
        this.setState({ customers: this.sortCustomers(array), loaded: true })
    }

    sortCustomers(customers: Customer[]) {
        const sortedCustomers = customers.sort((a, b) => {
            if ((a.name ?? "") < (b.name ?? ""))
                return -1
            if ((a.name ?? "") > (b.name ?? ""))
                return 1
            return 0
        })

        return sortedCustomers
    }

    async search(index: string, searchTerm: string): Promise<Map<string, any>> {
        switch (index) {
            case "index.email":
            case "index.name":
                return await this.searchWithCase(index, searchTerm.toLowerCase())
            default: {
                // Try both cased and lowercased if they differ
                const lowercased = searchTerm.toLowerCase()
                if (lowercased === searchTerm) {
                    return await this.searchWithCase(index, searchTerm)
                } else {
                    const a = await this.searchWithCase(index, searchTerm)
                    const b = await this.searchWithCase(index, lowercased)
                    for (const key in b) {
                        a[key] = b[key]
                    }
                    return a
                }
            }
        }
    }

    async searchWithCase(index: string, searchTerm: string): Promise<Map<string, any>> {
        const customers: Map<string, any> = new Map()
        const customersRef = this.customersRef()
            .where(index, ">=", searchTerm)
            .where(index, "<", searchTerm + "~")
            .limit(customerFetchLimit)

        const snapshot = await customersRef.get()
        for (const doc of snapshot.docs) {
            const data = doc.data()
            const customer = new Customer(data)
            customers.set(customer.identifier, customer)
        }
        return customers
    }

    private get searchTerm(): string | undefined {
        const params = new URLSearchParams(this.props.router.location.search)
        const encodedsearchTerm = params.get("search")

        if (!encodedsearchTerm) {
            return undefined
        }

        const searchTerm = decodeURIComponent(encodedsearchTerm)

        return searchTerm
    }

    async searchCustomersThroughIntegration(searchTerm: string) {
        this.setState({ customers: [], loaded: false, searching: true })
        const args: any = {
            action: "customer-lookup",
            account: this.props.role.account_id,
            search_term: searchTerm,
            currency_code: ""
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        let customers: Customer[] = []
        for (const customerData of result.data) {
            const customer = new Customer(customerData)
            customers.push(customer)
        }
        this.setState({ customers: customers, loaded: true })
    }

    private set searchTerm(searchTerm: string | undefined) {

        // We always overwrite all query params, so we don't start out from the current params
        const params = new URLSearchParams()

        if (searchTerm) {
            const encodedsearchTerm = encodeURIComponent(searchTerm)
            params.set("search", encodedsearchTerm)
        } else {
            params.delete("search")
        }

        this.props.router.navigate(`?${params.toString()}`)
    }

    deletionMessage(customer: Customer): string {
        let message = `Are you sure you want to delete ${getCustomerNameOrId(customer)}?`
        if (this.state.loyaltyCardsEnabled) {
            message += "\n\nNote: Loyalty points for a customer is not deleted. Reuse of a customer id is therefore not supported."
        }
        return message
    }

    render() {
        return (
            <PageState loading={false} typeName="customer">
                {this.renderSearchField()}
                <br></br>
                {this.state.useLocalCustomerDatabase && !this.state.searching ? this.renderPager() : <br />}
                {this.state.useLocalCustomerDatabase ? this.renderCreateCustomerButton() : <br />}
                {this.renderCard()}
                {
                    <Modal show={this.state.showIdModal}>
                        <Modal.Header>
                            <Modal.Title>
                                Id for new customer
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={12}><Label variant="info">Info</Label> Leaving this empty will autogenerate a customer id.</Col>
                            </FormGroup>
                            <br />
                            <ValidatingIdEntryControl
                                collectionRef={this.customersRef()}
                                isNew={true}
                                typeName="customer"
                                identifierSource={null}
                                existingIdentifier={this.state.newCustomerId}
                                handleIdChange={(id, valid) => {
                                    this.setState({ newCustomerId: id, isNewCustomerIdValid: valid })
                                }}
                            />
                            <br />
                            <br />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => {
                                this.navigateToNewCustomerView()
                            }} disabled={!this.state.isNewCustomerIdValid}>Create</Button>
                            <Button onClick={() => {
                                this.setState({ newCustomerId: "", showIdModal: false })
                            }}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                }
                <Card className="my-4">
                    <Card.Header>
                        Customer CSV import
                    </Card.Header>
                    <Card.Body>
                        <strong>Upload customer through CSV</strong>
                        <CallableDropzoneComponent
                            key="customer-csv-drop-zone"
                            config={this.componentConfig()}
                            eventHandlers={this.eventHandlers()}
                            djsConfig={this.djsConfig()}
                            account={this.props.role.account_id}
                            functionName={FunctionName.CustomerCSVImporter}
                        />
                    </Card.Body>
                </Card>

            </PageState>
        )
    }

    private navigateToNewCustomerView() {
        let key = this.state.newCustomerId ? this.state.newCustomerId : this.generateNewKey()
        let path = `/customers/${key}/new`
        this.props.router.navigate(path)
    }

    public generateNewKey(): string | null {
        return ref().push().key
    }

    renderSearchField() {
        return <InputGroup>
            <FormControl
                type="text"
                name="search_term"
                value={this.state.searchTerm ?? ""}
                placeholder={`Search for customer`}
                onChange={(event: any) => { this.handlesearchTermChange(event) }}
                onKeyPress={(value) => { this.handleSearchKeyPress(value) }}
            />
            <Button variant="primary" onClick={() => { this.performSearch() }} >Search</Button>
            {this.state.searching ? <Button variant="warning" onClick={() => { this.closeSearch() }}>Close</Button> : null}
        </InputGroup>
    }

    renderPager() {
        return <CompPagination>
            <CompPagination.Prev
                disabled={this.pagination.isFirstPage()}
                onClick={async () => {
                    this.pagination.previous()
                }}>&larr; Previous</CompPagination.Prev>
            <CompPagination.Next
                disabled={this.pagination.isLastPage()}
                onClick={async () => {
                    this.pagination.next()
                }}>Next &rarr;</CompPagination.Next>
        </CompPagination>
    }

    renderCreateCustomerButton() {
        return <Button onClick={async (event) => {
            event.stopPropagation();
            this.setState({ showIdModal: true })
        }}> Create customer </Button>;
    }

    async deleteCustomer(id: string) {
        const ref = firestore.doc(`accounts/${this.props.role.account_id}/customers/${id}`)
        if (this.state.searching) {
            this.closeSearch()
        }
        await ref.delete()
    }

    renderCard() {
        return <Card className="my-4">
            <Card.Header>Customers</Card.Header>
            <Card.Body>
                {!this.state.useLocalCustomerDatabase && 
                  !this.state.searching &&
                <div>Start a search to display customers</div>}
                {this.state.customers.length > 0 &&
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Customer ID</th>
                                <th>Name</th>
                                <th>Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.customers.map(customer => {
                                return (
                                    <tr
                                        key={customer.identifier}
                                        onClick={() => {
                                            let path = `/customers/${customer.identifier}`
                                            this.props.router.navigate(path)
                                        }} >
                                        <td> {customer.identifier} </td>
                                        <td> {customer.name} </td>
                                        <td> {customer.email} </td>
                                        {this.state.loyaltyCardsEnabled ? <td>
                                            <SendEmailButton
                                                customerId={customer.identifier}
                                                account={this.props.role.account_id}
                                            />
                                        </td> : null}
                                        {this.state.useLocalCustomerDatabase ? <td>
                                            <ConfirmDeleteButton
                                                message={this.deletionMessage(customer)}
                                                deletionText="Delete customer"
                                                onDelete={async () => {
                                                    await this.deleteCustomer(customer.identifier)
                                                }}
                                            />
                                        </td> : null}

                                    </tr>
                                )
                            })}

                        </tbody>
                    </StripedTable>
                }
                { (this.state.useLocalCustomerDatabase || this.state.searching) && this.state.loaded && this.state.customers.length === 0 && <div> No customers found </div> }
                { !this.state.loaded && this.state.searching && <div>Searching...</div>}
                { !this.state.loaded && !this.state.searching && <div>Loading...</div>}
            </Card.Body>
        </Card>
    }

    djsConfig = () => {
        return {
            autoProcessQueue: true,
            dictDefaultMessage: "Add a CSV file customers here.",
            url: "https://fakeurl.com" // not used, but DropzoneComponent complains if it's not set
        }
    }

    componentConfig = () => {
        return {
            iconFiletypes: [".csv"],
            showFiletypeIcon: true
        }
    }

    eventHandlers = () => {
        return {
            error: (error: any) => {
                console.error(`Error: ${JSON.stringify(error)}`)
            }
        }
    }
}

export default withRoleRouter(Customers)


