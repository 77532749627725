import firebase from "firebase/compat/app"
import * as React from "react"

import { Button, Card, ProgressBar } from "../../../wrappers"
import { ShopifyConfiguration } from "./Models"
import { BoxArrowUpRight } from "react-bootstrap-icons"

interface StockImportCardProps {
    accountId: string
    configuration: ShopifyConfiguration
    openShopifyPrivateApps: () => void
}

interface StockImportCardState {
    importCount?: number
    importProgress?: number
    importTotal?: number
}

export class StockImportCard extends React.Component<StockImportCardProps, StockImportCardState> {

    constructor(props: StockImportCardProps) {
        super(props)
        this.state = {}
    }

    // Helpers

    stockImportHelpText = (
        <div key="stock_import_help_text">
            Follow these steps to perform a stock import from Shopify to Ka-ching
            <ol>
                <li>
                    Click the &quot;Open Shopify Configuration&quot; button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to read the stock values in Shopify. The stock import requires&quot;Read and write&quot; to &quot;Inventory&quot;.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Click &quot;Import stock values&quot; to start the import.
                </li>
            </ol>
        </div>
    )

    importLabelText(): string {
        if (this.state.importCount !== undefined && this.state.importTotal !== undefined) {
            const label = `${this.state.importCount} / ${this.state.importTotal}`
            if (this.state.importCount >= this.state.importTotal) {
                return label + " Import complete!"
            }
            return label
        } else {
            return "Initiating import"
        }
    }

    importStockValues = async () => {
        const triggerStockImport = firebase.functions().httpsCallable("triggerImport", { timeout: 540000000 })
        this.setState({ importProgress: 0, importCount: undefined, importTotal: undefined })
        let imported: number = 0
        let total: number = 0
        let nextPageURL: string | undefined = undefined
        for (; ;) {
            const options: any = {
                account: this.props.accountId,
                integrationType: "shopify",
                integration: "shopify",
                elementType: "stock"
            }
            if (nextPageURL) {
                options.nextPageURL = nextPageURL
            }
            const result = await triggerStockImport(options)
            if (result.data.total) {
                total = result.data.total
            }
            if (result.data.count) {
                imported += result.data.count
            }
            nextPageURL = result.data.next_page_url

            let progress = 0
            if (imported > 0 && total > 0) {
                progress = (100 * imported) / total
            }
            this.setState({ importProgress: progress, importCount: imported, importTotal: total })

            if (!nextPageURL) {
                break
            }
        }
    }

    // Component

    render() {
        return (
            <Card className="my-4" key="stock_import">
                <Card.Header>
                    Stock import
                </Card.Header>
                <Card.Body>
                    <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <BoxArrowUpRight /></Button>
                    <br /> <br />
                    {this.stockImportHelpText}
                    <br /> <br />
                    <Button disabled={this.state.importProgress !== undefined} onClick={async () => { await this.importStockValues() }}>Import stock values</Button>
                    <br /> <br />
                    {this.state.importProgress !== undefined ? <ProgressBar now={this.state.importProgress} label={this.importLabelText()} /> : null}
                </Card.Body>
            </Card>
        )
    }
}