import * as React from "react"
import { FormattedTime, FormattedRelativeTime } from "react-intl"
import { ref } from "../../config/constants"
import { Card, Button } from "../wrappers"
import { PageState } from "../PageState"
import { StripedTable } from "../StripedTable"
import dayjs from "dayjs"
import * as _ from "lodash"
import ExportsJS from "exportsjs"
import { RoleShopRouterProps, withShopRouter } from "src/routes"
import { DateRange } from 'react-date-range'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Pagination } from "react-bootstrap"

const FileDownload = require("js-file-download")

interface RegisterCloseStatementListState {
    statements: any[]
    lastStatementIdStack: string[]
    startDate?: Date
    endDate?: Date
    exports: any
    loaded: boolean
    focusedInput?: any
    firstPage?: boolean
    lastPage?: boolean
    datePickerCollapsed: boolean
}

class RegisterCloseStatementList extends React.Component<RoleShopRouterProps, RegisterCloseStatementListState> {
    constructor(props: RoleShopRouterProps) {
        super(props)

        this.state = {
            statements: [],
            lastStatementIdStack: [],
            exports: {},
            startDate: new Date(),
            endDate: new Date(),
            loaded: false,
            datePickerCollapsed: false
        }
    }

    async componentDidMount() {
        const params = new URLSearchParams(this.props.router.location.search)
        const fromKey = params.get("fromKey")
        await this.loadStatements(fromKey)
        await this.loadExports()
    }

    loadExports = async () => {
        this.setState({ loaded: false })
        const account = this.props.role.account_id
        const exportsRef = ref().child(`v1/accounts/${account}/configuration/export_integrations/register_close_statements`).orderByKey().limitToLast(50)
        const snapshot = await exportsRef.once("value")
        if (!snapshot.exists()) {
            this.setState({ loaded: true })
            return
        }
        let exportsDict = snapshot.val()
        exportsDict = _.filter(exportsDict, (value, key: string, collection) => {
            if (Object.prototype.hasOwnProperty.call(value, "show_in_portal")) {
                return value.show_in_portal
            }
            return true
        })
        this.setState({ exports: exportsDict, loaded: true })
    }

    loadNext = async () => {
        const statementIdStack = this.state.lastStatementIdStack
        let lastStatementId: string | null = null
        if (statementIdStack.length > 0) {
            lastStatementId = statementIdStack[statementIdStack.length - 1]
        }
        await this.loadStatements(lastStatementId)
    }

    loadPrevious = async () => {
        const statementIdStack = this.state.lastStatementIdStack
        statementIdStack.pop()
        statementIdStack.pop()
        this.setState({ lastStatementIdStack: statementIdStack })
        let lastStatementId: string | null = null
        if (statementIdStack.length > 0) {
            lastStatementId = statementIdStack[statementIdStack.length - 1]
        }
        await this.loadStatements(lastStatementId)
    }

    statementsRef() {
        const account = this.props.role.account_id
        const shop = this.props.shop
        return ref().child(`v1/accounts/${account}/shops/${shop}/register_close_statements`)
    }

    loadStatements = async (fromKey: string | null) => {
        const params = new URLSearchParams()

        if (fromKey) {
            const encodedSearchTerm = encodeURIComponent(fromKey)
            params.set("fromKey", encodedSearchTerm)
        } else {
            params.delete("fromKey")
        }
        this.props.router.navigate(`?${params.toString()}`)

        const limit = 50
        this.setState({ loaded: false, firstPage: fromKey === null })
        let statementsRef = this.statementsRef().orderByKey().limitToLast(limit)
        if (fromKey) {
            statementsRef = statementsRef.endAt(fromKey)
        }
        const snapshot = await statementsRef.once("value")
        const statementsDict = snapshot.val()

        if (!statementsDict) {
            this.setState({ loaded: true, lastPage: true })
            return
        }

        const keys = Object.keys(statementsDict)
        const values = keys.map(v => {
            const statement = statementsDict[v]
            statement.key = v
            return statement
        })
        const sorted = values.sort(function (a, b) {
            if (a.key < b.key) { return 1 }
            if (a.key > b.key) { return -1 }
            return 0
        })
        const statementIdStack = this.state.lastStatementIdStack
        if (sorted.length > 0) {
            const lastKey = sorted[sorted.length - 1].key
            let lastStatementId: string | null = null
            if (statementIdStack.length > 0) {
                lastStatementId = statementIdStack[statementIdStack.length - 1]
            }
            if (lastKey !== lastStatementId) {
                statementIdStack.push(lastKey)
            }
        }
        this.setState({ statements: sorted, lastStatementIdStack: statementIdStack, loaded: true, lastPage: values.length < limit })
    }

    performExport = async (exportKey: string) => {
        const exportMethod = this.state.exports[exportKey]

        const start = dayjs(this.state.startDate).startOf("day")
        const end = dayjs(this.state.endDate).endOf("day")
        if (!start.isValid()) {
            return
        }
        if (!end.isValid()) {
            return
        }
        const statementsRef = this.statementsRef().orderByChild("reconciliation_time").startAt(start.unix()).endAt(end.unix())
        const snapshot = await statementsRef.once("value")
        if (!snapshot.exists()) {
            // TODO: Warn that no data is available?
            return
        }
        const statementsDict = snapshot.val()

        const statements = Object.values(statementsDict)
        const configuration = exportMethod.transformation.parameters
        let exportFunction: (input: any) => any
        let fileExtension: string = "json"
        let singleItemExport: boolean = false

        switch (exportMethod.transformation.type) {
            case "csv": {
                exportFunction = input => {
                    const csvExport = new ExportsJS.csv(configuration, input)
                    return csvExport.export()
                }
                fileExtension = "csv"

                break
            }
            case "simplejson": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.simpleJSON(configuration, input)
                    return jsonExport.export()
                }
                singleItemExport = true
                break
            }
            case "identity": {
                exportFunction = input => { return input }
                break
            }
            case "economic": {
                exportFunction = input => {
                    const jsonExport = new ExportsJS.economic(configuration, input)
                    return jsonExport.registerCloseStatementExport()
                }
                singleItemExport = true
                break
            }
            default: {
                return
            }
        }

        const filename = "statements." + fileExtension
        console.log("FILENAME", filename)
        if (singleItemExport) {
            const output: any = []
            for (const statement of statements) {
                try {
                    // Expecting export functions to return objects
                    output.push(exportFunction(statement))
                } catch (error) {
                    if (error instanceof ExportsJS.SkipExport) {
                        console.log("Skipping export", error.message)
                    } else {
                        throw error
                    }
                }
            }
            FileDownload(JSON.stringify(output), filename)

        } else {
            let output = exportFunction(statementsDict)
            if (_.isObject(output)) {
                output = JSON.stringify(output)
            }
            FileDownload(output, filename)
        }
    }

    showStatement = (statementKey: string) => {
        const path = `/shop/${this.props.shop}/register_close_statement/${statementKey}`
        this.props.router.navigate(path)
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="statements">
                <Card className="my-4">
                    <Card.Header>Register Close Statements</Card.Header>

                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Date/Time</th>
                                <th>Register</th>
                                <th>Cashier</th>
                                <th>Sales</th>
                                <th>Returns</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.statements.map(listValue => {
                                const date = new Date(listValue.reconciliation_time * 1000)
                                return (
                                    <tr key={listValue.key} onClick={() => this.showStatement(listValue.key)} >
                                        <td>
                                            <FormattedTime
                                                value={date}
                                                day="numeric"
                                                month="long"
                                                year="numeric"
                                                hour="numeric"
                                                minute="numeric"
                                            />
                                            {/* &nbsp;(<FormattedRelativeTime value={date} />) */}
                                        </td>
                                        <td>{listValue.source.register_name}</td>
                                        <td>{listValue.source.cashier_name || listValue.source.cashier_display_name}</td>
                                        <td>{(listValue.register_summary && listValue.register_summary.sales.count) ? listValue.register_summary.sales.count : 0}</td>
                                        <td>{(listValue.register_summary && listValue.register_summary.returns.count) ? listValue.register_summary.returns.count : 0}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </StripedTable>
                </Card>
                <Pagination>
                    <Pagination.Prev disabled={this.state.firstPage} onClick={this.loadPrevious}>&larr; Previous Page</Pagination.Prev>
                    <Pagination.Next disabled={this.state.lastPage} onClick={this.loadNext}>Next Page &rarr;</Pagination.Next>
                </Pagination>

                {Object.keys(this.state.exports).length > 0 ? (
                    <Card className="my-4">
                        <Card.Header>Export</Card.Header>
                        <Card.Body>
                            <div>
                                <DateRange onRangeFocusChange={() => this.setState({datePickerCollapsed: false})} className={this.state.datePickerCollapsed ? "collapsed" : "" } editableDateInputs={true}
                                    onChange={item => {
                                        const range = item["selection"]
                                        if (!range) { return }
                                        const start = dayjs(range.startDate).startOf("day")
                                        const end = dayjs(range.endDate).endOf("day")
                                        this.setState({ startDate: range.startDate, endDate: range.endDate })
                                    }}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[{ startDate: this.state.startDate, endDate: this.state.endDate, key: "selection" }]} />

                                <br />
                                <br />
                            </div>

                            <div>
                                {
                                    Object.keys(this.state.exports).map(key => {
                                        const exportMethod = this.state.exports[key]
                                        const start = dayjs(this.state.startDate).startOf("day")
                                        const end = dayjs(this.state.endDate).endOf("day")
                                
                                        const enabled = start.isValid() && end.isValid()
                                        return <div key={key}><Button variant="secondary" onClick={() => this.performExport(key)} disabled={!enabled}>{exportMethod.name}</Button><br /><br /></div>
                                    })
                                }
                            </div>
                        </Card.Body>
                    </Card>
                ) : <div />}
            </PageState>
        )
    }
}

export default withShopRouter(RegisterCloseStatementList)
