import React from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";
import { Col, DescriptionCol, FormControl, FormGroup, Row } from "src/components/wrappers";
import { Margins } from "src/models/pdf_template/margins";
import { GlobalEditProps } from "./GlobalModalEdit";

export function EditGlobalMargins(props: GlobalEditProps) {
    return (
        <Form>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Leading margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Leading margin"
                        value={props.pdfTemplate.margins?.leading ?? 0}
                        placeholder="Enter leading margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateMargins((margins) => {
                                margins.leading = value;
                                return margins;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Trailing margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Trailing margin"
                        value={props.pdfTemplate.margins?.trailing ?? 0}
                        placeholder="Enter trailing margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateMargins((margins) => {
                                margins.trailing = value;
                                return margins;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Top margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Top margin"
                        value={props.pdfTemplate.margins?.top ?? 0}
                        placeholder="Enter top margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateMargins((margins) => {
                                margins.top = value;
                                return margins;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Bottom margin</Form.Label>
                </DescriptionCol>

                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="number"
                        name="Bottom margin"
                        value={props.pdfTemplate.margins?.bottom ?? 0}
                        placeholder="Enter bottom margin"
                        onChange={(event) => {
                            const value = new Number(event.currentTarget.value).valueOf();
                            updateMargins((margins) => {
                                margins.bottom = value;
                                return margins;
                            });
                        }} />
                </Col>
            </FormGroup>
        </Form>
    );

    function updateMargins(callback: (margins: Margins) => Margins) {
        let margins = _.cloneDeep(props.pdfTemplate?.margins);
        if (_.isNil(margins)) {
            margins = new Margins();
        }
        margins = callback(margins);
        props.updatePDFTemplate((template) => {
            if (isMarginEmpty(margins)) {
                margins = undefined;
            }
            template.margins = margins;
            return template;
        });
    }
}

export function isMarginEmpty(margins: Margins | undefined): boolean {
    return (margins?.bottom === 0 && margins?.top === 0 && margins?.leading === 0 && margins.trailing === 0);
}

