import firebase from "firebase/compat/app"
import { Card, Pager } from "./wrappers"
import { StripedTable } from "./StripedTable"
import * as React from "react"
import * as _ from "lodash"
import { Pagination } from "react-bootstrap"

export interface PagerProps {
    queueFetchLimit?: number
    queueRef: firebase.database.Reference | firebase.firestore.CollectionReference
    renderTitle: () => JSX.Element
    renderHeader: () => JSX.Element
    renderElement: (key: string, element: any) => JSX.Element[]
    didUpdateElements: (elements: any, firstPage: boolean) => void
    setRefreshFunction: (refresh: () => Promise<void>) => void
    defaultExpanded: boolean
}

export interface PagerState {
    loading: boolean
    lastPage: boolean
    lastKeyStack: string[]
    lastSnapStack: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
    queueFetchLimit: number
    firstPage: boolean
    elements?: any
}

export class LivePager extends React.Component<PagerProps, PagerState> {

    refresh = async () => {
        if (this.props.queueRef instanceof firebase.firestore.CollectionReference) {
            const snapStack = this.state.lastSnapStack
            snapStack.pop()
            this.setState({ lastSnapStack: snapStack })
            let lastSnap: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined
            if (snapStack.length > 0) {
                lastSnap = snapStack[snapStack.length - 1]
            }
            await this.loadFirestoreElements(lastSnap)

        } else {
            const keyStack = this.state.lastKeyStack
            keyStack.pop()
            this.setState({ lastKeyStack: keyStack })
            let lastKey: string | undefined
            if (keyStack.length > 0) {
                lastKey = keyStack[keyStack.length - 1]
            }
            await this.loadElements(lastKey)
        }
    }

    constructor(props: PagerProps) {
        super(props)
        props.setRefreshFunction(async () => { await this.refresh() })

        this.state = {
            loading: false,
            lastPage: false,
            lastKeyStack: [],
            lastSnapStack: [],
            queueFetchLimit: props.queueFetchLimit || 50,
            firstPage: true
        }
    }

    async componentDidMount() {
        if (this.props.queueRef instanceof firebase.firestore.CollectionReference) {
            await this.loadFirestoreElements()
        } else {
            await this.loadElements()
        }
    }

    elementsFinishedLoading = (snapshot: firebase.database.DataSnapshot | firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>, firstPage: boolean) => {
        this.setState({ loading: false })
        let elementsDict: any = {}
        if (snapshot instanceof firebase.firestore.QuerySnapshot) {
            if (snapshot.empty) {
                this.props.didUpdateElements({}, this.state.firstPage)
                this.setState({ lastPage: true, elements: {} })
                return
            }

            for (const doc of snapshot.docs) {
                elementsDict[doc.id] = doc.data()
            }

            let snapStack = _.cloneDeep(this.state.lastSnapStack)
            if (Object.keys(elementsDict).length > 0) {
                const length = Object.keys(elementsDict).length
                const lastKey = Object.keys(elementsDict)[length - 1]
                const lastSnap = snapshot.docs[snapshot.docs.length - 1]

                if (firstPage) {
                    snapStack = [lastSnap]
                } else {
                    let lastStackSnap: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined
                    if (snapStack.length > 0) {
                        lastStackSnap = snapStack[snapStack.length - 1]
                    }
                    if (lastSnap.id !== lastStackSnap?.id) {
                        snapStack.push(lastSnap)
                    }
                }
            }

            this.props.didUpdateElements(elementsDict, this.state.firstPage)
            this.setState({ elements: elementsDict, lastSnapStack: snapStack, lastPage: Object.keys(elementsDict).length < this.state.queueFetchLimit })

        } else {
            elementsDict = snapshot.val()

            if (!elementsDict) {
                this.props.didUpdateElements({}, this.state.firstPage)
                this.setState({ lastPage: true, elements: {} })
                return
            }

            // Don't you just love that key value pairs in objects actually retain their order?
            const ordered = {}
            let lastKey: string | undefined
            Object.keys(elementsDict).sort().reverse().forEach((key) => {
                if (Object.keys(ordered).length < this.state.queueFetchLimit) {
                    ordered[key] = elementsDict[key]
                }
                lastKey = key
            })

            let keyStack = this.state.lastKeyStack

            if (firstPage && !_.isNil(lastKey)) {
                keyStack = [lastKey]
            } else {
                if (Object.keys(ordered).length > 0) {
                    let lastStackKey: string | undefined
                    if (keyStack.length > 0) {
                        lastStackKey = keyStack[keyStack.length - 1]
                    }
                    if (lastKey !== lastStackKey) {
                        keyStack.push(lastKey as string)
                    }
                }
            }
            this.props.didUpdateElements(ordered, this.state.firstPage)
            this.setState({ elements: ordered, lastKeyStack: keyStack, lastPage: Object.keys(ordered).length < this.state.queueFetchLimit })

        }

    }

    unsubscribe?: any

    loadElements = async (fromKey?: string) => {
        if (this.props.queueRef instanceof firebase.firestore.CollectionReference) {
            return
        }
        this.props.queueRef.off()

        const firstPage = _.isNil(fromKey)
        this.setState({ loading: true, firstPage: firstPage })
        let queryRef = this.props.queueRef.orderByKey().limitToLast(this.state.queueFetchLimit + 1)
        if (!_.isNil(fromKey)) {
            queryRef = queryRef.endAt(fromKey)
        }

        if (firstPage) {
            queryRef.on("value", snapshot => {
                this.elementsFinishedLoading(snapshot, firstPage)
            })
        } else {
            const snapshot = await queryRef.once("value")
            this.elementsFinishedLoading(snapshot, firstPage)
        }

    }

    loadFirestoreElements = async (fromSnap?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
        // Firestore
        if (!(this.props.queueRef instanceof firebase.firestore.CollectionReference)) {
            return
        }

        this.unsubscribe?.()
        const firstPage = _.isNil(fromSnap)
        this.setState({ loading: true, firstPage: firstPage })
        let queryRef = this.props.queueRef.orderBy("timestamp", "desc").limit(this.state.queueFetchLimit + 1)
        if (!_.isNil(fromSnap)) {
            queryRef = queryRef.startAfter(fromSnap)
        }

        if (firstPage) {
            this.unsubscribe = queryRef.onSnapshot(snapshot => {
                this.elementsFinishedLoading(snapshot, firstPage)
            })
        } else {
            const snapshot = await queryRef.get()
            this.elementsFinishedLoading(snapshot, firstPage)
        }
    }

    loadNext = async () => {
        if (this.props.queueRef instanceof firebase.firestore.CollectionReference) {
            const snapStack = this.state.lastSnapStack
            let lastSnap: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined
            if (snapStack.length > 0) {
                lastSnap = snapStack[snapStack.length - 1]
            }
            await this.loadFirestoreElements(lastSnap)

        } else {
            const keyStack = this.state.lastKeyStack
            let lastKey: string | undefined
            if (keyStack.length > 0) {
                lastKey = keyStack[keyStack.length - 1]
            }
            await this.loadElements(lastKey)
        }
    }

    loadPrevious = async () => {
        if (this.props.queueRef instanceof firebase.firestore.CollectionReference) {
            const snapStack = this.state.lastSnapStack
            snapStack.pop()
            snapStack.pop()
            this.setState({ lastSnapStack: snapStack })
            let lastSnap: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | undefined
            if (snapStack.length > 0) {
                lastSnap = snapStack[snapStack.length - 1]
            }
            await this.loadFirestoreElements(lastSnap)

        } else {
            const keyStack = this.state.lastKeyStack
            keyStack.pop()
            keyStack.pop()
            this.setState({ lastKeyStack: keyStack })
            let lastKey: string | undefined
            if (keyStack.length > 0) {
                lastKey = keyStack[keyStack.length - 1]
            }
            await this.loadElements(lastKey)
        }
    }

    render() {
        return (
            <Card className="my-4" /*defaultExpanded={this.props.defaultExpanded} */>
                <Card.Header>
                    {this.props.renderTitle()}
                </Card.Header>
                <Card.Body>
                    <StripedTable>
                        <thead>
                            {this.props.renderHeader()}
                        </thead>
                        <tbody>
                            {
                                Object.keys(this.state.elements || {}).map((key: string) => {
                                    return this.props.renderElement(key, this.state.elements[key])
                                })
                            }
                        </tbody>
                    </StripedTable>
                    <Pagination>
                        <Pagination.Prev disabled={this.state.firstPage || this.state.loading} onClick={this.loadPrevious}>&larr; Previous Page</Pagination.Prev>
                        <Pagination.Next disabled={this.state.lastPage || this.state.loading} onClick={this.loadNext}>Next Page &rarr;</Pagination.Next>
                    </Pagination>
                </Card.Body>
            </Card>
        )
    }
}
