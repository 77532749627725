//
//  ExpenseList.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 26/02/2020.
//  Copyright © 2020 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button, Card } from "../wrappers"
import { forEach } from "lodash"
import { ref } from "../../config/constants"
import { PageState } from "../PageState"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { StripedTable } from "../StripedTable"
import { Role } from "../../config/role"
import { LanguageCode } from "../../helpers/L10n"
import { Expense } from "../../models/Expense"
import { RoleRouterProps, withRoleRouter } from "src/routes"

interface ExpenseListState {
    expenses: Expense[]
    currentLanguage: LanguageCode | null
    loaded: boolean
}

class ExpenseList extends React.Component<RoleRouterProps, ExpenseListState> {

    // Properties

    initialState: ExpenseListState = {
        expenses: [],
        currentLanguage: LanguageCode.en,
        loaded: false
    }

    // Component

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.loadState()
    }

    componentWillUnmount() {
        this.expenseRepoRef().off()
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="expenses">
                <Card className="my-4">
                    <Card.Header>
                        <Button onClick={this.newExpense}>New expense</Button>
                    </Card.Header>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.expenses.map(expense => {
                                return (
                                    <tr key={expense.id} onClick={() => { this.editExpense(expense.id) }}>
                                        <td>{expense.name.localized(this.state.currentLanguage) ?? expense.name.value}</td>
                                        <td className="narrow">
                                            <ConfirmDeleteButton
                                                message={`Really delete the expense: ${expense.name.localized(this.state.currentLanguage)}?`}
                                                onDelete={async () => { await this.removeExpense(expense.id) }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </StripedTable>
                    <Card.Footer>
                        <Button onClick={this.newExpense}>New expense</Button>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }

    // State

    loadState() {
        this.expenseRepoRef()
            .on("value", snapshot => {
                if (!snapshot || (snapshot && !snapshot.exists())) {
                    this.setState({ expenses: [], loaded: true })
                    return
                }

                const expenses: Expense[] = []
                forEach(snapshot.val(), (value) => {
                    const expense = new Expense(value.expense)
                    expenses.push(expense)
                })

                const sorted = expenses.sort((lhs, rhs) => {
                    return lhs.name.localized(this.state.currentLanguage) < rhs.name.localized(this.state.currentLanguage) ? -1 : 1
                })

                this.setState({
                    expenses: sorted,
                    loaded: true
                })
            })
    }

    // Expense

    newExpense = () => {
        this.props.router.navigate("/expense/new")
    }

    editExpense = (key: string) => {
        this.props.router.navigate(`/expense/${key}`)
    }

    async removeExpense(key: string) {
        await this.expenseRepoRef().child(key).set(null)
    }

    // Helpers

    expenseRepoRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/expense_repo")
    }
}

export default withRoleRouter(ExpenseList)
