import { EmailTemplate } from "src/models/email_template";
import * as handlebars from "handlebars";

function htmlTemplate(walletURL: string | undefined): string {
    return `
<!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<style>
    .root {
        font-family: sans-serif;
        line-height: 1.5;
        font-size: 17px;
    }
    table {
        border-spacing: 1em 1em;
    }
</style>

<head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    {{#if title}}<title>{{title}}</title>{{/if}}
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</head>

<body style="{{#if backgroundColor}}background-color:{{backgroundColor}};{{/if}}">
    <table border="0" cellpadding="0" cellspacing="0" width="100%">
        {{#each lines}}
        <tr>
            <td class="root"
                {{#if alignment}}align="{{alignment}}"{{/if}}
                style="{{#if backgroundColor}}background-color:{{backgroundColor}};{{/if}} {{#if textColor}}color:{{textColor}};{{/if}} {{#if fontSize}}font-size:{{fontSize}}px;{{/if}} {{#if fontWeight}}font-weight:{{fontWeight}};{{/if}} {{#if fontStyle}}font-style:{{fontStyle}};{{/if}}  {{#if fontFamily}}font-family:{{fontFamily}}{{/if}}">
                {{#if walletURL}}

                <br/>
<a href="${walletURL}"><img alt="Add to Apple Wallet" src="https://firebasestorage.googleapis.com/v0/b/ka-ching-giftcard-dev.appspot.com/o/public%2Fwallet%2Fadd_to_apple_wallet.png?alt=media&token=d2a89db5-220c-49df-81c8-0ad76ee649d6" height="45" /></a>
&nbsp;&nbsp;&nbsp;
<a href="${walletURL}"><img alt="Add to Google Wallet" src="https://firebasestorage.googleapis.com/v0/b/ka-ching-giftcard-dev.appspot.com/o/public%2Fwallet%2Fadd_to_google_wallet.png?alt=media&token=15ac2725-b15c-4709-a4b0-e5078a1426b7" height="45" /></a>
<br/>

                {{else}}
                  {{text}}
                {{/if}}
            </td>
        </tr>
        {{/each }}
    </table>
</body>

</html>
`;
}
const textTemplate = `
{{#each lines}}
    {{text}}\n
{{/each }}
`;
interface TemplateType {
    html: string;
    text: string;
}

export function compileTemplate(template: EmailTemplate, walletURL: string | undefined): TemplateType {

    const html = handlebars.compile(htmlTemplate(walletURL))(template);
    const text = handlebars.compile(textTemplate)(template);

    return { html, text };
}
