import * as React from "react"

import { CallableDropzoneComponent, FunctionName } from "../CallableDropzoneComponent"
import { PageState } from "../PageState"
import { Card } from "../wrappers"
import { Role } from "../../config/role"
import { RoleStockLocationProps, withStockLocationRouter } from "src/routes"
import { DropzoneComponentConfig } from "react-dropzone-component"

interface StockImportState {
    loaded: boolean
}

class StockImport extends React.Component<RoleStockLocationProps, StockImportState> {

    // Constructor

    constructor(props: RoleStockLocationProps) {
        super(props)
        this.state = {
            loaded: false
        }
    }

    // Dropzone related

    djsConfig = () => {
        return {
            autoProcessQueue: true,
            dictDefaultMessage: "Add CSV file containing stock values here.",
            url: "https://fakeurl.com" // not used, but DropzoneComponent complains if it's not set
        }
    }

    componentConfig = () => {
        return {
            iconFiletypes: [".csv"],
            showFiletypeIcon: true
        }
    }

    eventHandlers = () => {
        return {
            error: (error: any) => {
                console.error(`Error: ${JSON.stringify(error)}`)
            }
        }
    }

    // React 

    async componentDidMount() {
        this.setState({
            loaded: true
        })
    }

    render() {
        return (
            <PageState dirty={false} loading={!this.state.loaded} typeName="stock import">
                <Card className="my-4">
                    <Card.Header>
                        Stock import
                    </Card.Header>
                    <Card.Body>
                        <strong>Upload stock values CSV</strong>
                        <CallableDropzoneComponent
                            key="stock-csv-drop-zone"
                            config={this.componentConfig()}
                            eventHandlers={this.eventHandlers()}
                            djsConfig={this.djsConfig()}
                            account={this.props.role.account_id}
                            shop={this.props.stockLocation}
                            functionName={FunctionName.StockCSVImporter}
                        />
                    </Card.Body>
                </Card>
            </PageState>
        )
    }

}

export default withStockLocationRouter(StockImport)