import * as _ from "lodash"
import firebase from "firebase/compat"
import * as dayjs from "dayjs"
import * as Numeral from "numeral"
import * as PDFMake from "pdfmake/build/pdfmake"
import * as PDFMakeFonts from "pdfmake/build/vfs_fonts"
import * as PDFMakeTypes from "pdfmake/interfaces"
import { ref } from "../config/constants"
import {
    ReportLine,
    ReportType,
    StyleField
} from "../models/ReportModels"
import * as utc from "dayjs/plugin/utc"
import * as dayjstimezone from "dayjs/plugin/timezone"
dayjs.extend(utc as any)
dayjs.extend(dayjstimezone as any)

export class ReportBuilder {

    // Properties

    private accountId: string
    private shopId: string

    // Initialisation

    constructor(accountId: string, shopId: string) {
        this.accountId = accountId
        this.shopId = shopId
    }

    // Public

    async buildCSVReport(report: ReportType, fieldDelimiter: string, decimalSeparator: string, startAt: dayjs.Dayjs, endAt: dayjs.Dayjs): Promise<String> {
        const salesSnapshot = await this.salesSnapshot(this.accountId, this.shopId, startAt, endAt)
        const accountRef = ref().child(`v1/accounts/${this.accountId}`)
        await report.prepare(accountRef)
        const reportLines: ReportLine[] = report.buildLines(salesSnapshot)

        let csvLines: string[] = [this.buildCSVHeader(report.headers("csv"), fieldDelimiter)]

        const contentLines = reportLines.map((line) => {
            const csvLine = line.forCSV(decimalSeparator)
            const noLineBreaks = csvLine.map((element) => {
                if (typeof element === "string") {
                    return element.replace(/(\r\n|\n|\r)/gm, " ")
                } else {
                    return element
                }
            })
            return noLineBreaks.join(fieldDelimiter)
        })

        csvLines = csvLines.concat(contentLines)
        return csvLines.join("\n")
    }

    async buildPDFReport(report: ReportType, startAt: dayjs.Dayjs, endAt: dayjs.Dayjs): Promise<PDFMake.TCreatedPdf> {
        const salesSnapshot = await this.salesSnapshot(this.accountId, this.shopId, startAt, endAt)
        const title = await report.buildDocumentName(this.accountId, this.shopId, false, report.title())
        const accountRef = ref().child(`v1/accounts/${this.accountId}`)
        await report.prepare(accountRef)
        const headers = this.buildPDFHeader(report.headers("pdf"))
        const reportLines = report.buildLines(salesSnapshot)

        Numeral.locale("da-dk")
        const documentDefinition = this.pdfDocumentDefinition(title, headers, reportLines)

        const pdf = PDFMake as any // ugly workaround for bad typings
        pdf.vfs = PDFMakeFonts.pdfMake.vfs

        Numeral.locale(undefined)

        return PDFMake.createPdf(documentDefinition)
    }

    // Helpers

    private buildCSVHeader(headers: string[], delimiter: string): string {
        return headers.join(delimiter)
    }

    private buildPDFHeader(headers: string[]): object[] {
        return headers.map((h) => { return { text: h, style: `${StyleField.leftTableHeader}` } })
    }

    private pdfDocumentDefinition(title: string, headers: any[], lines: ReportLine[]): PDFMakeTypes.TDocumentDefinitions {
        const columnWidths = headers.map(header => {
            if (header.text === "Name") {
                return "*"
            } else if (header.text === "Tags") {
                return 50
            } else {
                return "auto"
            }
        })
        const pdfRows: any[] = lines.map((line) => { return line.forPDF() })

        const documentDefinition: PDFMakeTypes.TDocumentDefinitions = {
            pageSize: "A4" as any, // Compensating for bad type declarations
            pageOrientation: "LANDSCAPE" as any, // Compensating for bad type declarations
            pageMargins: [10, 40, 10, 40],
            content: [
                { text: title, style: `${StyleField.title}` },
                "\n",
                {
                    layout: {
                        hLineWidth: function (i: number, node: any) {
                            return 1
                        },
                        vLineWidth: function (i: number, node: any) {
                            return 1
                        },
                        hLineColor: function (i: number, node: any) {
                            return i === 1 ? "black" : "#EEEEEE"
                        },
                        vLineColor: function (i: number, node: any) {
                            return "#EEEEEE"
                        }
                    },
                    table: {
                        headerRows: 1,
                        dontBreakRows: false,
                        widths: columnWidths,
                        body: [
                            headers,
                            ...pdfRows,
                        ]
                    }
                }
            ],
            styles: this.pdfStyles(headers)
        }
        return documentDefinition
    }

    private pdfStyles(headers: any[]): PDFMakeTypes.StyleDictionary {
        const styles = {}

        const bodySize = headers.length > 14 ? 7 : 9

        styles[StyleField.title] = {
            fontSize: 12
        }

        styles[StyleField.tableHeader] = {
            fontSize: bodySize,
            bold: true,
            alignment: "center"
        }

        styles[StyleField.leftTableHeader] = {
            fontSize: bodySize,
            bold: true,
        }

        styles[StyleField.tableValue] = {
            fontSize: bodySize,
            alignment: "center"
        }

        styles[StyleField.leftTableValue] = {
            fontSize: bodySize,
            alignment: "left"
        }

        styles[StyleField.rightTableValue] = {
            fontSize: bodySize,
            alignment: "right"
        }

        return styles
    }

    private async salesSnapshot(account: string, shop: string, startAt: dayjs.Dayjs, endAt: dayjs.Dayjs): Promise<firebase.database.DataSnapshot> {
        const start = _.cloneDeep(startAt).startOf("day").utc(true).format("YYYY-MM-DDTHH:mm:ss")
        const end = _.cloneDeep(endAt).endOf("day").utc(true).format("YYYY-MM-DDTHH:mm:ss")
        const salesRef = ref()
            .child(`v1/accounts/${account}/shops/${shop}/sales`)
            .orderByChild("timing/timestamp_string")
            .startAt(start)
            .endAt(end)

        return await salesRef.once("value")
    }
}
