import * as _ from "lodash"
import * as React from "react"
import {
    Alert,
    DescriptionCol,
    Button,
    Col,

    DropdownButton,
    Form,
    FormControl,
    FormControlStatic,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    MenuItem,
    Card,
    Row
} from "../../wrappers"
import {
    Attribute,
    AttributeTypeKey
} from "../../../models/Product"
import {
    Facet,
    FacetType,
    FacetTypeKey,
    AttributeFacetType,
    PriceFacetConfig
} from "../../../models/Facet"
import { L10nFormControl } from "../../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../../helpers/L10n"
import { LanguagePicker } from "../../LanguagePicker"
import { PageState } from "../../PageState"
import { publish } from "../../../helpers/ModelPublisher"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import { ValidatingIdEntryControl } from "../../ValidatingIdEntryControl"
import { DeleteButton } from "../../../components/DeleteButton"
import { RoleRouterProps, withRoleRouter } from "src/routes"
import { Dropdown } from "react-bootstrap"

const typeToNameMap: _.Dictionary<string> = {
    attribute: "Attribute",
    price: "Price",
    tags: "Tags",
    group: "Product Group"
}

interface FacetEditProps extends RoleRouterProps {
    currentLanguage?: LanguageCode
}

interface FacetEditState {
    attributes: _.Dictionary<Attribute>
    facet: Facet
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
    intervalError: string | null
    editingOption?: boolean
    editingOptionId?: string
    editingOptionName?: L10nString
    facetTypeEditingCache: _.Dictionary<FacetType>
    attributeFacetTypeEditingCache: _.Dictionary<AttributeFacetType>
}

class FacetEdit extends React.Component<FacetEditProps, FacetEditState> {
    constructor(props: FacetEditProps) {
        super(props)

        const attributesType = new FacetType({ attribute: { id: "", type: { options: { options: true } } } })
        const priceType = new FacetType({ price: { interval_count: 5 } })
        const tagsType = new FacetType({ tags: {} })
        const groupType = new FacetType({ group: {} })
        const facetTypeEditingCache = {
            attribute: attributesType,
            price: priceType,
            tags: tagsType,
            group: groupType
        }

        const numberAttributeFacet = new AttributeFacetType({ number: { max: 0, min: 0, interval_count: 5 } })
        const optionsAttributeFacet = new AttributeFacetType({ options: { options: true } })
        const attributeFacetTypeEditingCache = {
            number: numberAttributeFacet,
            options: optionsAttributeFacet
        }

        this.state = {
            attributes: {},
            facet: new Facet({
                id: "",
                name: "",
                type: {
                    price: {
                        interval_count: 5
                    }
                }
            }),
            identifier: "",
            currentLanguage: props.currentLanguage || null,
            loaded: false,
            dirty: false,
            publishing: false,
            error: null,
            intervalError: null,
            facetTypeEditingCache: facetTypeEditingCache,
            attributeFacetTypeEditingCache: attributeFacetTypeEditingCache
        }
    }

    pop() {
        const path = `/facets`
        this.props.router.navigate(path)
    }

    facetKey() {
        return this.props.router.params.facetKey
    }

    isNewFacet() {
        return this.facetKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (!_.isNil(this.state.facet.name) && this.state.facet.name.hasEmptyLocalizations()) {
            return false
        }
        if (!_.isNil(this.state.facet.type!.price!) &&
            this.state.facet.type!.price!.facetConfig === PriceFacetConfig.MANUAL &&
            _.isNil(this.state.facet.type!.price!.intervals)
        ) {
            return false
        }

        if (!_.isNil(this.state.intervalError)) {
            return false
        }

        return this.state.facet.isValid()
    }

    facetsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/facets")
    }

    async publish() {
        const json = this.state.facet.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "id", this.state.identifier, this.isNewFacet(), this.facetsRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        const attributesRef = ref().child(`v1/accounts/${this.props.role.account_id}/inventory/attributes`)
        const attributesSnapshot = await attributesRef.once("value")
        const attributes: _.Dictionary<Attribute> = {}
        if (attributesSnapshot.exists()) {
            const rawAttributes = attributesSnapshot.val()
            for (const k in rawAttributes) {
                if (Object.prototype.hasOwnProperty.call(rawAttributes, k)) {
                    const candidate = new Attribute(rawAttributes[k])
                    if (candidate.typeKey() !== AttributeTypeKey.TEXT &&
                        candidate.typeKey() !== AttributeTypeKey.TEXT_ENTRY &&
                        candidate.typeKey() !== AttributeTypeKey.DATE &&
                        candidate.typeKey() !== AttributeTypeKey.DATE_TIME) {
                        attributes[k] = candidate
                    }
                }
            }

            this.setState({ attributes: attributes })
        }

        if (!this.isNewFacet()) {
            const snapshot = await this.facetsRef()
                .child(this.facetKey())
                .once("value")
            const facet = new Facet(snapshot.val())

            const facetTypeEditingCache = _.cloneDeep(this.state.facetTypeEditingCache)
            facetTypeEditingCache[facet.typeKey()] = _.cloneDeep(facet.type!)

            let attributeFacetTypeEditingCache: _.Dictionary<AttributeFacetType> = {}
            if (!_.isNil(facet.type) && !_.isNil(facet.type.attribute)) {
                attributeFacetTypeEditingCache = _.cloneDeep(this.state.attributeFacetTypeEditingCache)
                attributeFacetTypeEditingCache[facet.type.attribute!.type.typeKey()] = facet.type.attribute!.type
            }

            this.setState({ facet: facet, identifier: facet.id, facetTypeEditingCache: facetTypeEditingCache, attributeFacetTypeEditingCache: attributeFacetTypeEditingCache, loaded: true })
        } else {
            const attributeKeys = Object.keys(attributes)
            const facetTypeEditingCache = _.cloneDeep(this.state.facetTypeEditingCache)
            if (attributeKeys.length > 0) {
                const first = attributes[attributeKeys[0]]

                const facetType = _.cloneDeep(facetTypeEditingCache[FacetTypeKey.ATTRIBUTE])
                facetType.attribute!.id = first.id
                facetType.attribute!.type = this.state.attributeFacetTypeEditingCache[first.typeKey()]
                facetTypeEditingCache[FacetTypeKey.ATTRIBUTE] = facetType
            }

            this.setState({ facetTypeEditingCache: facetTypeEditingCache, loaded: true })
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        if (_.isNil(this.state.facet.name)) {
            return []
        }
        return this.state.facet.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const facet = _.cloneDeep(this.state.facet)
        if (!_.isNil(facet.name)) {
            facet.name.removeLocalization(language)
        }
        this.setState({ facet: facet, dirty: true, currentLanguage: null })
    }

    handleInputChange = (l10n: L10nString | null) => {
        const facet = _.cloneDeep(this.state.facet)
        facet.name = l10n || new L10nString("")
        this.setState({ facet: facet, dirty: true })
    }

    handleIdChange(identifier: string) {
        const facet = _.cloneDeep(this.state.facet)
        facet.id = identifier
        this.setState({ dirty: true, error: null, identifier: identifier, facet: facet })
    }

    handleTypeChange(type: string) {
        if (type === this.state.facet.typeKey()) {
            return
        }

        const cachedAttributeType = this.state.facetTypeEditingCache[type]
        if (!cachedAttributeType) {
            return
        }

        const facetTypeEditingCache = _.cloneDeep(this.state.facetTypeEditingCache)
        facetTypeEditingCache[this.state.facet.typeKey()] = _.cloneDeep(this.state.facet.type!)
        const newFacet = _.cloneDeep(this.state.facet)
        newFacet.type = cachedAttributeType

        this.setState({ facetTypeEditingCache: facetTypeEditingCache, facet: newFacet, dirty: true })
    }

    descriptionForFacetTypeKey(key: FacetTypeKey): JSX.Element {
        let result = <br />
        switch (key) {
            case FacetTypeKey.ATTRIBUTE:
                result = (
                    <p>
                        With this type you can use an attribute as a facet for filtering in the POS app.
                        <br />
                        Please note that you can&apos;t select attributes of type &quot;Text&quot;.
                    </p>
                )
                break

            case FacetTypeKey.PRICE:
                result = (
                    <p>
                        Using this type will add a facet for price in the POS app.
                        <br />
                        It&apos;s possible to define the number of intervals used in the dropdown below.
                    </p>
                )
                break

            case FacetTypeKey.TAGS:
                result = (
                    <p>
                        Using this type will add a facet for all tags in the POS app.
                    </p>
                )
                break

            case FacetTypeKey.GROUP:
                result = (
                    <p>
                        Using this type will add a facet for all Product groups in POS app.
                    </p>
                )
                break
        }
        return result
    }

    renderType(): JSX.Element {
        switch (this.state.facet.typeKey()) {
            case FacetTypeKey.ATTRIBUTE:
                return this.renderAttribute()

            case FacetTypeKey.PRICE:
                return this.renderPrice()

            case FacetTypeKey.TAGS:
                return this.renderTags()

            case FacetTypeKey.GROUP:
                return this.renderGroups()
        }
    }

    renderNumberAttribute(attribute: Attribute): JSX.Element {
        const numberFacet = this.state.facet.type!.attribute!.type.number!
        // BG: clamp to max 3 decimals - if people need more they will have to tell us about the use case
        const scale = _.clamp(attribute.type.number!.scale, 0, 3)
        const step = 1 / Math.pow(10, scale)
        return (
            <div>
                <br />
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Min</DescriptionCol>
                    <Col sm={10}>
                        <InputGroup>
                            <FormControl
                                type="number"
                                name="min"
                                step={step}
                                value={Number(numberFacet.min)}
                                placeholder="Enter value"
                                onChange={(e: any) => {
                                    const newValue = Number(e.target.value)
                                    if (_.isNil(newValue)) {
                                        return
                                    }
                                    const newFacet = _.cloneDeep(this.state.facet)
                                    newFacet.type!.attribute!.type.number!.min = newValue
                                    this.setState({ facet: newFacet, dirty: true })
                                }}
                                onBlur={(e: any) => {
                                    const newValue = Number(e.target.value).toFixed(scale)
                                    const newFacet = _.cloneDeep(this.state.facet)
                                    newFacet.type!.attribute!.type.number!.min = Number(newValue)
                                    this.setState({ facet: newFacet, dirty: true })
                                }}
                                autoComplete="off"
                            />
                            <InputGroupAddon>{attribute.type.number!.suffix.localized(this.state.currentLanguage)}</InputGroupAddon>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Max</DescriptionCol>
                    <Col sm={10}>
                        <InputGroup>
                            <FormControl
                                type="number"
                                name="max"
                                step={step}
                                value={Number(numberFacet.max)}
                                placeholder="Enter value"
                                onChange={(e: any) => {
                                    const newValue = Number(e.target.value)
                                    if (_.isNil(newValue)) {
                                        return
                                    }
                                    const newFacet = _.cloneDeep(this.state.facet)
                                    newFacet.type!.attribute!.type.number!.max = newValue
                                    this.setState({ facet: newFacet, dirty: true })
                                }}
                                onBlur={(e: any) => {
                                    const newValue = Number(e.target.value).toFixed(scale)
                                    const newFacet = _.cloneDeep(this.state.facet)
                                    newFacet.type!.attribute!.type.number!.max = Number(newValue)
                                    this.setState({ facet: newFacet, dirty: true })
                                }}
                                autoComplete="off"
                            />
                            <InputGroupAddon>{attribute.type.number!.suffix.localized(this.state.currentLanguage)}</InputGroupAddon>
                        </InputGroup>
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Number of intervals</DescriptionCol>
                    <Col sm={10}>
                        <DropdownButton
                            title={`${numberFacet.intervalCount}`}
                            key="interval_count"
                            id={`dropdown-basic-options`}
                        >
                            {
                                Object.values(["2", "3", "4", "5", "6", "7", "8", "9", "10"]).map((value: string) => {
                                    return (
                                        <MenuItem
                                            key={value}
                                            onClick={(event: any) => {
                                                const newFacet = _.cloneDeep(this.state.facet)
                                                newFacet.type!.attribute!.type.number!.intervalCount = Number(value)
                                                this.setState({ facet: newFacet, dirty: true })
                                            }}
                                        >
                                            {value}
                                        </MenuItem>
                                    )
                                })
                            }
                        </DropdownButton>
                    </Col>
                </FormGroup>
            </div>
        )
    }

    renderAttributeType(attribute: Attribute): JSX.Element {
        switch (attribute.typeKey()) {
            case AttributeTypeKey.NUMBER:
                return this.renderNumberAttribute(attribute)

            default:
                return <br />
        }
    }

    renderAttribute(): JSX.Element {
        const id = this.state.facet.type!.attribute!.id
        const attribute = this.state.attributes[id]
        if (_.isNil(attribute)) {
            return <br />
        }

        const attributes: Attribute[] = []
        _.forEach(this.state.attributes, (value) => {
            attributes.push(new Attribute(value))
        })

        const sorted = attributes.sort((lhs, rhs) => {
            return lhs.name.localized(this.state.currentLanguage) < rhs.name.localized(this.state.currentLanguage) ? -1 : 1
        })

        return (
            <div>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Attribute</DescriptionCol>
                    <Col sm={10}>
                        <DropdownButton
                            title={attribute.name.localized(this.state.currentLanguage)}
                            key="attribute_picker"
                            id={`dropdown-basic-options`}
                        >
                            {
                                Object.values(sorted).map((value: Attribute) => {
                                    return (
                                        <MenuItem
                                            key={value.id}
                                            onClick={(event: any) => {
                                                const newAttribute = this.state.attributes[value.id]
                                                if (_.isNil(newAttribute)) {
                                                    return
                                                }

                                                const attributeFacetTypeEditingCache = _.cloneDeep(this.state.attributeFacetTypeEditingCache)
                                                attributeFacetTypeEditingCache[this.state.facet.type!.attribute!.type.typeKey()] = this.state.facet.type!.attribute!.type

                                                const newFacet = _.cloneDeep(this.state.facet)
                                                newFacet.type!.attribute!.id = value.id
                                                newFacet.type!.attribute!.type = attributeFacetTypeEditingCache[newAttribute.typeKey()]

                                                this.setState({ facet: newFacet, attributeFacetTypeEditingCache: attributeFacetTypeEditingCache, dirty: true })
                                            }}
                                        >
                                            {value.name.localized(this.state.currentLanguage)}
                                        </MenuItem>
                                    )
                                })
                            }
                        </DropdownButton>
                    </Col>
                </FormGroup>
                {this.renderAttributeType(attribute)}
            </div>
        )
    }

    renderPriceFacetConfig(): JSX.Element {
        switch (this.state.facet.type!.price!.facetConfig) {
            case PriceFacetConfig.AUTOMATIC:
                return this.renderAutomaticInterval()

            case PriceFacetConfig.MANUAL:
                return this.renderManualInterval()
        }
    }

    renderAutomaticInterval(): JSX.Element {
        const price = this.state.facet.type!.price!
        return (
            <div>
                <br />
                <DropdownButton
                    title={`${price.intervalCount}`}
                    key="interval_count"
                    id={`dropdown-basic-options`}
                >
                    {
                        Object.values(["2", "3", "4", "5", "6", "7", "8", "9", "10"]).map((value: string) => {
                            return (
                                <MenuItem
                                    key={value}
                                    onClick={(event: any) => {
                                        const newFacet = _.cloneDeep(this.state.facet)
                                        newFacet.type!.price!.intervalCount = Number(value)
                                        newFacet.type!.price!.intervals = undefined
                                        this.setState({ facet: newFacet, dirty: true })
                                    }}
                                >
                                    {value}
                                </MenuItem>
                            )
                        })
                    }
                </DropdownButton>
            </div>
        )
    }

    renderManualInterval(): JSX.Element {

        var components: JSX.Element[] = []

        if (_.isNil(this.state.facet.type!.price!.intervals!)) {
            components.push(this.renderIntervalComponent())
            return this.renderIntervalComponents(components)
        }

        let intervals = this.state.facet.type!.price!.intervals!

        components = intervals.map((interval, index) => {
            if (index === 0) {
                const fromInterval = 0
                const fromIndex = undefined
                const toInterval = interval
                const toIndex = index
                return this.renderIntervalComponent(fromInterval, fromIndex, toInterval, toIndex)
            } else {
                const fromIndex = index - 1
                const frominterval = intervals[fromIndex]
                const toInterval = interval
                const toIndex = index
                return this.renderIntervalComponent(frominterval, fromIndex, toInterval, toIndex)
            }
        })

        const lastIndex = intervals.length - 1
        const lastInterval = intervals[lastIndex]

        components.push(this.renderIntervalComponent(lastInterval, lastIndex, undefined, lastIndex))

        return this.renderIntervalComponents(components)
    }

    renderIntervalComponents(intervalComponents: JSX.Element[]) {
        return (
            <div>
                <br />
                {intervalComponents}
                {
                    this.state.intervalError ? (
                        <Alert variant="danger" style={{ width: "70%" }}>
                            {this.state.intervalError}
                        </Alert>
                    ) : []
                }
                <Button onClick={() => this.addInterval()}> Add interval </Button>
            </div >
        )
    }

    // Add new interval which is equal to lastInterval + 500
    addInterval() {
        const newFacet = _.cloneDeep(this.state.facet)
        var newInterval = 500
        // If no intervals are present, 
        // add one interval with value 500
        if (_.isNil(newFacet.type!.price!.intervals)) {
            newFacet.type!.price!.intervals = [newInterval]
            this.setState({ facet: newFacet, dirty: true })
            return
        }
        // If intervals are present
        // add new interval which is 
        // equal to latestInterval + 500
        var latestInterval = newFacet.type!.price!.intervals![newFacet.type!.price!.intervals!.length - 1]
        newInterval += latestInterval
        newFacet.type!.price!.intervals!.push(newInterval)
        newFacet.type!.price!.intervalCount = newFacet.type!.price!.intervals!.length + 1
        this.setState({ facet: newFacet, dirty: true })
    }

    renderIntervalComponent(fromInterval?: number, fromIndex?: number, toInterval?: number, toIndex?: number): JSX.Element {
        return (
            <div key={`${fromIndex}`}>
                <div>
                    <FormControl
                        style={{ width: "30%", display: "inline" }}
                        type="number"
                        name="fromInterval"
                        value={`${fromInterval ?? 0}`}
                        placeholder="Enter from interval"
                        onChange={(e: any) => { this.onIntervalChange(e, fromInterval, fromIndex) }}
                        autoComplete="off"
                    />
                    &nbsp;
                    to
                    &nbsp;
                    <FormControl
                        style={{ width: "30%", display: "inline" }}
                        type="number"
                        name="toInterval"
                        value={`${toInterval}`}
                        placeholder="Max number"
                        onChange={(e: any) => { this.onIntervalChange(e, toInterval, toIndex) }}
                        autoComplete="off"
                    />
                    &nbsp;
                    <DeleteButton onDelete={() => { this.onIntervalDelete(fromIndex, fromInterval, toIndex) }} />
                </div>
                &nbsp;
            </div>
        )
    }

    private onIntervalDelete(fromIndex?: number, fromInterval?: number, toIndex?: number) {
        if (_.isNil(fromIndex) && _.isNil(fromInterval) && fromInterval === 0) {
            return
        }
        const newFacet = _.cloneDeep(this.state.facet)
        const intervals = newFacet.type!.price!.intervals!

        if (_.isNil(intervals)) {
            return
        }

        newFacet.type!.price!.intervals!.splice(toIndex!, 1)

        // Remove intervals array if no elements 
        // are present within it
        if (newFacet.type!.price!.intervals!.length < 1) {
            newFacet.type!.price!.intervals = undefined
        }
        this.setIntervalErrorState(newFacet)
        this.setState({ facet: newFacet, dirty: true })
    }

    private onIntervalChange(e: any, interval?: number, index?: number) {
        const newValue = Number(e.target.value)
        if (_.isNil(newValue) || interval === undefined) {
            return
        }
        const newFacet = _.cloneDeep(this.state.facet)

        if (_.isNil(newFacet.type!.price!.intervals![index!])) {
            return
        }

        newFacet.type!.price!.intervals![index!] = newValue
        newFacet.type!.price!.intervalCount = newFacet.type!.price!.intervals!.length! + 1
        this.setIntervalErrorState(newFacet)
        this.setState({ facet: newFacet, dirty: true })
    }

    private setIntervalErrorState(facet: Facet) {
        if (facet.type?.price?.areIntervalsAscending()) {
            this.setState({ intervalError: null })
        } else {
            this.setState({ intervalError: "The intervals need to be in ascending order." })
        }
    }

    private renderPrice(): JSX.Element {
        const price = this.state.facet.type!.price!
        return (
            <span>
                <div>
                    <Row>
                        <DescriptionCol sm={2}>Interval config</DescriptionCol>
                        <Col sm={10}>
                            <DropdownButton
                                title={`${price.facetConfig}`}
                                key="price_facet_config"
                                id={`dropdown-basic-options`}
                            >
                                {
                                    Object.values([PriceFacetConfig.AUTOMATIC, PriceFacetConfig.MANUAL]).map((value: PriceFacetConfig) => {
                                        return (
                                            <MenuItem
                                                key={value}
                                                onClick={(event: any) => {
                                                    const newFacet = _.cloneDeep(this.state.facet)
                                                    newFacet.type!.price!.facetConfig = value

                                                    if (value === PriceFacetConfig.AUTOMATIC) {
                                                        newFacet.type!.price!.intervalCount = 5
                                                        newFacet.type!.price!.intervals = undefined
                                                    } else {
                                                        newFacet.type!.price!.intervals = [500]
                                                        newFacet.type!.price!.intervalCount = newFacet.type!.price!.intervals!.length + 1
                                                    }
                                                    this.setState({ facet: newFacet, dirty: true })
                                                }}
                                            >
                                                {value}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </DropdownButton>
                            {this.renderPriceFacetConfig()}
                        </Col>
                    </Row>
                    <br />
                </div>
            </span>
        )
    }

    renderTags(): JSX.Element {
        return <br />
    }

    renderGroups(): JSX.Element {
        return <br />
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="facet"
            >
                <Form>

                    <div className="float-sm-end">
                        <LanguagePicker
                            typeName="facet"
                            initialLanguage={this.state.currentLanguage}
                            resolveLanguages={this.resolveLanguages}
                            onChange={this.setLanguage}
                            onRemove={this.removeLanguage}
                        />
                    </div>
                    <br />

                    <Card className="my-4">
                        <Card.Header>{this.isNewFacet() ? "Create new facet" : `Edit facet '${this.state.facet.name!.localized(this.state.currentLanguage)}'`}</Card.Header>
                        <Card.Body>
                            <span key="a">
                                <FormGroup className="mb-3" as={Row}>
                                    <DescriptionCol sm={2}>Name</DescriptionCol>
                                    <Col sm={10}>
                                        <L10nFormControl
                                            l10n={this.state.facet.name || null}
                                            placeholder="Enter localized name"
                                            language={this.state.currentLanguage}
                                            onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                        />
                                    </Col>
                                </FormGroup>
                                {
                                    !this.isNewFacet() ? (
                                        <FormGroup className="mb-3" as={Row}>
                                            <DescriptionCol sm={2}>Identifier</DescriptionCol>
                                            <Col sm={10}>
                                                <FormControlStatic>{this.state.facet.id}</FormControlStatic>
                                            </Col>
                                        </FormGroup>
                                    ) : (
                                        <ValidatingIdEntryControl
                                            collectionRef={this.facetsRef()}
                                            isNew={this.isNewFacet()}
                                            typeName="facet"
                                            identifierSource={this.state.facet.name!.localized(this.state.currentLanguage)}
                                            existingIdentifier={this.state.identifier}
                                            handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                                        />
                                    )
                                }
                                <FormGroup className="mb-3" as={Row}>
                                    <DescriptionCol sm={2}>Type</DescriptionCol>
                                    <Col sm={10}>
                                        <DropdownButton
                                            title={typeToNameMap[this.state.facet.typeKey()]}
                                            key="attribute_type"
                                            id={`dropdown-basic-options`}
                                        >
                                            {
                                                Object.keys(typeToNameMap)
                                                    .filter((type: string) => {
                                                        if (type === FacetTypeKey.ATTRIBUTE) {
                                                            return Object.keys(this.state.attributes).length > 0
                                                        }
                                                        return true
                                                    })
                                                    .map((key: string) => {
                                                        return (
                                                            <MenuItem
                                                                key={key}
                                                                onClick={(event) => {
                                                                    this.handleTypeChange(key)
                                                                }}
                                                            >
                                                                {typeToNameMap[key]}
                                                            </MenuItem>
                                                        )
                                                    })
                                            }
                                        </DropdownButton>
                                        <div>
                                            <br />
                                            {this.descriptionForFacetTypeKey(this.state.facet.typeKey())}
                                        </div>

                                    </Col>
                                </FormGroup>

                                {this.renderType()}

                            </span>
                        </Card.Body>
                        <Card.Footer>
                            <Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button>
                        </Card.Footer>
                    </Card>
                </Form>
                {
                    this.state.error ? (
                        <Alert variant="danger">
                            <strong>Error publishing facet: </strong>
                            {this.state.error}
                        </Alert>
                    ) : []
                }
            </PageState>
        )
    }
}

export default withRoleRouter(FacetEdit)
