import React from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";
import { Col, DescriptionCol, FormControl, FormGroup, Row } from "src/components/wrappers";
import { GlobalEditProps } from "./GlobalModalEdit";
import { PDFMetadata } from "src/models/pdf_template/pdf_metadata";

export function EditMetadata(props: GlobalEditProps) {
    return (
        <Form>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Author</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Author"
                        value={props.pdfTemplate.metadata?.author ?? ""}
                        placeholder="Enter author"
                        onChange={(event) => {
                            const value = event.currentTarget.value as string;
                            updateMetadata((metadata) => {
                                metadata.author = value;
                                return metadata;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Title</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="Title"
                        value={props.pdfTemplate.metadata?.title ?? ""}
                        placeholder="Enter title"
                        onChange={(event) => {
                            const value = event.currentTarget.value as string;
                            updateMetadata((metadata) => {
                                metadata.title = value;
                                return metadata;
                            });
                        }} />
                </Col>
            </FormGroup>
            <FormGroup className="mb-3" as={Row}>
                <DescriptionCol sm={3}>
                    <Form.Label>Creator</Form.Label>
                </DescriptionCol>
                <Col sm={9}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="creator"
                        value={props.pdfTemplate.metadata?.creator ?? ""}
                        placeholder="Enter creator"
                        onChange={(event) => {
                            const value = event.currentTarget.value as string;
                            updateMetadata((metadata) => {
                                metadata.creator = value;
                                return metadata;
                            });
                        }} />
                </Col>
            </FormGroup>
        </Form>
    );

    function updateMetadata(callback: (metadata: PDFMetadata) => PDFMetadata) {
        let metadata = _.cloneDeep(props.pdfTemplate.metadata);
        if (_.isNil(metadata)) {
            metadata = new PDFMetadata()
        }
        metadata = callback(metadata);
        props.updatePDFTemplate((template) => {
            if (isMetadataEmpty(metadata)) {
                metadata = undefined;
            }
            template.metadata = metadata;
            return template;
        });
    }
}
function isMetadataEmpty(metadata: PDFMetadata | undefined) {
    return _.isEmpty(metadata?.author) && _.isEmpty(metadata?.creator) &&_.isEmpty(metadata?.title)
}
