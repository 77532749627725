import * as React from "react"
import {
    cloneDeep, set
} from "lodash"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import { ToggleButton } from "../../ToggleButton"
import { ModuleDefinition } from "./ModulesConfiguration"

import { CustomerLookupModule } from "../Modules/CustomerLookupModule"
import { FiscalRulesModule } from "../Modules/FiscalRulesModule"
import { MobilePayModule } from "../Modules/MobilePayModule"
import { NewsletterModule } from "../Modules/NewsletterModule"
import { SecondScreenBasketModule } from "../Modules/SecondScreenBasketModule"
import { TASLandingPageModule } from "../Modules/TASLandingPageModule"
import { TASNotificationsModule } from "../Modules/TASNotificationsModule"
import { GiftcardModule } from "../Modules/GiftcardModule"
import { ProductLabelModule } from "../Modules/ProductLabelModule"
import { GiftReceiptsModule } from "../Modules/GiftReceiptModule"
import { Button, Card, Collapse } from "react-bootstrap"
import { useState } from "react"
import { LoyaltyClubModule } from "../Modules/LoyaltyClubModule"
import { StockModule } from "../Modules/StockModule"

interface ModuleConfigurationProps {
    role: Role
    moduleDefinition: ModuleDefinition,
    moduleData: any
}

export interface ModuleProps {
    role: Role
    moduleData: any
    updateConfiguration: (configure: (data: any) => void) => void
}

interface ModuleConfigurationState {
    loaded: boolean
    publishing: boolean
    dirty: boolean
    moduleData: any
}

// TODO: Bundle this with the configurable front page module somehow
const defaultConfigurableFrontPage = {
    sections: [
        {
            shortcuts: {
                title: { da: "Genveje", en: "Shortcuts" },
                shortcuts: [
                    { shortcut_item: "customer_lookup" },
                    { shortcut_item: "cashier_dashboard" },
                    { shortcut_item: "basket_discount" },
                    { shortcut_item: "open_cash_drawer" },
                ]
            }
        },
        {
            most_sold_products: {
                title: { da: "Mest solgte varer", en: "Most sold products" },
                number_of_products: 8,
                time_interval_days: 14
            }
        }
    ]
}

export class ModuleConfiguration extends React.Component<ModuleConfigurationProps, ModuleConfigurationState> {

    constructor(props: ModuleConfigurationProps) {
        super(props)
        this.state = {
            moduleData: cloneDeep(props.moduleData ?? {}),
            loaded: true,
            publishing: false,
            dirty: false
        }
    }

    toggleModuleEnabled = () => {
        this.updateConfiguration(data => {
            if (data["enabled"]) {
                data["enabled"] = false
            } else {
                data["enabled"] = true
            }
        })
    }

    publishModuleConfiguration = async () => {
        this.setState({ publishing: true })

        // update module configurations
        const account = this.props.role.account_id
        const moduleConfigRef = ref().child(`v1/accounts/${account}/configuration/modules/${this.props.moduleDefinition.id}`)
        // Usually we just write the whole configuration, but if the 'update_properties' is set, we only update those
        if (this.props.moduleDefinition.update_properties) {
            const update: any = {}
            for (const property of this.props.moduleDefinition.update_properties) {
                update[property] = this.state.moduleData[property]
            }
            await moduleConfigRef.update(update)
        } else {
            await moduleConfigRef.set(this.state.moduleData)
        }

        // TODO: Bundle this with the configurable front page module somehow
        if (this.props.moduleDefinition.id === "configurable_front_page" && this.isEnabled()) {
            const frontPageRef = ref().child(`v1/accounts/${account}/app_data/pos/configurable_front_page`)
            const snap = await frontPageRef.once("value")
            if (!snap.exists()) {
                await frontPageRef.set(defaultConfigurableFrontPage)
            }
        }

        // Whenever we save the 'orders' module - no matter if we are enabling or disabling - remove legacy order config (c&c and r&c)
        if (this.props.moduleDefinition.id === "orders") {
            const ccModuleConfigRef = ref().child(`v1/accounts/${account}/configuration/modules/click_and_collect`)
            const rcModuleConfigRef = ref().child(`v1/accounts/${account}/configuration/modules/reserve_and_collect`)
            await ccModuleConfigRef.remove()
            await rcModuleConfigRef.remove()
        }

        this.setState({ publishing: false, dirty: false })
    }

    isEnabled(): boolean {
        const data = this.state.moduleData ?? {}
        return this.props.moduleDefinition.always_enabled ?? data["enabled"] ?? false
    }

    updateConfiguration = (update: (data: any) => void) => {
        const data = cloneDeep(this.state.moduleData ?? {})
        update(data)
        this.setState({ moduleData: data, dirty: true })
    }

    render() {
        const m = this.props.moduleDefinition
        const enabled = this.isEnabled()

        return <ModuleCard
            publishModuleConfiguration={() => this.publishModuleConfiguration()}
            dirty={this.state.dirty}
            updateConfiguration={data => { this.updateConfiguration(data) }}
            toggleModuleEnabled={() => { this.toggleModuleEnabled() }}
            role={this.props.role}
            moduleData={this.state.moduleData}
            moduleDefinition={this.props.moduleDefinition}
        />
    }
}

interface P extends ModuleConfigurationProps {
    updateConfiguration: (data: any) => void
    toggleModuleEnabled: () => void
    dirty: boolean
    publishModuleConfiguration: () => void
}

function ModuleCard(props: P) {
    const enabled = props.moduleDefinition.always_enabled ?? props.moduleData["enabled"] ?? false
    const [collapsed, setCollapsed] = useState(true)

    function moduleSpecific() {
        switch (props.moduleDefinition.id) {
            case "second_screen_basket":
                return (<SecondScreenBasketModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "tas_landing_page":
                return (<TASLandingPageModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "customer_lookup":
                return (<CustomerLookupModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "newsletter":
                return (<NewsletterModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "fiscal_rules":
                return (<FiscalRulesModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "mobile_pay":
                return (<MobilePayModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "tas_notifications":
                return (<TASNotificationsModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "giftcard":
                return (<GiftcardModule type={"giftcard"} role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "voucher":
                return (<GiftcardModule type={"voucher"} role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "product_labels":
                return (<ProductLabelModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "gift_receipts":
                return (<GiftReceiptsModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "loyalty_club":
                return (<LoyaltyClubModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)
            case "stock":
                return (<StockModule role={props.role} moduleData={props.moduleData} updateConfiguration={props.updateConfiguration} />)

            default:
                return null
        }
    }

    return <Card className="my-4" key={props.moduleDefinition.id} >
        <Card.Header style={{ color: enabled ? "#000000" : "#999999" }} onClick={() => setCollapsed(!collapsed)}>
            {props.moduleDefinition.name}
        </Card.Header>
        <Collapse in={!collapsed}>
            <div>
                <Card.Body>
                    {props.moduleDefinition.always_enabled === true ? null : (
                        <div>
                            <ToggleButton active={enabled} performToggle={async () => { props.toggleModuleEnabled() }} />
                            <br /><br />
                        </div>
                    )
                    }
                    <div>{props.moduleDefinition.description}</div>
                    <br />
                    {moduleSpecific()}
                </Card.Body>
                <Card.Footer>
                    <Button onClick={() => {
                        props.publishModuleConfiguration()
                        setCollapsed(true)
                    }} disabled={!props.dirty}>Publish</Button>
                </Card.Footer>
            </div>
        </Collapse>
    </Card>
}