import * as _ from "lodash"
import CopyToClipboard from "react-copy-to-clipboard"
import * as React from "react"

import { Alert, Button, FormControl, FormGroup, InputGroup, InputGroupAddon, Card } from "../../../wrappers"
import { shopifyAPIVersion, ShopifyConfiguration } from "./Models"
import { userId } from "../../../../helpers/auth"
import { Row } from "react-bootstrap"
import { BoxArrowUpRight, Clipboard2Check } from "react-bootstrap-icons"

interface StockEventsCardProps {
    accountId: string
    configuration: ShopifyConfiguration
    enabled: boolean
    openShopifyNotifications: () => void
    openShopifyPrivateApps: () => void
    updateDatabaseUpdates: (updates: _.Dictionary<any>) => void
    updateEnabled: (enabled: boolean) => void
    requestHeaders: () => any
}

interface StockEventsCardState {
    copied: boolean
    valid: boolean
}

export class StockEventsCard extends React.Component<StockEventsCardProps, StockEventsCardState> {

    constructor(props: StockEventsCardProps) {
        super(props)
        this.state = {
            copied: false,
            valid: this.isConfigurationValid(props.configuration)
        }
    }

    // Helpers

    isConfigurationValid(configuration: ShopifyConfiguration) {
        return Object.keys(configuration.locationIdMap).length > 0
    }

    stockChangesHelpText = (
        <div key="stock_changes_help_text">
            Follow these steps to configure synchronization of stock events between Ka-ching and Shopify
            <ol>
                <li>
                    Click the &quot;Open Shopify Configuration&quot; button above to open Shopify settings. Click the Ka-ching private app in that page.
                </li>
                <li>
                    Make sure that Ka-ching has access to update stock values in Shopify. The stock export from Ka-ching to Shopify requires &quot;Read and write&quot; to &quot;Inventory&quot;.
                </li>
                <li>
                    Remember to save any changes in Shopify.
                </li>
                <li>
                    Click the url below the stock location table to copy it to the clipboard.
                </li>
                <li>
                    Click the &quot;Open Shopify Notifications&quot; button above to open Shopify Notification settings.
                </li>
                <li>
                    Click &quot;Create Webhook&quot; and select the &quot;Inventory level update&quot; event. Choose &quot;{shopifyAPIVersion}&quot; as the &quot;Webhook API version&quot;. Leave the format as &quot;JSON&quot;. Select the URL field and paste the URL that you copied in step 5.
                </li>
                <li>
                    Repeat the previous step for the &quot;Inventory level connect&quot; event.
                </li>
                <li>
                    Toggle the Disabled/Enabled button below to enable the integration in Ka-ching.
                </li>
                <li>
                    IMPORTANT: To disable this integration, you must click the &quot;Enabled&quot; button below and remove the newly created webhooks in Shopify&apos;s Notification settings.
                </li>
            </ol>
        </div>
    )

    shopifyImportURL(): string {
        if (this.props.configuration.apiKey) {
            return `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/imports/stock/?apikey=${this.props.configuration.apiKey}&integration=shopify&account=${this.props.accountId}&transform=shopify`
        }
        return ""
    }

    enableButton = (enabled: boolean) => {
        return (
            <Button variant={enabled ? "success" : "default"} active={enabled} onClick={() => { this.toggleEnabled() }}>
                {enabled ? "Enabled" : "Disabled"}
            </Button>
        )
    }

    toggleEnabled = () => {
        const enabled = !this.props.enabled
        this.props.updateEnabled(enabled)

        // TODO - Use some kind of system user instead of the current user
        const uid = userId()

        // update customer lookup config based on enabled status
        if (enabled && uid) {
            const stockLocationMap = Object.keys(this.props.configuration.locationIdMap).reduce((result, currentKey) => {
                result[this.props.configuration.locationIdMap[currentKey]] = currentKey
                return result
            }, {})

            const importConfig = {
                name: "Shopify Stock Import",
                access_token: this.props.configuration.shopifyAccessToken,
                shopify_access_token: this.props.configuration.shopifyPassword,
                shopify_id: this.props.configuration.shopifyShopName,
                stock_location_map: stockLocationMap
            }
            const exportAdjustConfig = {
                delivery: {
                    parameters: {
                        headers: this.props.requestHeaders(),
                        method: "POST",
                        url: `https://${this.props.configuration.shopifyShopName}.myshopify.com/admin/api/${shopifyAPIVersion}/inventory_levels/adjust.json`
                    },
                    type: "web_hook"
                },
                filters: {
                    event_traits: {
                        adjustment: true
                    },
                    ignore_events: {
                        external_reset: true
                    }
                },
                name: "Shopify adjust",
                transformation: {
                    parameters: {
                        api_key: this.props.configuration.shopifyAPIKey ?? null,
                        access_token: this.props.configuration.shopifyAccessToken ?? null,
                        location_id_map: this.props.configuration.locationIdMap,
                        password: this.props.configuration.shopifyPassword ?? null,
                        shopify_id: this.props.configuration.shopifyShopName
                    },
                    type: "shopify"
                },
                trigger: {
                    type: "realtime"
                }
            }
            const exportSetConfig = {
                delivery: {
                    parameters: {
                        headers: this.props.requestHeaders(),
                        method: "POST",
                        url: `https://${this.props.configuration.shopifyShopName}.myshopify.com/admin/api/${shopifyAPIVersion}/inventory_levels/set.json`
                    },
                    type: "web_hook"
                },
                filters: {
                    event_traits: {
                        new_stock_value: true
                    },
                    ignore_events: {
                        external_reset: true
                    }
                },
                name: "Shopify set",
                transformation: {
                    parameters: {
                        api_key: this.props.configuration.shopifyAPIKey ?? null,
                        access_token: this.props.configuration.shopifyAccessToken ?? null,
                        location_id_map: this.props.configuration.locationIdMap,
                        password: this.props.configuration.shopifyPassword ?? null,
                        shopify_id: this.props.configuration.shopifyShopName
                    },
                    type: "shopify"
                },
                trigger: {
                    type: "realtime"
                }
            }

            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/api_keys/import_integrations/stock/shopify/${this.props.configuration.apiKey}/metadata`] = { active: true, uid: uid }
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/stock_events/shopify_adjust`] = exportAdjustConfig
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/stock_events/shopify_set`] = exportSetConfig
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/stock/shopify/name`] = importConfig.name
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/stock/shopify/shopify_id`] = importConfig.shopify_id
            if (!_.isNil(importConfig.shopify_access_token)) {
                dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/stock/shopify/shopify_access_token`] = importConfig.shopify_access_token
            }
            if (!_.isNil(importConfig.access_token)) {
                dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/stock/shopify/access_token`] = importConfig.access_token
            }
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/stock/shopify/stock_location_map`] = importConfig.stock_location_map
            this.props.updateDatabaseUpdates(dbUpdates)
        }
        if (!enabled && uid) {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/stock_events/shopify_adjust`] = null
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/export_integrations/stock_events/shopify_set`] = null
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/stock/shopify`] = null
            dbUpdates[`v1/accounts/${this.props.accountId}/api_keys/import_integrations/stock/shopify/${this.props.configuration.apiKey}/metadata`] = { active: false, uid: uid }
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    }

    // Component 

    UNSAFE_eceiveProps(newProps: StockEventsCardProps) {
        this.setState({ valid: this.isConfigurationValid(newProps.configuration) })
    }

    render() {
        return (
            <Card className="my-4" key="stock_events">
                <Card.Header>
                    Stock events
                </Card.Header>
                <Card.Body>
                    <Button onClick={() => { this.props.openShopifyPrivateApps() }}>Open Shopify Configuration <BoxArrowUpRight /></Button>
                    <Button onClick={() => { this.props.openShopifyNotifications() }}>Open Shopify Notifications <BoxArrowUpRight /> </Button>
                    <br /> <br />
                    {this.stockChangesHelpText}
                    <CopyToClipboard text={this.shopifyImportURL()} onCopy={() => this.setState({ copied: true })}>
                        <FormGroup className="mb-3" as={Row}>
                            <InputGroup>
                                <InputGroupAddon>Webhook URL</InputGroupAddon>
                                <FormControl type="text" readOnly={true} value={this.shopifyImportURL()} />
                                <InputGroupAddon>
                                    <Clipboard2Check />
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </CopyToClipboard>
                    {
                        this.state.copied
                            ?
                            <Alert variant="success"> Integration URL copied to clipboard. Now go to Shopify and configure your web hooks.</Alert>
                            :
                            null
                    }
                    {
                        this.state.valid
                            ?
                            this.enableButton(this.state.valid && this.props.enabled)
                            :
                            <Button variant="danger" disabled={true}>Invalid</Button>
                    }
                    <br /> <br />
                </Card.Body>
            </Card>
        )
    }
}