import * as _ from "lodash"
import * as React from "react"
import {
    Col,
    DescriptionCol,
    DropdownButton,
    FormControl,
    Checkbox,
    FormGroup,
    MenuItem,
    Card,
    Row
} from "../wrappers"
import { Container, Form, Stack } from "react-bootstrap"
import { PageState } from "../PageState"
import { Customer } from "../../models/Customer"
import { ToggleButton } from "../ToggleButton"
import { firestore, ref } from "../../config/constants"
import { AttributeSelection } from "./AttributeSelection"
import { AttributeValue } from "src/models/Product"
import { cloneDeep } from "lodash"
import firebase from "firebase/compat/app"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { StripedTable } from "../StripedTable"
import { RoleRouterProps, withRoleRouter } from 'src/routes';
import { LabelledControl } from "./ProductEdit"

interface CustomerDetailProps extends RoleRouterProps {
    id: string,
    isNew: boolean
}

interface CustomerDetailState {
    customer?: Customer,
    useLocalCustomerDatabase: boolean
    showB2BFields: boolean
    dirty: boolean
    publishing: boolean
    loading: boolean
    allPurchaseTypeInDB: string[],
    availablePurchaseTypes: Map<string, string>
    loyaltyPoints?: LoyaltyPoint[],
    isNewCustomer: boolean
}

interface LoyaltyPoint {
    currencyCode: string
    points: number
}

class CustomerDetail extends React.Component<CustomerDetailProps, CustomerDetailState> {
    constructor(props: CustomerDetailProps) {
        super(props)
        const id = props.router.params.id
        // const customerFromParent = new Customer(this.props.location.state?.customer)
        // const useLocalCustomerDatabaseFromParent = this.props.location.state?.useLocalCustomerDatabase ?? false
        this.state = {
            customer: undefined,
            useLocalCustomerDatabase: false,
            dirty: false,
            publishing: false,
            loading: false,
            allPurchaseTypeInDB: [],
            availablePurchaseTypes: new Map(),
            loyaltyPoints: undefined,
            showB2BFields: false,
            isNewCustomer: this.props.isNew
        }
    }

    async componentDidMount() {
        this.setState({ loading: true })
        const useLocalCustomerDb = await this.getlocalCustomerDatabaseStatus()
        if (!this.state.isNewCustomer) {
            await this.getCustomer(useLocalCustomerDb)
            await this.fetchAvailablePurchaseTypes()
            await this.getLoyaltyPoints()
        }

        let useB2B = this.state.showB2BFields
        if (!useB2B) {
            useB2B = await this.getB2BModuleEnabled()
        }
        this.setState({ loading: false, showB2BFields: useB2B })
    }

    async getlocalCustomerDatabaseStatus() {
        const snapshot = await this.customerLookupRef().get()
        if (snapshot.exists()) {
            const useLocalCustomerDatabase = snapshot.val()
            this.setState({ useLocalCustomerDatabase: useLocalCustomerDatabase })
            return useLocalCustomerDatabase
        }
        return false
    }

    customerLookupRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/modules/customer_lookup/use_local_customer_database`)
    }

    async getCustomer(useLocalCustomerDatabase: boolean) {
        if (useLocalCustomerDatabase) {
            await this.getLocalCustomer()
        } else {
            await this.getCustomerFromIntegration()
        }
    }

    async getLocalCustomer() {
        const snapshot = await this.customerRef().get()
        if (snapshot.exists) {
            const data = snapshot.data()
            const customer = new Customer(data)
            this.setState({ customer: customer })
        }
    }

    async getLoyaltyPoints() {
        const isLoyaltyClubModuleEnabled = await this.getLoyaltyClubModuleEnabled()
        if (isLoyaltyClubModuleEnabled === true) {
            const snapshot = await this.loyaltyPointsRef().get()
            if (!snapshot.empty) {
                const loyaltyPoints: LoyaltyPoint[] = []
                for (const doc of snapshot.docs) {
                    const data = doc.data()
                    const loyaltyPoint: LoyaltyPoint = {
                        points: data.points,
                        currencyCode: doc.id
                    }
                    loyaltyPoints.push(loyaltyPoint)
                }
                if (loyaltyPoints.length > 0) {
                    this.setState({
                        loyaltyPoints: loyaltyPoints
                    })
                }
            }
        }
    }

    async getLoyaltyClubModuleEnabled() {
        const snapshot = await this.loyaltyClubModuleRef().get()
        if (snapshot.exists()) {
            const loyaltyClubModule = snapshot.val()
            return loyaltyClubModule.enabled
        }
    }

    async getB2BModuleEnabled() {
        const snapshot = await this.B2BModuleRef().get()
        return (snapshot.val() ?? {}).enabled ?? false
    }

    loyaltyPointsRef() {
        const account = this.props.role.account_id
        return firestore
            .collection(`accounts/${account}/customers/${this.customerId()}/loyalty_points`)
    }

    loyaltyClubModuleRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/modules/loyalty_club`)
    }

    B2BModuleRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/modules/b2b`)
    }

    async getCustomerFromIntegration() {
        const args: any = {
            action: "customer-lookup",
            account: this.props.role.account_id,
            search_term: this.customerId(),
            currency_code: ""
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        let customer: Customer
        for (const customerData of result.data) {
            if (customerData.identifier === this.customerId()) {
                customer = new Customer(customerData)
                this.setState({ customer: customer })
                break
            }
        }
    }

    purchaseTypesRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/purchase_types/available`)
    }

    async fetchAvailablePurchaseTypes() {
        const snapshot = await this.purchaseTypesRef().get()
        if (snapshot.exists()) {
            const allPurchaseTypeInDB = snapshot.val()
            const purchaseTypeMap = new Map<string, string>()
            for (const purchaseType of allPurchaseTypeInDB) {
                purchaseTypeMap.set(purchaseType.identifier, purchaseType.name)
            }
            this.setState({
                allPurchaseTypeInDB: allPurchaseTypeInDB,
                availablePurchaseTypes: purchaseTypeMap
            })
        }
    }

    async submit() {
        this.setState({
            publishing: true
        })
        const customer = this.state.customer!
        const json = customer.toJSON()
        await this.customerRef().set(json)
        this.updateHistory(customer)
        this.setState({
            dirty: false,
            publishing: false
        })
    }

    updateHistory(customer: Customer) {
        // const state = cloneDeep(this.state)
        // this.props.history.replace(
        //     this.props.history.location.pathname,
        //     {
        //         ...state,
        //         customer: customer
        //     }
        // )
    }

    customerId(): string {
        return this.props.id
    }

    customerRef() {
        const account = this.props.role.account_id
        return firestore
            .collection(`accounts/${account}/customers`).doc(this.customerId())
    }

    handleToggleInputChange = (name: string) => {
        const value = !(this.state.customer?.[name] ?? true)
        const event = {
            target: {
                value: value,
                name: name,
                type: "boolean"
            }
        }
        this.handleInputChange(event)
    }

    handleAttributesChange = (attributes: _.Dictionary<AttributeValue>) => {
        const event = {
            target: {
                value: attributes,
                name: "attributes",
                type: "dictionary"
            }
        }
        this.handleInputChange(event)
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.value
        const key = target.name
        if (target.type === "number") {
            value = Number(value)
        }


        this.setState(prevState => {
            const currentCustomer = prevState.customer ?? new Customer({ identifier: this.customerId() })
            return {
                customer: this.updateCurrentCustomer(currentCustomer, key, value),
                dirty: true
            }
        })


    }

    updateCurrentCustomer<Value>(customer: Customer, key: string, value: Value): Customer {
        let updatedCustomer = cloneDeep(customer)
        updatedCustomer[key] = value
        this.updateIndexIfNecessary(updatedCustomer, key, value)
        return updatedCustomer
    }

    private updateIndexIfNecessary(updatedCustomer: Customer, key: string, value: any) {
        if ((key === "email" || key === "name") && typeof value === 'string') {
            const string = String(value).toLowerCase()
            if (_.isNil(updatedCustomer.index)) {
                updatedCustomer.index = {}
            }
            updatedCustomer.index[key] = string
        }
    }

    componentHidden(value: any) {
        return (!this.state.useLocalCustomerDatabase && _.isNil(value))
    }

    b2bComponentHidden(value: any) {
        return !this.state.showB2BFields || this.componentHidden(value)
    }

    purchaseTypeHidden() {
        const purchaseTypes = this.state.customer?.purchase_types
        return this.b2bComponentHidden(purchaseTypes) || (_.isNil(purchaseTypes) && this.state.allPurchaseTypeInDB.length == 0)
    }

    getDirtyState() {
        if (!this.state.useLocalCustomerDatabase) {
            return false
        }
        return this.state.dirty
    }

    renderLoyaltyPoints() {
        return (
            <StripedTable>
                <thead>
                    <tr>
                        <th>Currency Code</th>
                        <th>Points</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.loyaltyPoints?.map((loyaltyPoint) => {
                            return (
                                <tr key={loyaltyPoint.currencyCode}
                                    onClick={() => {
                                        this.props.router.navigate(`/customers/${this.props.id}/loyalty_points_log`)
                                    }}
                                >
                                    <td>{loyaltyPoint.currencyCode} </td>
                                    <td> {loyaltyPoint.points} </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </StripedTable>
        )
    }

    renderPurchaseTypes() {
        return (
            <>
                {(this.state.customer?.purchase_types?.length ?? 0) > 0 && <StripedTable>
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Value</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.customer?.purchase_types?.map((id) => {
                                return (
                                    <tr key={id}>
                                        <td>{id} </td>
                                        <td> {this.state.availablePurchaseTypes.get(id)} </td>
                                        <td className="narrow">
                                            <ConfirmDeleteButton
                                                message={`Really delete ${this.state.availablePurchaseTypes.get(id)} purchase type?`}
                                                onDelete={() => {
                                                    this.removePurchaseType(id)
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </StripedTable>
                }
                {this.renderDropDownButton()}
            </>
        )
    }

    renderDropDownButton() {
        const unsusedPurchaseTypes = this.unusedPurchaseTypes()
        if (unsusedPurchaseTypes.length > 0 || !this.state.useLocalCustomerDatabase) {
            return (
                <DropdownButton
                    title="Add purchase type"
                    id="dropdown-add-purchase-type"
                    onSelect={(selectedAttribute: any) => {
                        this.addPurchaseType(selectedAttribute)
                    }}
                >
                    {unsusedPurchaseTypes.map((key) => {
                        const value = this.state.availablePurchaseTypes.get(key)
                        return (<MenuItem key={key} eventKey={key}>{value}</MenuItem>)
                    })}
                </DropdownButton>
            )
        } else {
            return null
        }
    }

    unusedPurchaseTypes() {
        let unusedPurchaseTypes = cloneDeep(this.state.availablePurchaseTypes)
        for (const value of this.state.customer?.purchase_types ?? []) {
            unusedPurchaseTypes.delete(value)
        }
        return Array.from(unusedPurchaseTypes.keys())
    }

    addPurchaseType(purchaseType: string) {
        const selectedPurchaseTypes = this.state.customer?.purchase_types ?? []
        selectedPurchaseTypes.push(purchaseType)
        const event = this.createEvent(selectedPurchaseTypes, "purchase_types")
        this.handleInputChange(event)
    }

    removePurchaseType(purchaseType: string) {
        const selectedPurchaseTypes = this.state.customer?.purchase_types ?? []
        let newPurchaseTypes: string[] | undefined = selectedPurchaseTypes.filter((key) => key !== purchaseType)
        if (newPurchaseTypes.length === 0) {
            newPurchaseTypes = undefined
        }
        const event = this.createEvent(newPurchaseTypes, "purchase_types")
        this.handleInputChange(event)
    }

    private createEvent<Value>(value: Value, name: string, type: string = "") {
        return {
            target: {
                value: value,
                name: name,
                type: type
            }
        }
    }

    renderCardBody() {
        return (
            <Form>
                <Container style={{ width: 950, marginLeft: "auto", marginRight: "auto" }}>
                    <Row>
                        <Col>
                            <Stack direction="horizontal" className="pb-2" gap={2}>
                                <h4>{this.state.customer?.name}</h4>
                            </Stack>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} lg={8}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <LabelledControl label="Identifier">
                                        <FormControl
                                            disabled={true}
                                            type="text"
                                            name="identifier"
                                            value={this.customerId()}
                                            placeholder="Enter customer ID"
                                        />

                                    </LabelledControl>

                                    <LabelledControl label="Name" hidden={this.componentHidden(this.state.customer?.name)}>
                                        <FormControl
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="name"
                                            value={this.state.customer?.name}
                                            placeholder="Enter customer name"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>

                                    <LabelledControl label="Phone" hidden={this.componentHidden(this.state.customer?.phone)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="phone"
                                            value={this.state.customer?.phone}
                                            placeholder="Enter customer phone"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>
                                    <LabelledControl label="Email" hidden={this.componentHidden(this.state.customer?.email)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="email"
                                            value={this.state.customer?.email}
                                            placeholder="Enter customer email"
                                            onChange={this.handleInputChange}
                                        />

                                    </LabelledControl>
                                    <LabelledControl label="Additional info" hidden={this.componentHidden(this.state.customer?.additional_info)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="additional_info"
                                            value={this.state.customer?.additional_info}
                                            placeholder="Enter customer additional info"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>

                                    <LabelledControl label="Organization number" hidden={this.b2bComponentHidden(this.state.customer?.organization_number)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="organization_number"
                                            value={this.state.customer?.organization_number}
                                            placeholder="Enter customer organization number"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>


                                    <LabelledControl label="Accepts marketing"
                                        hidden={this.componentHidden(this.state.customer?.accepts_marketing)}
                                    >
                                        <Checkbox style={{ paddingTop: "3px" }}
                                            type="checkbox"
                                            checked={this.state.customer?.accepts_marketing}
                                            id="accepts_marketing"
                                            onChange={() => { this.handleToggleInputChange("accepts_marketing") }}
                                        ></Checkbox>
                                    </LabelledControl>
                                </Card.Body>
                            </Card>

                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Address info</Card.Title>
                                    <LabelledControl label="Street" hidden={this.componentHidden(this.state.customer?.street)}>
                                        <FormControl
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="street"
                                            value={this.state.customer?.street}
                                            placeholder="Enter customer street"
                                            onChange={this.handleInputChange}
                                        />

                                    </LabelledControl>
                                    <LabelledControl label="City" hidden={this.componentHidden(this.state.customer?.city)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="city"
                                            value={this.state.customer?.city}
                                            placeholder="Enter customer city"
                                            onChange={this.handleInputChange}
                                        />

                                    </LabelledControl>
                                    <LabelledControl label="Postal code" hidden={this.componentHidden(this.state.customer?.postal_code)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="postal_code"
                                            value={this.state.customer?.postal_code}
                                            placeholder="Enter customer postal code"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>
                                    <LabelledControl label="Country" hidden={this.componentHidden(this.state.customer?.country)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="country"
                                            value={this.state.customer?.country}
                                            placeholder="Enter customer country"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>
                                    <LabelledControl label="Country code" hidden={this.componentHidden(this.state.customer?.country_code)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="text"
                                            name="country_code"
                                            value={this.state.customer?.country_code}
                                            placeholder="Enter customer country code"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>
                                </Card.Body>
                            </Card>
                            {
                                !this.componentHidden(this.state.customer?.attributes) ?
                                    <AttributeSelection
                                        attributeType="customer"
                                        account={this.props.role.account_id}
                                        disabled={!this.state.useLocalCustomerDatabase}
                                        selectedAttributes={this.state.customer?.attributes || {}}
                                        currentLanguage={null}
                                        onChange={(attributes) => { this.handleAttributesChange(attributes) }}
                                    />
                                    : null
                            }

                            {this.state.showB2BFields && <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>B2B</Card.Title>
                                    <LabelledControl label="Credit limit" hidden={this.b2bComponentHidden(this.state.customer?.credit_limit)}>
                                        <Form.Control
                                            disabled={!this.state.useLocalCustomerDatabase}
                                            type="number"
                                            name="credit_limit"
                                            value={this.state.customer?.credit_limit}
                                            placeholder="Enter customer credit limit"
                                            onChange={this.handleInputChange}
                                        />
                                    </LabelledControl>

                                    <LabelledControl label="Block sales" hidden={this.b2bComponentHidden(this.state.customer?.credit_limit)}>
                                        <div>
                                            <ToggleButton
                                                disabled={!this.state.useLocalCustomerDatabase}
                                                active={this.state.customer?.block_sales ?? false}
                                                performToggle={async () => { this.handleToggleInputChange("block_sales") }}
                                            />
                                        </div>
                                    </LabelledControl>

                                    <LabelledControl label="Purchase types" hidden={this.purchaseTypeHidden()}>
                                        {this.renderPurchaseTypes()}
                                    </LabelledControl>

                                    <LabelledControl label="Balance" hidden={this.b2bComponentHidden(this.state.customer?.balance)}>
                                        <Form.Control
                                            disabled={true}
                                            type="text"
                                            name="name"
                                            value={this.state.customer?.balance}
                                            placeholder="Customer balance"
                                            onChange={() => { }}
                                        />
                                    </LabelledControl>

                                    <LabelledControl label="Balance overdue" hidden={this.b2bComponentHidden(this.state.customer?.balance_overdue)}>
                                        <Form.Control
                                            disabled={true}
                                            type="text"
                                            name="name"
                                            value={this.state.customer?.balance_overdue}
                                            placeholder="Customer balance overdue"
                                            onChange={() => { }}
                                        />
                                    </LabelledControl>

                                </Card.Body>
                            </Card>
                            }
                            <Card className="mb-4">
                                <Card.Body>
                                    <Card.Title>Loyalty</Card.Title>
                                    <LabelledControl label="Loyalty points" hidden={this.componentHidden(this.state.loyaltyPoints) || _.isNil(this.state.loyaltyPoints)}>
                                        {this.renderLoyaltyPoints()}
                                    </LabelledControl>
                                </Card.Body>
                            </Card>
                        </Col></Row>
                </Container>
            </Form >
        )
    }

    render() {
        return (
            <PageState loading={this.state.loading} publishing={this.state.publishing} dirty={this.getDirtyState()} typeName="customer" submit_title={this.state.isNewCustomer ? "Save customer" : "Publish customer changes"} submit_action={async () => { this.submit() }}>
                {this.renderCardBody()}
            </PageState>
        )
    }
}

export default withRoleRouter(CustomerDetail)

