import * as React from "react"
import { ToggleButton } from "../../ToggleButton"
import {
    Button,
    Col,
    
    Form,
    FormGroup,
    Card,
} from "../../wrappers"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { RoleRouterProps, withRouter } from "src/routes"
import { Row } from "react-bootstrap"

interface CapabilitiesConfigurationState {
    loaded: boolean
    publishing: boolean
    showSuccess: boolean
    supported: string[]
    selectedSupported: string[]
}

class CapabilitiesConfiguration extends React.Component<RoleRouterProps, CapabilitiesConfigurationState> {

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            loaded: false,
            publishing: false,
            showSuccess: false,
            supported: ["click_and_collect", "reserve_and_collect", "ship_from_store", "inventory_transfer"],
            selectedSupported: []
        }
    }

    async componentDidMount() {
        const account = this.props.role.account_id
        const shop = this.props.router.params.shopKey

        const supportedHandlingRef = ref().child(`v1/accounts/${account}/shops/${shop}/configuration/order_handling/supported`)
        const supportedSnapshot = await supportedHandlingRef.once("value")

        if (!supportedSnapshot || !supportedSnapshot.exists()) {
            this.setState({
                loaded: true
            })
            return
        } else {
            const selectedSupported = supportedSnapshot.val()
            this.setState({
                loaded: true,
                selectedSupported: selectedSupported
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} publishing={this.state.publishing} typeName="Order handling configuration">
                <Card className="my-4" key="upper_panel" border={this.state.showSuccess ? "success" : "default"}>
                    <Card.Header>
                        {this.state.showSuccess ? "Order handling configuration updated successfully" : "Order handling configuration"}
                    </Card.Header>
                    <Card.Body>
                        <div />
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                                {this.state.supported.map((value) => {
                                    const currentEnabledState = this.setEnabledState(value)
                                    return (
                                        <div key={value}>
                                            <Col sm={2}>{this.setTitle(value)}</Col>
                                            <Col sm={10}>
                                                <ToggleButton active={currentEnabledState} performToggle={async () => { this.updateSelectedSupported(value, currentEnabledState) }} />
                                                <br /><br />
                                            </Col>
                                        </div>
                                    )
                                }
                                )}
                            </FormGroup>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        <Button onClick={this.publish} >Publish</Button>
                    </Card.Footer>
                </Card >
            </PageState>
        )
    }

    private setEnabledState(value: string): boolean {
        return this.state.selectedSupported.includes(value)
    }

    private updateSelectedSupported(value: string, currentEnabledState: boolean) {
        var selectedSupported = this.state.selectedSupported

        if (currentEnabledState) {
            const index = selectedSupported.findIndex((element: string) => element === value)
            if (index !== -1) {
                selectedSupported.splice(index, 1)
            }
        } else {
            selectedSupported.push(value)
        }

        this.setState({
            selectedSupported: selectedSupported
        })
    }

    private setTitle(value: string): string {
        switch (value) {
            case "click_and_collect": return "Click & Collect"
            case "reserve_and_collect": return "Reserve & Collect"
            case "ship_from_store": return "Ship from Store"
            case "inventory_transfer": return "Inventory transfer"
            default: return ""
        }
    }

    supportedUpdate = (data: any) => {
        const supported: string[] = Object.values(data)
        this.setState({ selectedSupported: supported })
    }

    publish = async () => {
        const account = this.props.role.account_id
        const shop = this.props.router.params.shopKey
        const shopConfigPath = `v1/accounts/${account}/shops/${shop}/configuration`
        const capabilitiesRef = ref().child(`${shopConfigPath}/order_handling/supported`)

        this.setState({ publishing: true })

        await capabilitiesRef.set(this.state.selectedSupported)

        this.setState({ showSuccess: true, publishing: false })
    }
}

export default withRouter(CapabilitiesConfiguration)
