import { DropzoneComponent, DropzoneComponentProps } from "react-dropzone-component"

import firebase from "firebase/compat/app"
import React from "react"
import * as ReactDOM from "react-dom"
import { Buffer } from 'buffer';

interface CallableDropzoneComponentProps extends DropzoneComponentProps {
    submitRequest?: (xhr: any, formdata: any, files: any) => void
    account: string
    shop?: string
    functionName: FunctionName
}

export enum FunctionName {
    // We should probably move the stock csv importer to be a client callable as well
    StockCSVImporter = "Stock-stockCSVImporter",
    BudgetCSVImporter = "budget-csv-import",
    CustomerCSVImporter = "customer-csv-import"
}

// NOTE:
// Based on https://raw.githubusercontent.com/enyo/dropzone/master/dist/dropzone.js
// and https://github.com/felixrieseberg/React-Dropzone-Component/blob/master/src/react-dropzone.js
// I have tampered with Dropzone + Component in order to make it upload to firebase storage instead of simply post"ing.
// More fiddling may be necessary in the future.

// TODO: This is still basically javascript - 'any' is used to silence typescript-compiler
export class CallableDropzoneComponent extends DropzoneComponent<CallableDropzoneComponentProps> {
    dropzone?: Dropzone
    componentDidMount() {
        if (super.componentDidMount) {
            super.componentDidMount()
        }
        const anyThis = this
        if (this.dropzone) {
            this.dropzone.uploadFiles = function (files: any) {
                const file = files[0]
                const fileReader = new FileReader()
                fileReader.onload = () => {
                    const buffer = Buffer.from(fileReader.result as string)
                    const args = getArguments(anyThis.props, buffer)
                    const callable = getFunction(anyThis.props.functionName);
                    callable(args).then((response) => {
                        this.emit("uploadprogress", file, 100, file.upload.bytesSent);                    
                        (this as any)._finished([file], "Import started", null)
                    }).catch((error) => {
                        console.error(`Stock CSV import error: ${error}`)
                    })
                }
                fileReader.readAsText(file)
            }
        }
    }
}

function getArguments(props: CallableDropzoneComponentProps, buffer: Buffer) {
    const args = {
        account: props.account,
        csv: buffer.toString("base64")
    };
    if (props.shop) {
        args["shop"] = props.shop
    }
    if (props.functionName === FunctionName.BudgetCSVImporter) {
        args["action"] = FunctionName.BudgetCSVImporter
    }

    if (props.functionName === FunctionName.CustomerCSVImporter) {
        args["action"] = FunctionName.CustomerCSVImporter
    } 
    return args;
}

function getFunction(functionName: FunctionName) {
    switch (functionName) {
        case FunctionName.StockCSVImporter: 
            return firebase.functions().httpsCallable(functionName)
        case FunctionName.BudgetCSVImporter: 
            return firebase.functions().httpsCallable("clientApi");
        case FunctionName.CustomerCSVImporter:
            return firebase.functions().httpsCallable("clientApi");
    }
}
