import * as React from "react"

import { Button, Card } from "../../wrappers"
import LoadingButton, { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import { ToggleButton } from "../../ToggleButton"
import { RoleProps, withRole } from "src/routes"

interface MarkdownConfigurationState {
    dirty: boolean
    loaded: boolean
    publishing: boolean
    enabled: boolean
}

class MarkdownConfiguration extends React.Component<RoleProps, MarkdownConfigurationState> {

    constructor(props: RoleProps) {
        super(props)

        this.state = {
            dirty: false,
            loaded: false,
            publishing: false,
            enabled: false,
        }
    }

    // Helpers

    publish = async () => {
        this.setState({
            publishing: true
        })
        const account = this.props.role.account_id
        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/markdown_enabled`)
        await configRef.set(this.state.enabled)
        this.setState({
            dirty: false,
            publishing: false
        })
    }

    toggleMarkdownEnabled = () => {
        this.setState({
            dirty: true,
            enabled: !this.state.enabled
        })
    }

    // Component

    async componentDidMount() {
        const account = this.props.role.account_id

        const configRef = ref().child(`v1/accounts/${account}/configuration/pos/markdown_enabled`)
        const configSnap = await configRef.once("value")

        if (configSnap.exists()) {
            const value = configSnap.val()
            this.setState({
                loaded: true,
                enabled: value
            })
        } else {
            this.setState({
                loaded: true
            })
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="Markdown Configuration">
                <Card className="my-4" key="markdown" /* XXX TODO defaultExpanded={false} */>
                    <Card.Header>
                        Markdown configuration
                    </Card.Header>
                    <Card.Body>
                        <p> Enabling markdown allows using markdown syntax in the description of a product. </p>
                        <p> Currently we support these markdown features: headers, bold text, italic text, strikethrough text, inline code and links.</p>
                        <div>
                            <ToggleButton active={this.state.enabled} performToggle={async () => { this.toggleMarkdownEnabled() }} />
                            <br /><br />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <LoadingButton onClick={this.publish} disabled={!this.state.dirty}></LoadingButton>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }
}

export default MarkdownConfiguration