import React, { useState } from "react";
import { PDFTemplate } from "src/models/pdf_template/pdf_template";
import _ from "lodash";
import { Button, Modal } from "src/components/wrappers";
import { EditGlobalMargins } from "./EditGlobalMargins";
import { EditGlobalBackground } from "./EditGlobalBackground";
import { EditMetadata } from "./EditMetadata";

interface GlobalModalEditProps {
    onCancel: () => void;
    onOK: (template: PDFTemplate) => void;
    pdfTemplate: PDFTemplate;
    editType: GlobalEditType;
    show: boolean;
}
export enum GlobalEditType {
    margins, background, metadata
}
export function GlobalModalEdit(props: GlobalModalEditProps) {
    const [pdfTemplate, setPDFTemplate] = useState<PDFTemplate>(props.pdfTemplate);

    return (
        <Modal show={props.show} size="lg">
            <Modal.Header>
                <Modal.Title> {getEditTitle(props.editType)} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <GlobalEditBody pdfTemplate={pdfTemplate} updatePDFTemplate={updatePDFTemplate} editType={props.editType}></GlobalEditBody>
            </Modal.Body>

            <Modal.Footer>
                <Button onClick={props.onCancel}>Cancel</Button>
                <Button variant="primary" disabled={false} onClick={() => { props.onOK(pdfTemplate); }}> OK </Button>
            </Modal.Footer>

        </Modal>
    );

    function updatePDFTemplate(callback: UpdatePDFTemplateCallback) {
        let updatedLine = _.cloneDeep(pdfTemplate);
        updatedLine = callback(updatedLine);
        setPDFTemplate(updatedLine);
    }
}
type UpdatePDFTemplateCallback = (template: PDFTemplate) => PDFTemplate;

function getEditTitle(type: GlobalEditType) {
    switch (type) {
        case GlobalEditType.margins:
            return "Edit margins"
        case GlobalEditType.background:
            return "Edit backgroun"
        case GlobalEditType.metadata:
            return "Edit metadata"
    }
}

interface GlobalEditBodyProps {
    updatePDFTemplate: (callback: UpdatePDFTemplateCallback) => void;
    pdfTemplate: PDFTemplate;
    editType: GlobalEditType;
}
function GlobalEditBody(props: GlobalEditBodyProps) {
    switch (props.editType) {
        case GlobalEditType.margins:
            return <EditGlobalMargins pdfTemplate={props.pdfTemplate} updatePDFTemplate={props.updatePDFTemplate}></EditGlobalMargins>;
        case GlobalEditType.background:
            return <EditGlobalBackground pdfTemplate={props.pdfTemplate} updatePDFTemplate={props.updatePDFTemplate}></EditGlobalBackground>;
        case GlobalEditType.metadata:
            return <EditMetadata pdfTemplate={props.pdfTemplate} updatePDFTemplate={props.updatePDFTemplate}></EditMetadata>;
    }
}

export interface GlobalEditProps  {
    updatePDFTemplate: (callback: UpdatePDFTemplateCallback) => void;
    pdfTemplate: PDFTemplate;
}
