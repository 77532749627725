//
//  AttributeGroupList.tsx
//  POSFirebaseHosting
//
//  Created by Morten Bek Ditlevsen on 6/11/2020.
//  Copyright © 2020 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button, Card } from "react-bootstrap"
import { forEach } from "lodash"
import firebase from "firebase/compat"
import { ref } from "../../config/constants"
import { PageState } from "../PageState"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { StripedTable } from "../StripedTable"
import { Role } from "../../config/role"
import { AttributeGroup } from "../../models/Product"
import { LanguageCode } from "../../helpers/L10n"
import { RoleRouterProps, withRoleRouter } from "src/routes"

interface AttributeGroupListState {
    attributeGroups: AttributeGroup[]
    currentLanguage: LanguageCode | null
    loaded: boolean
}

class AttributeGroupList extends React.Component<RoleRouterProps, AttributeGroupListState> {

    // Properties

    initialState: AttributeGroupListState = {
        attributeGroups: [],
        currentLanguage: LanguageCode.en,
        loaded: false
    }

    // Component

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.loadState()
    }

    componentWillUnmount() {
        this.attributeGroupsRef().off()
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="Attribute Groups">
                <Card className="my-4">
                    <Card.Header>
                        <Button onClick={this.newAttributeGroup}>New Attribute Group</Button>
                    </Card.Header>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.attributeGroups.map(attributeGroup => {
                                    return (
                                        <tr key={attributeGroup.id} onClick={() => { this.editAttributeGroup(attributeGroup.id) }}>
                                            <td>{attributeGroup.name.localized(this.state.currentLanguage) ?? attributeGroup.name.value}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Really delete ${attributeGroup.name.localized(this.state.currentLanguage)} attribute group?`}
                                                    onDelete={async () => { await this.removeAttributeGroup(attributeGroup.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </StripedTable>
                    <Card.Footer>
                        <Button onClick={this.newAttributeGroup}>New Attribute Group</Button>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }

    // State

    loadState() {
        this.attributeGroupsRef()
            .on("value", snapshot => {
                if (!snapshot || (snapshot && !snapshot.exists())) {
                    this.setState({ attributeGroups: [], loaded: true })
                    return
                }

                const attributeGroups: AttributeGroup[] = []
                forEach(snapshot.val(), (value) => {
                    const attributeGroup = new AttributeGroup(value)
                    attributeGroups.push(attributeGroup)
                })

                const sorted = attributeGroups.sort((lhs, rhs) => {
                    return lhs.name.localized(this.state.currentLanguage) < rhs.name.localized(this.state.currentLanguage) ? -1 : 1
                })

                this.setState({
                    attributeGroups: sorted,
                    loaded: true
                })
            })
    }

    // Attribute

    newAttributeGroup = () => {
        this.props.router.navigate("/attribute_group/new")
    }

    editAttributeGroup = (key: string) => {
        this.props.router.navigate(`/attribute_group/${key}`)
    }

    async removeAttributeGroup(key: string) {
        await this.attributeGroupsRef().child(key).set(null)
    }

    // Helpers

    attributeGroupsRef(): firebase.database.Reference {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/attribute_groups")
    }
}

export default withRoleRouter(AttributeGroupList)
