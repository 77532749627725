import * as React from "react"
import { Button, Card } from "../wrappers"
import { CSVDropzoneComponent } from "../CSVDropzoneComponent"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { LanguageCode } from "../../helpers/L10n"
import LoadingButton, { PageState } from "../PageState"
import { RecommendationCategory } from "../../models/RecommendationCategory"
import { ref } from "../../config/constants"
import { Role } from "../../config/role"
import { StripedTable } from "../StripedTable"
import { token } from "../../helpers/auth"
import { RoleRouterProps, withRoleRouter } from "src/routes"

const eventHandlers = { 
    error: (error: any) => {
        console.error(`Error: ${JSON.stringify(error)}`)
    }
}

// these css styles are a feeble attempt to make the table rows not look too bad while being dragged....

const narrowStyle = {
    width: "10%",
    textAlign: "center" as "center"
}

const nameStyle = {
    width: "80%",
    textAlign: "left" as "left"
}

interface RecommendationsMaintainanceState {
    categories: RecommendationCategory[],
    dirty: boolean
    loaded: boolean
    publishing: boolean
    token: string
}

class RecommendationsMaintainance extends React.Component<RoleRouterProps, RecommendationsMaintainanceState> {

    // Constructor

    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            categories: [],
            dirty: false,
            loaded: false,
            publishing: false,
            token: ""
        }
    }

    // Methods

    add = () => {
        this.props.router.navigate("/recommendation_category/new")
    }

    publish = async () => {
        this.setState({ publishing: true })
        const json: any[] = []
        for (const key in this.state.categories) {
            const category = this.state.categories[key]
            json.push(category.json())
        }
        await ref().child(`v1/accounts/${this.props.role.account_id}/inventory/recommendation_categories`).set(json, (error) => {
            if (error) {
                console.log(error)
            }
        })
        this.setState({ publishing: false, dirty: false })
    }

    onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination || result.destination.index === result.source.index) {
            return
        }
    
        // no movement
        if (result.destination.index === result.source.index) {
            return
        }
    
        let newOrdering = Array.from(this.state.categories)
        const [removed] = newOrdering.splice(result.source.index, 1)
        newOrdering.splice(result.destination.index, 0, removed)
        newOrdering = newOrdering.map((v: RecommendationCategory, index: number) => { v.rank = index + 1; return v })
        
        this.setState({
            categories: newOrdering,
            dirty: true
        })
    }

    remove = (index: number) => {
        const category = this.state.categories[index]
        if (window.confirm(`Really delete the category "${category.name.localized(LanguageCode.da)}"?`) === true) {
            this.setState({ publishing: true })
            let newOrdering = Array.from(this.state.categories)
            newOrdering.splice(index, 1)
            newOrdering = newOrdering.map((v: RecommendationCategory, i: number) => { v.rank = i + 1; return v })
            this.setState({ categories: newOrdering }, async () => {
                await this.publish()
            })
        }
    }

    // Dropzone related

    djsConfig = () => {
        return {
            autoProcessQueue: true,
            headers: {
                "Content-Type": "application/octet-stream",
                "Authorization": "Bearer " + this.state.token
            },
            dictDefaultMessage: "Add CSV file containing product recommendations here."
        }
    }

    componentConfig = () => {
        const url = `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/app/recommendation_csv_importer/${this.props.role.account_id}`
        return {
            iconFiletypes: [".csv"],
            showFiletypeIcon: true,
            postUrl: url
        }
    }

    submitRequest = (xhr: any, formData: any, files: any) => {
        xhr.send(files[0])
    }

    // React 

    async componentDidMount() {
        const tokenValue: string | null = await token()
        if (tokenValue !== null) {
            this.setState({ categories: [], token: tokenValue })
        }
        const models: RecommendationCategory[] = []
        const categoriesSnapshot = await ref().child(`/v1/accounts/${this.props.role.account_id}/inventory/recommendation_categories`).once("value")
        if (categoriesSnapshot && categoriesSnapshot.exists()) {
            const categories = categoriesSnapshot.val()
            let rank = 1
            for (const key in categories) {
                const category = categories[key]
                models.push(new RecommendationCategory(category, rank))
                rank += 1
            }
        }
        this.setState({
            loaded: true,
            categories: models
        })
    }

    render() {
        return (
            <PageState dirty={this.state.dirty} loading={!this.state.loaded} typeName="recommendations" publishing={this.state.publishing}>
                <Card className="my-4">
                    <Card.Header>
                        Recommendation categories
                    </Card.Header>
                    <Card.Body>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(droppableProvided) => (
                                    <div ref={droppableProvided.innerRef}>
                                        <StripedTable>
                                            <thead>
                                                <tr>
                                                    <th>Rank</th>
                                                    <th>Id</th>
                                                    <th>Name</th>
                                                    <th>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.categories.map((category, index) => (
                                                    <Draggable key={category.id} draggableId={category.id} index={index}>
                                                        {(draggableProvided: any) => (
                                                            <tr 
                                                                ref={draggableProvided.innerRef} 
                                                                key={category.id}
                                                                onClick={() => {
                                                                    this.props.router.navigate(`/recommendation_category/${category.id}`)
                                                                }}
                                                                {...draggableProvided.draggableProps}
                                                                {...draggableProvided.dragHandleProps}
                                                            >
                                                                <td style={narrowStyle}>{category.rank}</td>
                                                                <td style={narrowStyle}>{category.id}</td>
                                                                <td style={nameStyle}>
                                                                    {category.name.localized(LanguageCode.da)}
                                                                </td>
                                                                <td style={narrowStyle}>
                                                                    <Button 
                                                                        variant="danger" 
                                                                        onClick={async (event) => { 
                                                                            event.stopPropagation()
                                                                            this.remove(index)
                                                                        }}
                                                                    >
                                                                    X
                                                                    </Button>   
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </tbody>
                                        </StripedTable>
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Card.Body>
                    <Card.Footer>
                        <Button onClick={this.add} style={{ textAlign: "right" }}>Add</Button>
                        &nbsp;
                        <LoadingButton onClick={this.publish} disabled={!this.state.dirty}/>
                    </Card.Footer>
                </Card>
                <Card className="my-4">
                    <Card.Header>
                        Recommendations import
                    </Card.Header>
                    <Card.Body>
                        <strong>Upload product recommendations CSV</strong>
                        <CSVDropzoneComponent
                            key="recommendations-csv-drop-zone"
                            config={this.componentConfig()}
                            eventHandlers={eventHandlers}
                            djsConfig={this.djsConfig()}
                            submitRequest={this.submitRequest}
                        />
                    </Card.Body>
                </Card>
            </PageState>
        )
    }

}

export default withRoleRouter(RecommendationsMaintainance)