import * as React from "react"
import { RoleRouterProps } from "../../../routes"
import { useEffect, useState } from "react"
import { StripedTable } from "../../StripedTable"
import { ref } from "../../../config/constants"
import { Form, Spinner } from "react-bootstrap"
import _ from "lodash"

interface Item {
    id: string
    name: string
}

interface ShopSelectionProps {
    accountId: string
    shopsSelected(selection: Set<string>): void
}

export function ShopSelection(props: ShopSelectionProps) {
    const [shops, setShops] = useState<Item[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [useAllShops, setUseAllShops] = useState(true)
    const [selectedShops, setSelectedShops] = useState<Set<string>>(new Set())

    useEffect(() => {
        const fetchData = async () => {
            const shopsRef = ref().child(`v1/accounts/${props.accountId}/shop_index`)
            const shopsSnap = await shopsRef.get()
            const shops = shopsSnap.val() ?? {}
            const activeShops: Item[] = []
            for (const key in shops) {
                const shop = shops[key]
                if (shop.deactivated === true) { continue }
                activeShops.push({ id: key, name: shop.name })
            }

            setShops(activeShops)

            // The state from 'setShops' is not yet propagated to the system, so
            // we need to include the shops in the call to 'allShops'
            const selectedShops_ = allShops(activeShops)
            setSelectedShops(selectedShops_)
            props.shopsSelected(selectedShops_)
            setIsLoading(false)
        }
        fetchData()

    }, [props.accountId])

    function allShops(shopList: Item[] = shops): Set<string> {
        const shopIds: Set<string> = new Set()
        for (const item of shopList) {
            shopIds.add(item.id)
        }
        return shopIds
    }

    return <span>

        {isLoading ? <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner> :

            <span>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="All shops"
                    checked={useAllShops}
                    onChange={a => {
                        const useAll = a.target.checked
                        setUseAllShops(useAll)
                        if (useAll) {
                            props.shopsSelected(allShops())
                        } else {
                            props.shopsSelected(_.cloneDeep(selectedShops))
                        }
                    }}
                />

                {!useAllShops &&
                    <StripedTable>
                        <thead>
                            <tr><th>Toggle all</th><th>
                                <Form.Check // prettier-ignore
                                    type="checkbox"
                                    id="custom-switch"
                                    label=""
                                    checked={selectedShops.size === shops.length}
                                    onChange={a => {
                                        const checked = a.target.checked
                                        const newSelection: Set<string> = checked ? allShops() : new Set()
                                        setSelectedShops(newSelection)
                                        props.shopsSelected(newSelection)
                                    }}
                                />

                            </th></tr>
                        </thead>
                        <tbody>
                            {shops.map((item, index) => {
                                return <tr key={item.id}>
                                    <td>{item.name}</td>
                                    <td>
                                        <Form.Check // prettier-ignore
                                            type="checkbox"
                                            id="custom-switch"
                                            label=""
                                            checked={selectedShops.has(item.id)}
                                            onChange={a => {
                                                const clone = _.cloneDeep(selectedShops)
                                                if (a.target.checked) {
                                                    clone.add(item.id)
                                                } else {
                                                    clone.delete(item.id)
                                                }
                                                setSelectedShops(clone)
                                                props.shopsSelected(clone)
                                            }}
                                        />

                                    </td>
                                </tr>
                            })}
                        </tbody>

                    </StripedTable>
                }
            </span>

        }
    </span>
}

