import firebase from "firebase/compat/app"
import _, {
    Dictionary,
    head,
    last,
    take
} from "lodash"
import { Product, RepoMetadata } from "../../../models/Product"
import { ref } from "../../../config/constants"
import { Role } from "../../..//config/role"
import { sortLikeFirebaseOrderByKey } from "../../../helpers/sorting"

export interface ProductListViewModelType {
    inputs: ProductListViewModelInputs
    outputs: ProductListViewModelOutputs

    teardown(): void
}

export interface ProductListViewModelInputs {
    start(): Promise<void>
    loadInitialProducts(fromKey?: string): void
    loadPreviousProducts(fromKey: string): void
    loadNextProducts(): void
    removeProduct(key: string): Promise<boolean>
    performSearch(searchTerm: string, marketId?: string): void
}

export interface ProductListViewModelOutputs {
    didLoadProducts?: (products: RepoProduct[], startKey: string) => void
    didPerformSearch?: (products: Product[]) => void
    isShowingSearch: boolean
    isReadOnly: boolean
    isPreviousDisabled: boolean
    isNextDisabled: boolean
    productPath: string
    productsRef: firebase.database.Reference | undefined
    generateNewKey(): string | null
}

export interface RepoProduct {
    metadata: RepoMetadata
    product: Product
}

export class ProductListViewModel implements ProductListViewModelType, ProductListViewModelInputs, ProductListViewModelOutputs {
    // ProductListViewModelType

    get inputs(): ProductListViewModelInputs { return this }
    get outputs(): ProductListViewModelOutputs { return this }

    // Inputs

    public async start() {
        const marketRef = this.marketRef()
        if (marketRef) {
            const snapshot = await marketRef.once("value")
            const market = snapshot.val()
            if (market) {
                this.market = market
            }
        }
        this.teardown()
        this.setupObservers()
    }

    public async loadInitialProducts(fromKey?: string) {
        const productsRef = this.productsRef
        if (!productsRef) { return }

        let query = productsRef.orderByChild("archived").limitToFirst(this.pageLimit + 1)

        if (fromKey) {
            query = query.startAt(null, fromKey).endAt(false)
        } else {
            query = query.startAt(null, fromKey).endAt(false)
        }

        await this.performLoad(query)
    }

    public async loadPreviousProducts(fromKey: string) {
        const productsRef = this.productsRef
        if (!productsRef) { return }

        const query = productsRef.orderByChild("archived")
            .endAt(null, fromKey)
            .limitToLast(this.pageLimit + 1)

        await this.performLoad(query)
    }

    public async loadNextProducts() {
        const productsRef = this.productsRef
        if (!productsRef || !this.nextStartKey) { return }

        const query = productsRef.orderByChild("archived")
            .limitToFirst(this.pageLimit + 1)
            .startAt(null, this.nextStartKey)
            .endAt(false)

        await this.performLoad(query)
    }

    public async removeProduct(key: string): Promise<boolean> {
        if (this.isReadOnly) {
            return false
        }

        const productsRef = this.productsRef
        if (!productsRef) {
            return false
        }

        const productRef = productsRef.child(key)
        await productRef.remove()

        const productAssetsRef = this.productAssetsRef(key)
        await productAssetsRef.set(null)

        return true
    }

    public async performSearch(searchTerm: string, marketId?: string) {
        this.isShowingSearch = true

        const args = {
            action: "product-search",
            account: this.accountId,
            search_term: searchTerm,
            market_id: marketId ?? this.market
        }

        const search = firebase.functions().httpsCallable("clientApi")
        const result = await search(args)
        const products: Product[] = Object.values(result.data).map(json => { return new Product(json) })

        if (this.didPerformSearch) {
            this.didPerformSearch(products)
        }
    }

    // Outputs

    public didLoadProducts?: (products: RepoProduct[], startKey: string) => void
    public didPerformSearch?: (products: Product[]) => void

    public isShowingSearch: boolean = false

    public get isReadOnly(): boolean {
        return this.isPresentingReadOnlyAccountProducts
    }

    public isPreviousDisabled: boolean
    public isNextDisabled: boolean

    public get productPath(): string {
        return "/products/"
    }

    public get productsRef(): firebase.database.Reference | undefined {
        if (this.isPresentingReadOnlyAccountProducts) {
            if (!this.market) {
                return undefined
            }
            return this.accountRef.child(`inventory/products/pos/${this.market}`)
        }
        return this.accountRef.child("inventory/product_repo")
    }

    public generateNewKey(): string | null {
        return ref().push().key
    }

    // Properties

    private role: Role
    private get accountId(): string { return this.role.account_id }
    private market?: string
    private pageLimit: number = 30
    private nextStartKey?: string
    private veryFirstProductKey?: string

    // Constructor

    constructor(role: Role) {
        this.role = role
        this.isPreviousDisabled = false
        this.isNextDisabled = true
    }

    private setupObservers() {
        const productsRef = this.productsRef
        if (!productsRef) {
            return
        }

        productsRef.orderByChild("archived").endAt(false).limitToFirst(1).on("value", snapshot => {
            if (!snapshot || !snapshot.exists()) {
                return
            }

            const values = snapshot.val()
            const keys = Object.keys(values)
            this.veryFirstProductKey = head(keys)
        })
    }

    teardown() {
        const productsRef = this.productsRef
        if (!productsRef) {
            return
        }

        productsRef.off()
    }

    private async performLoad(query: firebase.database.Query) {
        this.isShowingSearch = false

        let startKey: string = ""
        let hasMoreValues = false
        let result: RepoProduct[] = []

        const snapshot = await query.once("value")
        if (snapshot.exists()) {
            const dict: Dictionary<any> = snapshot.val()
            const sortedKeys = sortLikeFirebaseOrderByKey(Object.keys(dict))

            const values: any[] = []
            for (const key of sortedKeys) {
                const v = dict[key]

                if (this.isPresentingReadOnlyAccountProducts) {
                    const market = this.market ?? ""
                    values.push({ product: v, metadata: { markets: { [market]: true } } })
                } else {
                    if (_.isNil(v.product)) { continue }
                    values.push(v)
                }
            }
            if (values.length > 0) {
                const firstProduct = head(values)

                startKey = firstProduct.product.id
                hasMoreValues = sortedKeys.length > this.pageLimit

                this.nextStartKey = last(values).product.id
            }

            result = take(values.map(json => { return { product: new Product(json.product), metadata: json.metadata } }), this.pageLimit)
            console.log("RESULT", result)
        }

        this.updateDisableds(startKey, hasMoreValues)

        if (this.didLoadProducts) {
            this.didLoadProducts(result, startKey)
        }
    }

    private updateDisableds(startKey: string, hasMoreValues: boolean) {
        this.isPreviousDisabled = startKey === this.veryFirstProductKey
        this.isNextDisabled = !hasMoreValues
    }

    // Helpers

    private get isPresentingReadOnlyAccountProducts(): boolean {
        if (this.role.isShopOwner()) {
            return true
        }

        return false
    }

    private get accountRef(): firebase.database.Reference {
        return ref().child(`v1/accounts/${this.accountId}`)
    }

    private productAssetsRef(key: string): firebase.database.Reference {
        return this.accountRef.child(`inventory/product_assets/${key}`)
    }

    private marketRef(): firebase.database.Reference | undefined {
        if (this.isPresentingReadOnlyAccountProducts && this.role.shop_id) {
            return ref().child(`v1/accounts/${this.accountId}/shops/${this.role.shop_id}/market`)
        }
        return undefined
    }
}
