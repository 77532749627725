import * as React from "react"
import { FormattedTime } from "react-intl"
import { ref } from "../../config/constants"
import { Col, Row, Grid, Table } from "../wrappers"
import { FormattedCurrency } from "../FormattedCurrency"
import { PageState } from "../PageState"
import { paymentTypeNameFromId } from "../../helpers/PaymentTypeNames"
import { isNil } from "lodash"
import { RoleShopProps, withShop } from "src/routes"

interface RegisterCloseStatementViewProps extends RoleShopProps {
    id: string
}

class RegisterCloseStatementView extends React.Component<RegisterCloseStatementViewProps, any> {
    constructor(props: RegisterCloseStatementViewProps) {
        super(props)
        this.state = {
            loaded: false
        }
    }

    statementsRef() {
        const account = this.props.role.account_id
        const shop = this.props.shop
        return ref().child(`v1/accounts/${account}/shops/${shop}/register_close_statements`)
    }

    async componentDidMount() {
        const key = this.props.id
        const statementRef = this.statementsRef().child(key)
        const snapshot = await statementRef.once("value")
        console.log(snapshot.val())
        this.setState(snapshot.val() ?? { source: {}, register_summary: { sales: {}, returns: {}, all: {} }, reconciliations: [] })
        this.setState({ loaded: true })
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="statement">
                {
                    this.state.loaded ? this.renderRegisterCloseStatement() : null
                }
            </PageState>
        )
    }

    renderRegisterCloseStatement() {
        const zero = 0 // to get rid of annoying warning about comparing to self
        return (
            <Grid>
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Date
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedTime
                            value={new Date(this.state.reconciliation_time * 1000)}
                            day="numeric"
                            month="long"
                            year="numeric"
                            hour="numeric"
                            minute="numeric"
                        />
                    </Col>
                </Row>

                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Register
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.source.register_name}
                    </Col>
                </Row>

                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Cashier
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.source.cashier_name || this.state.source.cashier_display_name}
                    </Col>
                </Row>

                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Sequence number
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.sequence_number}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Cash drawer open count
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        {this.state.register_summary.event_counts?.cash_drawer_opened || "0"}
                    </Col>
                </Row>
                <Row>
                    <Col /*as="ControlLabel"*/ sm={3}>
                        Number of aborted sales
                    </Col>
                    <Col sm={4} style={{ textAlign: "right" }}>
                        {this.state.register_summary.event_counts?.sale_aborted || "0"}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Sales
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.sales.total} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Returns
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.returns.total} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                {
                    (this.state.register_summary.expenses || {}).count || zero > 0 ?
                        (
                            <Row>
                                <Col /*as="ControlLabel"*/ sm={2}>
                                    Expenses
                                </Col>
                                <Col sm={5} style={{ textAlign: "right" }}>
                                    <FormattedCurrency value={this.state.register_summary.expenses.total} currency={this.state.base_currency_code || "DKK"} />
                                </Col>
                            </Row>
                        )
                        : null
                }
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Total
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.all.total} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Output VAT
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.sales.total_tax_amount + this.state.register_summary.returns.total_tax_amount} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                {
                    (this.state.register_summary.expenses || {}).count || zero > 0 ?
                        (
                            <Row>
                                <Col /*as="ControlLabel"*/ sm={2}>
                                    Input VAT
                                </Col>
                                <Col sm={5} style={{ textAlign: "right" }}>
                                    {/* The value is negated since the sign is implicit in the label: 'input vat' */}
                                    <FormattedCurrency value={-(this.state.register_summary.expenses.total_tax_amount)} currency={this.state.base_currency_code || "DKK"} />
                                </Col>
                            </Row>
                        ) : null
                }
                <br />
                < Row >
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Total without taxes
                    </Col>
                    <Col sm={5} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.all.base_price} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>
                <br />
                < Row >
                    <Col /*as="ControlLabel"*/ sm={3}>
                        Cash register opening float
                    </Col>
                    <Col sm={4} style={{ textAlign: "right" }}>
                        <FormattedCurrency value={this.state.register_summary.cash_total_at_open} currency={this.state.base_currency_code || "DKK"} />
                    </Col>
                </Row>

                <br />
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Reconciliations
                    </Col>
                </Row>

                <Row><Col sm={7}>
                    <Table className="table-transparent">
                        <thead>
                            <tr>
                                <th style={{ border: "none" }} />
                                <th style={{ border: "none", textAlign: "right" }}>
                                    Counted
                                </th>
                                <th style={{ border: "none", textAlign: "right" }}>
                                    Expected
                                </th>
                                <th style={{ border: "none", textAlign: "right" }}>
                                    Difference
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state?.reconciliations?.flatMap((x: any, i: number) => {
                                    const transactions: any[] = this.state.register_summary?.all?.transactions ?? []
                                    const baseCurrency = this.state.base_currency_code
                                    const matchingPaymentType = transactions.find(t => { return t.type === x.payment_type_identifier })
                                    const byCurrency = matchingPaymentType?.totals?.all?.by_currency[baseCurrency]
                                    const byCardType = byCurrency?.by_card_type

                                    const totalsByCardType: [string, number][] = []
                                    for (const cardType in byCardType ?? {}) {
                                        const totals = byCardType[cardType]
                                        const total = totals?.total ?? 0
                                        totalsByCardType.push([cardType, total])
                                    }

                                    const elements: JSX.Element[] = []
                                    elements.push(<tr key={i.toString()}>
                                        <td style={{ border: "none" }}>
                                            <b>{paymentTypeNameFromId(x.payment_type_identifier)}</b>
                                        </td>
                                        <td style={{ border: "none", textAlign: "right" }}>
                                            <FormattedCurrency value={x.counted} currency={x.currency_code} />
                                        </td>
                                        <td style={{ border: "none", textAlign: "right" }}>
                                            <FormattedCurrency value={x.total} currency={x.currency_code} />
                                        </td>
                                        <td style={{ border: "none", textAlign: "right" }}>
                                            <FormattedCurrency value={x.counted - x.total} currency={x.currency_code} />
                                        </td>
                                    </tr>)
                                    for (const element of totalsByCardType) {
                                        const [cardType, total] = element
                                        elements.push(
                                            <tr style={{ color: "#888888" }} key={`${i.toString()}_${cardType}`}>
                                                <td style={{ border: "none" }}>
                                                    &nbsp;&nbsp;{formattedCardType(cardType)}
                                                </td>
                                                <td style={{ border: "none", textAlign: "right" }}>
                                                    <FormattedCurrency value={total} currency={baseCurrency} />
                                                </td>
                                                <td style={{ border: "none", textAlign: "right" }}>
                                                    {/* <FormattedCurrency value={x.total} currency={x.currency_code} /> */}
                                                </td>
                                                <td style={{ border: "none", textAlign: "right" }}>
                                                    {/* <FormattedCurrency value={x.counted - x.total} currency={x.currency_code} /> */}
                                                </td>
                                            </tr>
                                        )
                                    }
                                    return elements
                                })
                            }
                        </tbody>
                    </Table>
                </Col></Row>

                <br />
                <Row>
                    <Col /*as="ControlLabel"*/ sm={2}>
                        Deposits
                    </Col>
                </Row>

                <Row>
                    <Col sm={7}>
                        <Table className="table-transparent">
                            <thead>
                                <tr>
                                    <th style={{ border: "none" }} />
                                    <th style={{ border: "none", textAlign: "right" }}>
                                        Deposited
                                    </th>
                                    <th style={{ border: "none", textAlign: "right" }}>
                                        Remaining
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    this.state
                                        .reconciliations
                                        .filter((x: any) => {
                                            return !isNil(x.deposited_amount)
                                        })
                                        .map((x: any, i: number) => {
                                            return (
                                                <tr key={i.toString()}>
                                                    <td style={{ border: "none" }} >
                                                        <b>{paymentTypeNameFromId(x.payment_type_identifier)}</b>
                                                    </td>
                                                    <td style={{ border: "none", textAlign: "right" }}>
                                                        <FormattedCurrency value={x.deposited_amount} currency={x.currency_code} />
                                                    </td>
                                                    <td style={{ border: "none", textAlign: "right" }}>
                                                        <FormattedCurrency value={x.counted - x.deposited_amount} currency={x.currency_code} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Grid >
        )
    }
}

function formattedCardType(cardType: string): string {
    return cardType.charAt(0).toUpperCase() + cardType.slice(1);
}

export default withShop(RegisterCloseStatementView)
