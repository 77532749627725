export type StockDBEntry = number | _.Dictionary<number>

export class StockListItem {
    count: number | null
    productId: string
    name: string
    variantId: string | null
    archived: boolean

    constructor(productId: string, variantId: string | null, name: string, count: number | null, archived: boolean) {
        this.count = count
        this.productId = productId
        this.name = name
        this.variantId = variantId
        this.archived = archived
    }
}