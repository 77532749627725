import * as React from "react"
import { firestore, ref } from "../../config/constants"
import { Col, Form, FormControl, FormGroup, Card, DescriptionCol } from "../wrappers"
import  { PageState } from "../PageState"
import { stripEmptyValues } from "../../helpers/stripEmptyValues"
import { CashierSelection } from "../../models/CashierSelection"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { RoleRouterProps, withRoleRouter } from "src/routes"
import { Row } from "react-bootstrap"

interface ShopEditState {
    identifier: string
    city: string
    name: string
    street: string
    zip: string,
    phone: string,
    cvr: string
    market: string
    available_markets: string[]
    loaded: boolean
    publishing: boolean
    dirty: boolean
    identifierInvalid: boolean
    shopKey: string
    isNewShop: boolean
}

class ShopEdit extends React.Component<RoleRouterProps, ShopEditState> {
    constructor(props: RoleRouterProps) {
        super(props)
        this.state = {
            identifier: props.router.params.shopKey === "new" ? "" : props.router.params.shopKey,
            city: "",
            name: "",
            street: "",
            zip: "",
            phone: "",
            cvr: "",
            market: "",
            available_markets: [],
            loaded: false,
            publishing: false,
            dirty: false,
            identifierInvalid: false,
            shopKey: props.router.params.shopKey,
            isNewShop: props.router.params.shopKey === "new"
        }
    }

    shopKey() {
        return this.state.shopKey
    }

    isNewShop() {
        return this.state.isNewShop
    }

    async componentDidMount() {
        const key = this.shopKey()
        const account = this.props.role.account_id

        this.setState({ loaded: false })

        const promises = []
        const accountMarketsRef = ref().child(`v1/accounts/${account}/markets`)
        promises.push(accountMarketsRef.once("value"))
        if (!this.isNewShop()) {
            const addressRef = ref().child(`v1/accounts/${account}/shops/${key}/configuration/address`)
            promises.push(addressRef.once("value"))

            const cvrRef = ref().child(`v1/accounts/${account}/shops/${key}/configuration/cvr`)
            promises.push(cvrRef.once("value"))

            const shopMarketRef = ref().child(`v1/accounts/${account}/shops/${key}/market`)
            promises.push(shopMarketRef.once("value"))
        }

        const [accountMarketsSnap, addressSnap, cvrSnap, shopMarketSnap] = await Promise.all(promises)
        const accountMarketsDict = accountMarketsSnap.val()

        this.setState({ loaded: true })

        if (this.isNewShop()) {
            this.setState({
                available_markets: accountMarketsDict,
                market: Object.keys(accountMarketsDict)[0]
            })
        } else {
            const newState = {}

            if (addressSnap.exists()) {
                const addressDict = addressSnap.val()
                newState["city"] = addressDict["city"]
                newState["name"] = addressDict["name"]
                newState["street"] = addressDict["street"]
                newState["zip"] = addressDict["zip"]
                newState["phone"] = addressDict["phone"]
            }

            if (cvrSnap.exists()) {
                const cvrValue = cvrSnap.val()
                if (cvrValue) {
                    newState["cvr"] = cvrValue
                }
            }

            if (accountMarketsDict) {
                newState["available_markets"] = accountMarketsDict
            }

            if (shopMarketSnap.exists()) {
                const market = shopMarketSnap.val()
                newState["market"] = market
            }

            this.setState(newState)
        }
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.type === "checkbox" ? target.checked : target.value
        const name = target.name

        if (target.type === "number") {
            value = Number(value)
        }

        const newState: any = {
            [name]: value,
            dirty: true
        }

        this.setState(newState)
    }

    generateAddress() {
        const address = {
            city: this.state.city,
            name: this.state.name,
            street: this.state.street,
            zip: this.state.zip,
            phone: this.state.phone
        }

        stripEmptyValues(address)

        return address
    }

    validate() {
        if (!this.state.name || !this.state.market || this.state.identifierInvalid || !this.state.identifier) {
            return false
        }

        return true
    }

    handleUpdateResponse = (error: any) => {
        this.setState({ publishing: false, dirty: false })
        if (error) {
            alert("An error occurred while saving changes.")
        }
    }

    create = async () => {
        if (!this.validate()) {
            alert("A new shop must have a name, an identifier and a market.")
            return
        }

        const accountKey = this.props.role.account_id
        const shopKey = this.state.identifier

        const newAddress = this.generateAddress()
        const newIndex = { name: this.state.name }

        const updates = {}
        updates[`v1/accounts/${accountKey}/shops/${shopKey}/cashiers/default`] = {
            display_name: "C",
            full_name: "Cashier",
            identifier: "default"
        }
        updates[`v1/accounts/${accountKey}/shops/${shopKey}/configuration/cashier_selection`] = CashierSelection.default.toJSON()
        updates[`v1/accounts/${accountKey}/shops/${shopKey}/configuration/address`] = newAddress
        updates[`v1/accounts/${accountKey}/shops/${shopKey}/market`] = this.state.market
        if (this.state.cvr) {
            updates[`v1/accounts/${accountKey}/shops/${shopKey}/configuration/cvr`] = this.state.cvr
        }
        updates[`v1/accounts/${accountKey}/shop_index/${shopKey}`] = newIndex
        updates[`v1/accounts/${accountKey}/stock_location_index/${shopKey}`] = { type: "shop", name: this.state.name }

        this.setState({ publishing: true })

        await firestore.collection(`accounts/${accountKey}/shops`).doc(shopKey).set(newIndex)
        await firestore.collection(`accounts/${accountKey}/stock_locations`).doc(shopKey).set({
            type: "shop",
            name: this.state.name
        })

        await ref().update(updates, this.handleUpdateResponse)

        this.setState({ publishing: false, isNewShop: false, shopKey: shopKey })
        const path = `/shop/${shopKey}/edit`
        this.props.router.navigate(path)
    }

    publish = async () => {
        if (!this.validate()) {
            alert("A shop must have a name, an identifier and a market.")
            return
        }

        const accountKey = this.props.role.account_id
        const shopKey = this.props.router.params.shopKey

        const newAddress = this.generateAddress()
        const newIndex = { name: this.state.name }

        const updates = {}
        updates[`v1/accounts/${accountKey}/shops/${shopKey}/configuration/cashier_selection`] = CashierSelection.default.toJSON()
        updates[`v1/accounts/${accountKey}/shops/${shopKey}/configuration/address`] = newAddress
        updates[`v1/accounts/${accountKey}/shops/${shopKey}/market`] = this.state.market
        if (this.state.cvr) {
            updates[`v1/accounts/${accountKey}/shops/${shopKey}/configuration/cvr`] = this.state.cvr
        }
        updates[`v1/accounts/${accountKey}/shop_index/${shopKey}`] = newIndex
        updates[`v1/accounts/${accountKey}/stock_location_index/${shopKey}`] = { type: "shop", name: this.state.name }

        this.setState({ publishing: true })

        await firestore.collection(`accounts/${accountKey}/shops`).doc(shopKey).set(newIndex)
        await firestore.collection(`accounts/${accountKey}/stock_locations`).doc(shopKey).set({
            type: "shop",
            name: this.state.name
        })

        try {
            await ref().update(updates)
            this.handleUpdateResponse(null)
        } catch (error) {
            this.handleUpdateResponse(error)
        }
    }

    handleIdChange(identifier: string, valid: boolean) {
        if (identifier !== this.state.identifier) {
            this.setState({ dirty: true, identifierInvalid: !valid, identifier: identifier })
        }
    }

    stockLocationsRef() {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("stock_location_index")
    }

    render() {
        const publishDisabled = !this.state.dirty || this.state.identifierInvalid
        return (
            <PageState 
            loading={!this.state.loaded} 
            publishing={this.state.publishing} 
            dirty={this.state.dirty} 
            typeName="shop"
            submit_title={ this.isNewShop() ? "Create" : "Publish"}
            submit_action={async () => { await this.isNewShop() ? this.create() : this.publish() }}
            submit_disabled={publishDisabled}
            >
                <Card className="my-4">
                    <Card.Header>General</Card.Header>
                    <Card.Body>
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>Name</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="name"
                                        value={this.state.name ?? ""}
                                        placeholder="Enter shop name"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <ValidatingIdEntryControl
                                collectionRef={this.stockLocationsRef()}
                                isNew={this.state.isNewShop}
                                typeName="shop"
                                identifierSource={this.state.name}
                                existingIdentifier={this.state.identifier ?? ""}
                                handleIdChange={(id, valid) => { this.handleIdChange(id, valid) }}
                                invalidIdentifiers={["new"]}
                                showExistingIdentifier={true}
                            />
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>Market</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        as="select"
                                        name="market"
                                        placeholder="Select market"
                                        value={this.state.market}
                                        onChange={this.handleInputChange}
                                    >
                                        {
                                            Object.keys(this.state.available_markets).map((key, index) => {
                                                return <option key={index} value={key}>{this.state.available_markets[key].name}</option>
                                            })
                                        }
                                    </FormControl>
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>VAT number</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="cvr"
                                        value={this.state.cvr ?? ""}
                                        placeholder="Enter VAT number"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>
                </Card>
                <Card className="my-4">
                    <Card.Header>Address</Card.Header>
                    <Card.Body>
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>Street</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="street"
                                        value={this.state.street ?? ""}
                                        placeholder="Enter street name"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>Zip code</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="zip"
                                        value={this.state.zip ?? ""}
                                        placeholder="Enter zip code"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>City</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="city"
                                        value={this.state.city ?? ""}
                                        placeholder="Enter city"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <DescriptionCol sm={2}>Phone number</DescriptionCol>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="phone"
                                        value={this.state.phone ?? ""}
                                        placeholder="Enter phone number"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>
                </Card>

            </PageState>
        )
    }
}

export default withRoleRouter(ShopEdit)
