import * as React from "react"
import ContributionRatioConfiguration from "./Configuration/ContributionRatioConfiguration"
import ExchangeRateMarginConfiguration from "./Configuration/ExchangeRateMarginConfiguration"
import GiftcardTaxationConfiguration from "./Configuration/GiftcardTaxationConfiguration"
import ImageDisplayStyleConfiguration from "./Configuration/ImageDisplayStyleConfiguration"
import OpenRegisterModeConfiguration from "./Configuration/OpenRegisterModeConfiguration"
import PaymentTypesConfiguration from "./Configuration/PaymentTypesConfiguration"
import ReceiptBarcodeConfiguration from "./Configuration/ReceiptBarcodeConfiguration"
import MarkdownConfiguration from "./Configuration/MarkdownConfiguration"
import ShowCameraScanConfiguration from "./Configuration/ShowCameraScanConfiguration"
import { EcommerceConfiguration } from "./Configuration/EcommerceConfiguration"
import { Role } from "../../config/role"
import StockRemovalReasonCodeList from "./Configuration/StockRemovalReasonCodeList"
import { ref } from "../../config/constants"
import { withRole } from "src/routes"

interface ConfigurationProps {
    role: Role
}

interface ConfiguartionState {
    showStock: boolean
}

class Configuration extends React.Component<ConfigurationProps, ConfiguartionState> {
    constructor(props: ConfigurationProps) {
        super(props)
        this.state = {
            showStock: false
        }
    }
    stockModuleEnabledRef() {
        const account = this.props.role.account_id
        const stockModuleRef = ref().child(`v1/accounts/${account}/configuration/modules/stock/enabled`)
        return stockModuleRef
    }

    componentDidMount() {
        this.setState({ showStock: false })

        this.stockModuleEnabledRef()
            .on("value", snapshot => {
                if (snapshot && snapshot.exists() && snapshot.val() === true) {
                    this.setState({ showStock: true })
                }
            })
    }

    componentWillUnmount() {
        this.stockModuleEnabledRef().off()
    }

    render() {
        return (
            <div>
                <PaymentTypesConfiguration
                    key="payment_types"
                    role={this.props.role}
                />
                <ContributionRatioConfiguration
                    key="contribution_ratio"
                    role={this.props.role}
                />
                <ImageDisplayStyleConfiguration
                    key="image_display_style_configuration"
                    role={this.props.role}
                />
                <ReceiptBarcodeConfiguration
                    key="barcode_type"
                    role={this.props.role}
                />
                <ExchangeRateMarginConfiguration
                    key="exchange_rate_margin"
                    role={this.props.role}
                />
                <OpenRegisterModeConfiguration
                    key="open_register_mode_configuration"
                    role={this.props.role}
                />
                <GiftcardTaxationConfiguration
                    key="giftcard_taxation_configuration"
                    role={this.props.role}
                />
                <EcommerceConfiguration
                    key="ecommerce_configuration"
                    role={this.props.role}
                />
                {this.state.showStock ? (
                    <StockRemovalReasonCodeList
                        key="stock_removal_reason_code"
                        role={this.props.role}
                    />
                ) : null}
                <MarkdownConfiguration
                    key="markdown_configuration"
                    role={this.props.role}
                />
                <ShowCameraScanConfiguration
                    key="show_camera_scan_configuration"
                    role={this.props.role}
                />
            </div>
        )
    }
}

export default withRole(Configuration)