import _ from "lodash"
import * as React from "react"

export function paymentTypeNameFromId(id: any): string {
    let actualId
    if (typeof id === "object" && typeof id.id === "string") {
        actualId = id.id
    } else if (typeof id === "string") {
        actualId = id
    } else {
        return ""
    }

    if (actualId.includes("cash.rounding")) {
        return "Rounding"
    } else if (actualId.includes("cash.cashback")) {
        return "Cashback"
    } else if (actualId.includes("cash")) {
        return "Cash"
    } else if (actualId.includes("mobilepay")) {
        return "MobilePay"
    } else if (actualId.includes("giftcard")) {
        return "Giftcard"
    } else if (actualId.includes("invoice")) {
        return "Invoice"
    } else if (actualId.includes("voucher")) {
        return "Voucher"
    } else if (actualId.includes("customer_account")) {
        return "Customer account"
    } else if (actualId.includes("transfer")) {
        return "Transferred"
    } else if (actualId.includes("card")) {
        return "Card"
    } else if (actualId.includes("externally_paid")) {
        return "Externally billed"
    }
    return "Unknown"
}

function giftcardMetadata(payment: any) {
    let result: string = ""
    if (payment.metadata?.status === "used") {
        result = " used"
    } else {
        result = " created"
    }
    if (payment.metadata?.code) {
        return <span> {result + " - code: "} <a href={`/sales?giftcard_code=${payment.metadata?.code}`}>{payment.metadata?.code}</a> </span>
    }
    return null
}

function cardMetadata(payment: any) {
    const type = cardType(payment)
    if (!_.isNil(type)) {
        return ", " + type
    }
    return null
}

function cardType(payment: any): string | undefined {
    switch (payment.metadata?.card_type ?? "-") {
        case "visa":
            return "VISA"
        case "visadankort":
            return "VISA / Dankort"
        case "mastercard":
            return "Mastercard"
        case "dankort":
            return "Dankort"
        case "-":
            return undefined
        default:
            return payment.metadata?.card_type
    }
}

export function paymentTypeMetadata(payment: any) {
    switch (payment.payment_type) {
        case "giftcard.integration": {
            return giftcardMetadata(payment)
        }
        case "voucher.integration": {
            return giftcardMetadata(payment)
        }
        case "card.verifone": {
            return cardMetadata(payment)
        }
        case "card.payex": {
            return cardMetadata(payment)
        }
        case "card.izettle": {
            return cardMetadata(payment)
        }
        case "card.netsbaxi": {
            return cardMetadata(payment)
        }
        default:
            break
    }
    return null
}
