import * as React from "react"
import { RoleProps, withRole } from "src/routes"
import { ProductFoldersCard } from "./Folders/ProductFoldersPanel"
import { ProductTabsCard } from "./Tabs/ProductTabsPanel"

export class ProductOrganization extends React.Component<RoleProps> {
    
    constructor(props: RoleProps) {
        super(props)
        this.state = {}
    }

    // Component

    render() {
        return (
            <div>
                <ProductTabsCard role={this.props.role}/>
                <ProductFoldersCard role={this.props.role}/>
            </div>
        )
    }
}

export default withRole(ProductOrganization)