import * as React from "react"
import {
    Button,
    Col,
    
    Form,
    FormControl,
    FormGroup,
    Modal,
} from "../../wrappers"
import { ref } from "../../../config/constants"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import _ from "lodash"
import { Row } from "react-bootstrap"

type GiftcardModuleType = "giftcard" | "voucher"

interface GiftcardModuleProps extends ModuleProps {
    type: GiftcardModuleType
}

interface GiftcardModuleState {
    loaded: boolean
    configured: boolean
    accountId?: string
    token?: string
    isKaching: boolean
    isDev: boolean
    showPopup: boolean
}

export class GiftcardModule extends React.Component<GiftcardModuleProps, GiftcardModuleState> {

    constructor(props: GiftcardModuleProps) {
        super(props)
        this.state = {
            loaded: false,
            configured: true, // set as configured initially in order to not accidentally override
            isKaching: false,
            isDev: false,
            showPopup: false
        }
    }

    async componentDidMount() {
        await this.load()
    }

    async load() {
        const configRef = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/${this.props.type}_integration`)
        const configSnap = await configRef.once("value")
        const configured = configSnap.exists()
        const activateURL: string = configSnap.val()?.activate?.request.url ?? ""
        let isDev = false
        let isKaching = false
        let giftcardServiceAccount: string | undefined
        let giftcardServiceToken: string | undefined
        if (activateURL.startsWith("https://us-central1-ka-ching-giftcard-dev.")) {
            isDev = true
            isKaching = true
        } else if (activateURL.startsWith("https://us-central1-ka-ching-giftcard-prod.")) {
            isDev = false
            isKaching = true
        }
        if (isKaching) {
            giftcardServiceAccount = configSnap.val()?.activate?.request?.headers?.UID
            giftcardServiceToken = configSnap.val()?.activate?.request?.headers?.Token
        }
        this.setState({
            loaded: true,
            configured: configured,
            accountId: giftcardServiceAccount,
            token: giftcardServiceToken,
            isDev: isDev,
            isKaching: isKaching
        })
    }

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] || false
    }

    handleInputChange = (event: any) => {
        const target = event.target
        let value = target.value
        const name = target.name
        if (value === "" || value === null) {
            value = undefined
        }
        const state: any = { [name]: value }
        this.setState(state)
    }

    async deleteConfiguration() {
        const configRef = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/${this.props.type}_integration`)
        await configRef.remove()
        await this.load()
    }

    template(): any {
        return {
            "activate": {
                "request": {
                    "headers": {
                        "Token": "",
                        "UID": ""
                    },
                    "method": "POST",
                    "url": "[BASE_URL]/api/v1/giftcard/{giftcard_id}/activate"
                },
                "request_transformation": {
                    "type": "none"
                },
                "response_transformation": {
                    "parameters": {
                        "template": "{\"amount\":{{giftcard.amount}},\"code\":\"{{giftcard.id}}\",\"create_date\":{{iso8601DateStringToUnixTimestamp giftcard.created}},\"currency_code\":\"{{giftcard.currency}}\",\"expiry_date\":{{iso8601DateStringToUnixTimestamp giftcard.expiry}},\"id\":\"{{giftcard.id}}\",\"status\":\"{{giftcard.status}}\"}"
                    },
                    "type": "handlebars"
                }
            },
            "create": {
                "request": {
                    "headers": {
                        "Token": "",
                        "UID": ""
                    },
                    "method": "POST",
                    "parameters": {
                        "amount": "{amount}",
                        "email": "{email}",
                        "tax_name": "{tax_name}",
                        "tax_rate": "{tax_rate}",
                        "tax_type": "{tax_type}",
                        "type": "giftcard"
                    },
                    "url": "[BASE_URL]/api/v1/giftcard"
                },
                "request_transformation": {
                    "type": "none"
                },
                "response_transformation": {
                    "parameters": {
                        "template": "{\"amount\":{{giftcard.amount}},\"code\":\"{{giftcard.id}}\",\"create_date\":{{iso8601DateStringToUnixTimestamp giftcard.created}},\"currency_code\":\"{{giftcard.currency}}\",\"expiry_date\":{{iso8601DateStringToUnixTimestamp giftcard.expiry}},\"id\":\"{{giftcard.id}}\",\"status\":\"{{giftcard.status}}\" {{#with giftcard.tax_name}}, \"tax_name\": \"{{.}}\" {{/with}} {{#with giftcard.tax_rate}}, \"tax_rate\": {{.}} {{/with}} {{#with giftcard.tax_type}}, \"tax_type\": \"{{.}}\" {{/with}} }"
                    },
                    "type": "handlebars"
                }
            },
            "id": "ka-ching",
            "pay": {
                "request": {
                    "headers": {
                        "Token": "",
                        "UID": ""
                    },
                    "method": "POST",
                    "parameters": {
                        "amount": "{amount}"
                    },
                    "url": "[BASE_URL]/api/v1/giftcard/{giftcard_id}/consume"
                },
                "request_transformation": {
                    "type": "none"
                },
                "response_transformation": {
                    "parameters": {
                        "template": "{\"amount\":{{giftcard.amount}},\"code\":\"{{giftcard.id}}\",\"create_date\":{{iso8601DateStringToUnixTimestamp giftcard.created}},\"currency_code\":\"{{giftcard.currency}}\",\"expiry_date\":{{iso8601DateStringToUnixTimestamp giftcard.expiry}},\"id\":\"{{giftcard.id}}\",\"status\":\"{{giftcard.status}}\" {{#with giftcard.tax_name}}, \"tax_name\": \"{{.}}\" {{/with}} {{#with giftcard.tax_rate}}, \"tax_rate\": {{.}} {{/with}} {{#with giftcard.tax_type}}, \"tax_type\": \"{{.}}\" {{/with}} }"
                    },
                    "type": "handlebars"
                }
            },
            "payment_behavior": {
                "issue_new": {
                    "cashback_limit": 0
                }
            },
            "read": {
                "request": {
                    "headers": {
                        "Token": "",
                        "UID": ""
                    },
                    "method": "GET",
                    "url": "[BASE_URL]/api/v1/giftcard/{giftcard_code}"
                },
                "request_transformation": {
                    "type": "none"
                },
                "response_transformation": {
                    "parameters": {
                        "template": "{\"amount\":{{giftcard.amount}},\"code\":\"{{giftcard.id}}\",\"create_date\":{{iso8601DateStringToUnixTimestamp giftcard.created}},\"currency_code\":\"{{giftcard.currency}}\",\"expiry_date\":{{iso8601DateStringToUnixTimestamp giftcard.expiry}},\"id\":\"{{giftcard.id}}\",\"status\":\"{{giftcard.status}}\" {{#with giftcard.tax_name}}, \"tax_name\": \"{{.}}\" {{/with}} {{#with giftcard.tax_rate}}, \"tax_rate\": {{.}} {{/with}} {{#with giftcard.tax_type}}, \"tax_type\": \"{{.}}\" {{/with}} }"
                    },
                    "type": "handlebars"
                }
            }
        }
    }

    async submit(isDev: boolean = false) {
        if (_.isNil(this.state.accountId)) {
            return
        }
        if (_.isNil(this.state.token)) {
            return
        }
        const configRef = ref().child(`v1/accounts/${this.props.role.account_id}/configuration/${this.props.type}_integration`)
        const config = this.template()
        const baseUrl = isDev ? "https://us-central1-ka-ching-giftcard-dev.cloudfunctions.net" : "https://us-central1-ka-ching-giftcard-prod.cloudfunctions.net"
        for (const endpoint of ["activate", "read", "create", "pay"]) {
            const url: string = config[endpoint].request.url
            config[endpoint].request.url = url.replace("[BASE_URL]", baseUrl)
            config[endpoint].request.headers.Token = this.state.token
            config[endpoint].request.headers.UID = this.state.accountId
        }
        await configRef.set(config)
        await this.load()
        this.setState({ showPopup: false })
    }

    isProductionEnvironment(): boolean {
        return process.env.REACT_APP_FIREBASE_PROJECT_ID === "ka-ching-base"
    }

    // Component

    render() {
        if (!this.isEnabled()) { return null }
        if (!this.state.loaded) { return null }
        return (
            <div>
                {
                    this.state.configured ? (
                        <div>
                            {(this.state.isKaching && !this.state.isDev) ? <div>Configured with the Ka-ching Giftcard Service</div> : null}
                            {(this.state.isKaching && this.state.isDev) ? <div>Configured with the DEVELOPMENT Ka-ching Giftcard Service</div> : null}
                            {(this.state.isKaching) ? <div>Account: {this.state.accountId ?? ""}</div> : null}
                            {(this.state.isKaching) ? <div>Token: {this.state.token ?? ""}</div> : null}
                            {(!this.state.isKaching) ? <div>Configured with an external giftcard service</div> : null}
                            <ConfirmDeleteButton
                                message={`Really delete the current ${this.props.type} integration configuration?`}
                                onDelete={() => {
                                    this.deleteConfiguration()
                                }}
                            />
                        </div>
                    ) : (
                        <div>
                            <div>Not configured</div>
                            <Button onClick={() => this.setState({ showPopup: true })}>Configure</Button>
                        </div>
                    )
                }

                { this.state.showPopup ? (
                    <Modal show={true}>
                        <Modal.Header>
                            <Modal.Title>Configure integration</Modal.Title>
                        </Modal.Header>
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Account ID</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="accountId"
                                        value={this.state.accountId ?? ""}
                                        placeholder="Please enter the Giftcard Service Account ID"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Token</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="token"
                                        value={this.state.token ?? ""}
                                        placeholder="Please enter your Giftcard Service security token"
                                        onChange={this.handleInputChange}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>

                        <Modal.Footer>
                            <Button variant="danger" onClick={() => { this.submit() }}>Save</Button>
                            {this.isProductionEnvironment() ? null :
                                <Button variant="danger" onClick={() => { this.submit(true) }}>Save (DEV)</Button>
                            }
                            <Button onClick={() => { this.setState({ showPopup: false }) }}>Cancel</Button>
                        </Modal.Footer>
                    </Modal>
                ) : null
                }
            </div>
        )
    }
}
