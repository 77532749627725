import firebase from 'firebase/compat';
import _ from 'lodash';

type Direction = "initial" | "next" | "previous"

interface PaginationPermissions {
    nextPage: boolean
    previousPage: boolean
}
export class Pagination {
    
    private query: firebase.firestore.Query<firebase.firestore.DocumentData>
    private limitPlusOne: number

    private firstVisible: any
    private lastVisible: any

    private unsubscribe?: () => void
    
    public values?: (value: any[]) => void

    public valuesLoaded?: (isLoaded: boolean) => void

    private paginationPermissions: PaginationPermissions = { 
        nextPage: false, 
        previousPage: false
    }

    constructor(query: firebase.firestore.Query<firebase.firestore.DocumentData>, limit: number) {
        this.query = query
        this.limitPlusOne = limit + 1
    }

    public initial() {
        const query = this.query.limit(this.limitPlusOne)
        this.updateListener(query, "initial")
    }

    public next(){
        const query = this.nextQuery()
        this.updateListener(query, "next")
    }

    private nextQuery() {
        return this.query.startAfter(this.lastVisible).limit(this.limitPlusOne)
    }

    public previous() {
        const query = this.previousQuery()
        this.updateListener(query, "previous")
    }

    updateListener(query: firebase.firestore.Query, direction: Direction) {
        if (!_.isNil(this.unsubscribe)) {
            this.unsubscribe()
        }
        if (!_.isNil(this.valuesLoaded)) {
            this.valuesLoaded(false)
        }
        this.unsubscribe = query.onSnapshot((querySnapshot) => {
            const values = this.fetchValues(querySnapshot, direction);

            if (this.values) {
                this.values(values)

                if (!_.isNil(this.valuesLoaded)) {
                    this.valuesLoaded(true)
                }
            } 
        })
    }

    private previousQuery() {
        return this.query.endBefore(this.firstVisible).limitToLast(this.limitPlusOne)
    }

    public isLastPage(): boolean {
        return !this.paginationPermissions.nextPage
    }

    public isFirstPage(): boolean {
        return !this.paginationPermissions.previousPage
    }

    private fetchValues(querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>, direction: Direction) {
        const allDocs = querySnapshot.docs;

        // Side-effect
        this.setPaginationPermissions(allDocs, direction)

        const docsToDisplay = this.trimIfNecessary(allDocs, direction)

        // Side-effect
        this.setFirstAndLastVisibleItem(docsToDisplay)
        
        return this.getValuesToDisplay(docsToDisplay)
    }

    private getValuesToDisplay(docsToDisplay: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) {
        const dataArray: any[] = []
        for (const doc of docsToDisplay) {
            const data = doc.data();
            data.key = doc.id;
            dataArray.push(data);
        }
        return dataArray
    }

    /* 
        In order to know if we are on the first or last page when paginating,
        we always fetch one more item than the limit. 

        The logic is as follows: 
            If the extra item is present, we know that the next/previous page contains at 
            least one more item, and therefore pagination to that page should be allowed. 
            Otherwise, we should not allow further pagination.
    */
    private setPaginationPermissions(docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[], direction: Direction) {
        const docsToReturn = docs
        switch (direction) {
            case "initial":
                this.paginationPermissions = {
                    nextPage: this.shouldAllowPagination(docsToReturn),
                    previousPage: false
                }
                return
            case "next":
                this.paginationPermissions = {
                    nextPage: this.shouldAllowPagination(docsToReturn),
                    previousPage: true
                }
                return
            case "previous":
                this.paginationPermissions = {
                    nextPage: true,
                    previousPage: this.shouldAllowPagination(docsToReturn),
                }
                return
        }
    }

    private shouldAllowPagination(data: any[]): boolean {
        return (data.length === this.limitPlusOne)
    }

    private trimIfNecessary(docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[], direction: Direction) {
        const docsToReturn = docs
        switch (direction) {
            case "initial": case "next":
                if (docsToReturn.length === this.limitPlusOne) {
                    docsToReturn.pop()
                }
                return docsToReturn
            case "previous":
                if (docsToReturn.length === this.limitPlusOne) {
                    docsToReturn.shift()
                } 
                return docsToReturn
        }
    }

    private setFirstAndLastVisibleItem(docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]) {
        if (docs.length > 0) {
            this.firstVisible = docs[0];
            this.lastVisible = docs[docs.length - 1];
        }
    }
}