import _ from "lodash"
import * as React from "react"
import { Form, FormGroup, Col, FormControl, DescriptionCol } from "../../wrappers"
import { LanguageCode, L10nString } from "../../../helpers/L10n"
import { L10nFormControl } from "../../L10nFormControl"
import { MostSoldProductsSection } from "./FrontPageSectionModels"
import { Row } from "react-bootstrap"

interface MostSoldProductsSectionEditProps {
    section: MostSoldProductsSection
    currentLanguage: LanguageCode | null
    updateSection: (subSection: MostSoldProductsSection) => void
}

interface MostSoldProductsSectionEditState {
    section: MostSoldProductsSection
}

export class MostSoldProductsSectionEdit extends React.Component<MostSoldProductsSectionEditProps, MostSoldProductsSectionEditState> {
    constructor(props: MostSoldProductsSectionEditProps) {
        super(props)

        this.state = {
            section: props.section
        }
    }

    onLocalizationChanged = (name: string, l10n: L10nString | null) => {
        const section = _.cloneDeep(this.state.section)

        if (l10n === null || l10n.value === "") {
            delete section[name]
        } else {
            section[name] = l10n
        }
        this.props.updateSection(section)
        this.setState({ section: section })
    }

    updateNumberOfProducts(value: number) {
        const section = _.cloneDeep(this.state.section)
        section.numberOfProducts = value
        this.props.updateSection(section)
        this.setState({ section: section })
    }

    updateTimeIntervalDays(value: number) {
        const section = _.cloneDeep(this.state.section)
        section.timeIntervalDays = value
        this.props.updateSection(section)
        this.setState({ section: section })
    }

    render() {
        return (
            <Form onSubmit={e => e.preventDefault()}>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Name</DescriptionCol>
                    <Col sm={10}>
                        <L10nFormControl
                            l10n={this.state.section.title || null}
                            language={this.props.currentLanguage || null}
                            onLocalizationChanged={l10n => {
                                this.onLocalizationChanged("title", l10n)
                            }
                            }
                        />
                    </Col>
                </FormGroup>

                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Number of products to show</DescriptionCol>
                    <Col sm={10}>
                        <FormControl
                            type="number"
                            name="value"
                            value={Number(this.state.section.numberOfProducts).toFixed(0)}
                            placeholder="Enter value"
                            onChange={(e: any) => {
                                const newValue = Number(e.target.value)
                                if (_.isNil(newValue)) {
                                    return
                                }
                                this.updateNumberOfProducts(newValue)
                            }}
                            autoComplete="off"
                        />
                    </Col>
                </FormGroup>

                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Inverval in days</DescriptionCol>
                    <Col sm={10}>
                        <FormControl
                            type="number"
                            name="value"
                            value={Number(this.state.section.timeIntervalDays).toFixed(0)}
                            placeholder="Enter value"
                            onChange={(e: any) => {
                                const newValue = Number(e.target.value)
                                if (_.isNil(newValue)) {
                                    return
                                }
                                this.updateTimeIntervalDays(newValue)
                            }}
                            autoComplete="off"
                        />
                    </Col>
                </FormGroup>
            </Form>
        )
    }
}