import React, { useEffect, useRef, useState } from 'react';
import { Pagination as CompPagination } from 'react-bootstrap';
import { StripedTable } from '../StripedTable';
import { Pagination } from '../../helpers/Pagination';
import _ from 'lodash';
import { LoyaltyPointsEvent, Convert, LoyaltyPointsEventType } from "../../models/generated/LoyaltyPointsEvent"
import { firestore } from 'src/config/constants';
import { FormattedCurrency } from '../FormattedCurrency';
import { RoleProps, withRoleFunc } from 'src/routes';

function createPaginationObject(props: LoyaltyPointsLogProps): Pagination {
    const customerId = getCustomerId(props)
    const accountId = props.role.account_id

    const query = firestore
        .collection(`/accounts/${accountId}/loyalty_points_log`)
        .where("customer_id", "==", customerId)
        .where("currency_code", "==", "DKK")
        .orderBy("timestamp", "desc")

    return new Pagination(query, 50)
}

interface LoyaltyPointsLogProps extends RoleProps {
    id: string
}

function CustomerLoyaltyPointsLog(props: LoyaltyPointsLogProps) {
    const [loyaltyPointsEvent, setLoyaltyPointLog] = useState<LoyaltyPointsEvent[]>([])

    const pagination = useRef<Pagination>(createPaginationObject(props))
    const customerId = getCustomerId(props)
    const account = props.role.account_id

    useEffect(() => {
        pagination.current.values = (values) => {
            const logs = mapLoyaltyPointLogs(values)
            setLoyaltyPointLog(logs)
        }
        pagination.current.initial()
    }, [account, customerId])

    return (
        loyaltyPointsEvent.length > 0 ?
            <div>
                <h1>Loyalty Points Log </h1>
                <PreviousNextPager
                    isFirstPage={pagination.current.isFirstPage()}
                    isLastPage={pagination.current.isLastPage()}
                    onNext={async () => {
                        pagination.current.next()
                    }}
                    onPrevious={async () => {
                        pagination.current.previous()
                    }}
                />
                <StripedTable>
                    <thead>
                        <tr>
                            <th style={{ width: "280px", fontWeight: "bold" }}>Point Activity</th>
                            <th style={{ width: "200px", textAlign: "right", fontWeight: "bold" }}>Value</th>
                            <th style={{ fontWeight: "bold" }}>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loyaltyPointsEvent.map((loyaltyPointsEvent) => {
                            return (
                                <tr key={loyaltyPointsEvent.key}>
                                    <td>{mapTypeToPrettyName(loyaltyPointsEvent.type)} </td>
                                    {renderValueTd(loyaltyPointsEvent)}
                                    <td>{loyaltyPointsEvent.timestamp?.toLocaleString()} </td>
                                </tr>
                            )
                        })
                        }
                    </tbody>
                </StripedTable>
            </div>
            : <div>No loyalty point log entries found</div>
    );
}

function mapTypeToPrettyName(type: LoyaltyPointsEventType): string {
    switch (type) {
        case LoyaltyPointsEventType.Earned: return "Earned"
        case LoyaltyPointsEventType.Overwrite: return "Overwritten"
        case LoyaltyPointsEventType.ReturnEarned: return "Returned point-earning items"
        case LoyaltyPointsEventType.ReturnSpent: return "Reclaimed spent points on return"
        case LoyaltyPointsEventType.Spent: return "Spent"
        case LoyaltyPointsEventType.CoverNegativeBalance: return "Adjustment to cover negative balance"
    }
}

function renderValueTd(event: LoyaltyPointsEvent): any {
    if (!_.isNil(event.adjustment)) {
        if (event.adjustment > 0) {
            return (<td style={{ textAlign: "right" }}> +<FormattedCurrency value={event.adjustment} currency="" /> </td>)
        } else {
            return (<td style={{ textAlign: "right" }}><FormattedCurrency value={event.adjustment} currency="" /></td>)
        }
    } else if (!_.isNil(event.overwrite)) {
        return (<td style={{ textAlign: "right" }}> New value: <b><FormattedCurrency value={event.overwrite} currency="" /></b></td>)
    } else {
        return null
    }
}

function mapLoyaltyPointLogs(dataArray: any[]): LoyaltyPointsEvent[] {
    const logs: LoyaltyPointsEvent[] = []
    for (const data of dataArray) {
        const loyaltyPointsEvent = Convert.toLoyaltyPointsEvent(data)
        loyaltyPointsEvent.key = data.key
        logs.push(loyaltyPointsEvent)
    }
    return logs
}

type PreviousNextPagerProps = {
    isFirstPage: boolean
    isLastPage: boolean
    onPrevious: () => {}
    onNext: () => {}
}

function PreviousNextPager(props: PreviousNextPagerProps) {
    return <CompPagination>
        <CompPagination.Prev
            disabled={props.isFirstPage}
            onClick={props.onPrevious}
        >&larr; Previous</CompPagination.Prev>
        <CompPagination.Next
            disabled={props.isLastPage}
            onClick={props.onNext}>Next &rarr;</CompPagination.Next>
    </CompPagination>
}

function getCustomerId(props: LoyaltyPointsLogProps): string {
    return props.id
}

export default withRoleFunc(CustomerLoyaltyPointsLog)