import * as _ from "lodash"
import * as React from "react"
import {
    DropdownButton,
    MenuItem
} from "../../wrappers"
import { ModuleProps } from "../Configuration/ModuleConfiguration"
import { Globals } from "../../../helpers/globals"
import { Market } from "../../../models/MarketModels"

interface FiscalRulesModuleState {
    markets: Market[]
    countries: _.Dictionary<string>
}

export class FiscalRulesModule extends React.Component<ModuleProps, FiscalRulesModuleState> {

    constructor(props: ModuleProps) {
        super(props)
        this.state = {
            countries: {
                none: "None",
                se: "Sweden",
                no: "Norway",
                dk: "Denmark"
            },
            markets: []
        }
    }

    async componentDidMount() {
        const markets = await Globals.shared.getMarkets()
        this.setState({ markets: markets })
    }

    // Helpers

    countrySelected(country: string, marketId: string) {
        const config: any = _.cloneDeep(this.props.moduleData)
        const countryMap = config.countries ?? {}
        if (country !== "none") {
            countryMap[marketId] = country
        } else {
            delete countryMap[marketId]
        }
        this.props.updateConfiguration(existing => {
            delete existing.country
            if (Object.keys(countryMap).length === 0) {
                delete existing.countries
            } else {
                existing.countries = countryMap
            }
        })
    }

    isEnabled(): boolean {
        return this.props.moduleData["enabled"] ?? false
    }

    // Component

    render() {
        return (
            <div>
                {
                    this.isEnabled()
                        ?
                        (
                            <div>
                                {
                                    this.state.markets.map(market => {
                                        return <div key={market.id}>
                                            Fiscal rules for market: <b>{market.name}</b><br />
                                            <DropdownButton
                                                variant="primary"
                                                title={_.isNil(this.props.moduleData.countries?.[market.id]) ? "No fiscalization rules" : this.state.countries[this.props.moduleData.countries?.[market.id]]}
                                                id="dropdown-country"
                                                onSelect={(selectedCountry: any) => {
                                                    this.countrySelected(selectedCountry, market.id)
                                                }}
                                            >
                                                {
                                                    Object.keys(this.state.countries).sort().map((countryCode) => {
                                                        const countryName = this.state.countries[countryCode]
                                                        return <MenuItem key={countryCode} eventKey={countryCode}>{countryName}</MenuItem>
                                                    })
                                                }
                                            </DropdownButton>
                                            <br />
                                        </div>
                                    })


                                }
                            </div>
                        )
                        :
                        null
                }
            </div>
        )
    }
}
