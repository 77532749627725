import React from "react";
import _ from "lodash";
import { LineItemProps } from "./TextLineItem";
import { DeleteButton } from "src/components/DeleteButton";

export function ImageLineItem(props: LineItemProps) {
    return <div className="component-container">
        <div className="info-container">
            <div className="container">
                <span className="label">Type:</span>
                <span className="value">Image</span>
            </div>
            {!_.isNil(props.lineItem.image) ?
                <div className="container">
                    <span className="label">Value:</span>
                    <span className="value">{props.lineItem.image}</span>
                </div> : null}
            {!_.isNil(props.lineItem.imageWidth) ?
                <div className="container">
                    <span className="label">Image width:</span>
                    <span className="value">{props.lineItem.imageWidth}</span>
                </div> : null}
            {!_.isNil(props.lineItem.spacing) ?
                <div className="container">
                    <span className="label">Spacing:</span>
                    <span className="value">{props.lineItem.spacing}</span>
                </div> : null}
        </div>
        <div className="button-container">
            <DeleteButton onDelete={props.onDelete}></DeleteButton>
        </div>
    </div>;
}
