import * as _ from "lodash"
import * as React from "react"

import { Button, Col, DescriptionCol, DropdownButton, FormControl, FormGroup, MenuItem, Modal, Card, Row } from "../../wrappers"
import { ref } from "../../../config/constants"
import { PageState } from "../../PageState"
import { Role } from "../../../config/role"
import { StripedTable } from "../../StripedTable"
import { allShortcuts, ShortcutItem, shortcutName } from "../ConfigurableFrontPage/FrontPageSectionModels"
import { L10nString, LanguageCode } from "../../../helpers/L10n"
import { L10nFormControl } from "../../L10nFormControl"
import { LanguagePicker } from "../../LanguagePicker"
import { RoleRouterProps, withRoleRouter } from "src/routes"
import { Description } from "../DiscountRules/Customizable/CustomizableFormDescription"
import { ModalBody } from "react-bootstrap"
import { Dropdown } from "react-bootstrap"

export interface CashierRole {
    id: string
    name: L10nString
    functionalityBlocklist: ShortcutItem[]
}

interface CashierRoleEditProps {
    cashierRole: CashierRole
    save: (role: CashierRole) => void
    close: () => void
    currentLanguage?: LanguageCode
}

interface CashierRoleEditState {
    name: L10nString
    id: string
    blocklist: ShortcutItem[]
    itemMap: any
    currentLanguage: LanguageCode | null
}

class CashierRoleEdit extends React.Component<CashierRoleEditProps, CashierRoleEditState> {
    constructor(props: CashierRoleEditProps) {
        super(props)

        this.state = {
            name: props.cashierRole.name,
            id: props.cashierRole.id,
            blocklist: _.cloneDeep(props.cashierRole.functionalityBlocklist),
            itemMap: {},
            currentLanguage: props.currentLanguage ?? null
        }
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const name = _.cloneDeep(this.state.name)
        name.removeLocalization(language)
        this.setState({ name: name, currentLanguage: null })
    }
    async componentDidMount() {
        const itemMap: any = {}
        for (const item of allShortcuts) {
            itemMap[item] = this.shortcutName(item)
        }
        this.setState({ itemMap: itemMap })
    }

    handleInputChange = (l10n: L10nString | null) => {
        this.setState({ name: l10n ?? new L10nString("") })
    }

    isNew(): boolean {
        return this.props.cashierRole.id === ""
    }

    shortcutName(item: ShortcutItem) {
        if (item === "basket_comment") {
            return "Add comments"
        } else if (item === "basket_discount") {
            return "Add discounts"
        } else {
            return shortcutName(item)
        }
    }

    available(): any {
        const availableMap: any = {}
        for (const item in this.state.itemMap) {
            if (!this.state.blocklist.includes(item as ShortcutItem)) {
                availableMap[item] = this.shortcutName(item as ShortcutItem)
            }
        }
        return availableMap
    }

    handleChange = (value: ShortcutItem, index: number) => {
        const newAvailable = this.state.blocklist
        newAvailable[index] = value
        this.setState({ blocklist: newAvailable })
    }

    removeBlockedItem = (index: number) => {
        const blocklist = this.state.blocklist
        blocklist.splice(index, 1)

        this.setState({ blocklist: blocklist })
    }

    addBlockedItem = () => {
        const available = this.available()
        if (Object.keys(available).length === 0) {
            return
        }
        const blocklist = this.state.blocklist
        blocklist.push(Object.keys(available)[0] as ShortcutItem)

        this.setState({ blocklist: blocklist })
    }

    render() {
        return (
            <Modal show={true} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        <span>Edit role</span>
                        <span style={{ float: "right" }}>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormGroup className="mb-3" as={Row}>
                        <DescriptionCol smOffset={8} sm={4} >
                            <LanguagePicker
                                typeName="cashier role"
                                initialLanguage={this.state.currentLanguage}
                                resolveLanguages={this.resolveLanguages}
                                onChange={this.setLanguage}
                                onRemove={this.removeLanguage}
                            />
                        </DescriptionCol>
                    </FormGroup>
                    <FormGroup className="mb-3" as={Row}>
                        <DescriptionCol sm={2}>Id</DescriptionCol>
                        <Col sm={10}>
                            <FormControl
                                value={this.state.id}
                                placeholder="Enter id"
                                onChange={value => { this.setState({ id: (value.target as any).value }) }}
                                disabled={!this.isNew()}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-3" as={Row}>
                        <DescriptionCol sm={2}>Name</DescriptionCol>
                        <Col sm={10}>
                            <L10nFormControl
                                l10n={this.state.name}
                                placeholder="Enter localized name"
                                language={this.state.currentLanguage}
                                onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-3" as={Row}> 
                        <DescriptionCol sm={2}>Blocked commands</DescriptionCol>
                        <Col sm={10}>

                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.blocklist.map((item, itemIndex) => {
                                            return (
                                                <tr key={itemIndex} >
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle id={`dropdown-basic-${item}`}>{this.state.itemMap[item] ?? "-"}</Dropdown.Toggle>
                                                            <Dropdown.Menu style={{overflowY: "scroll", maxHeight: "600px"}}>
                                                            {
                                                                Object.keys(this.available()).sort((a, b) => { return this.state.itemMap[a].localeCompare(this.state.itemMap[b]) }).map((key, allItemsIndex) => {
                                                                    return (
                                                                        <MenuItem
                                                                            key={allItemsIndex}
                                                                            onClick={(event) => { this.handleChange(key as any, itemIndex); event.stopPropagation() }}
                                                                        >
                                                                         {this.state.itemMap[key]}
                                                                        </MenuItem>
                                                                    )
                                                                })
                                                            }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </td>
                                                    <td className="narrow"> 
                                                        <Button variant="danger" onClick={(event) => { this.removeBlockedItem(itemIndex); event.stopPropagation() }}>X</Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </StripedTable>
                            <div style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.addBlockedItem() }}>Add command to block</Button>
                            </div>
                        </Col>
                    </FormGroup>

                    {/* </FormGroup> */}

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { this.props.close() }} disabled={false}>Cancel</Button>
                    <Button onClick={() => { this.props.save({ id: this.state.id, name: this.state.name, functionalityBlocklist: this.state.blocklist }) }} disabled={false}>Done</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

interface CashierRolesState {
    roles: CashierRole[]
    dirty: boolean
    loaded: boolean
    publishing: boolean
    editedRoleId?: string
    currentLanguage: LanguageCode | null
}

class CashierRoles extends React.Component<RoleRouterProps, CashierRolesState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            roles: [],
            dirty: false,
            loaded: false,
            publishing: false,
            currentLanguage: null
        }
    }

    closeModal() {
        this.setState({ editedRoleId: undefined })
    }

    saveRole(role: CashierRole) {
        const roles = _.cloneDeep(this.state.roles)
        const index = roles.findIndex(entry => { return entry.id === role.id })
        if (index < 0) {
            roles.push(role)
        } else {
            roles[index] = role
        }
        this.setState({ editedRoleId: undefined, roles: roles, dirty: true })
    }

    async componentDidMount() {
        const cashierRolesRef = this.cashierRolesRef()
        const rolesSnap = await cashierRolesRef.once("value")
        let roles: CashierRole[] = []
        if (rolesSnap && rolesSnap.exists()) {
            const rolesDict = rolesSnap.val()
            for (const key in rolesDict) {
                const roleJson = rolesDict[key]
                if (roleJson.id && roleJson.name) {
                    roles.push({
                        id: roleJson.id,
                        name: new L10nString(roleJson.name),
                        functionalityBlocklist: roleJson.functionality_blocklist ?? []
                    })
                }
            }
        }
        if (roles.find(role => {
            return role.id === "_"
        }) === undefined) {
            const newRoles: CashierRole[] = [{
                id: "_",
                name: new L10nString("Default"),
                functionalityBlocklist: []
            }]
            roles = newRoles.concat(roles)
        }
        this.setState({ roles: roles, loaded: true })
    }

    editedRole(roleId: string): CashierRole {
        const role = this.state.roles.find(roleEntry => { return roleEntry.id === roleId })
        return role ?? { id: roleId, name: new L10nString(""), functionalityBlocklist: [] }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} dirty={this.state.dirty} publishing={this.state.publishing} typeName="cashier roles" submit_action={async () => { this.publish() }}>
                <Card className="my-4" key="upper_panel">
                    <Card.Header>
                        Cashier roles
                        <Button className="float-sm-end" variant="primary" onClick={(event) => { this.addCashierRole() }}>Add new cashier role</Button>
                    </Card.Header>

                    <Card.Body>
                        <StripedTable>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.roles.map((role) => {
                                        return (
                                            <tr key={role.id} onClick={() => { this.setState({ editedRoleId: role.id }) }}>
                                                <td>{role.id === "_" ? "(default role if no other is selected)" : role.id}</td>
                                                <td>{role.name.localized(LanguageCode.da)}</td>
                                                <td className="narrow">
                                                    {role.id !== "_" &&
                                                        <Button variant="danger" onClick={(event) => { this.removeCashierRole(role.id); event.stopPropagation() }}>X</Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </StripedTable>
                    </Card.Body>
                </Card >
                {!_.isNil(this.state.editedRoleId) && <CashierRoleEdit cashierRole={this.editedRole(this.state.editedRoleId)} save={role => { this.saveRole(role) }} close={() => { this.closeModal() }} />}
            </PageState>
        )
    }

    removeCashierRole = (id: string) => {
        const roles = _.cloneDeep(this.state.roles)
        _.remove(roles, role => { return role.id === id })

        this.setState({ roles: roles, dirty: true })
    }

    addCashierRole = () => {
        this.setState({ editedRoleId: "" })
    }

    cashierRolesRef() {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/inventory/cashier_roles`)
    }

    publish = async () => {
        const cashierRolesRef = this.cashierRolesRef()

        this.setState({ publishing: true })
        const json: any = {}
        for (const role of this.state.roles) {
            json[role.id] = { id: role.id, name: role.name.json(), functionality_blocklist: role.functionalityBlocklist }
        }
        await cashierRolesRef
            .set(json)
            .then(() => {
                this.setState({ publishing: false, dirty: false })
            })
    }
}

export default withRoleRouter(CashierRoles)