import * as _ from "lodash"
import dayjs from "dayjs"
import Numeral from "numeral"
import { L10nString, LanguageCode } from "../helpers/L10n"
import { AttributeValue, Product } from "../models/Product"
import {
    ProductCatalogService,
    ProductPrices
} from "./ProductCatalogService"
import { productName } from "../helpers/productName"
import { firestore, ref } from "../config/constants"
import { sortLikeFirebaseOrderByKey } from "../helpers/sorting"
import firebase from "firebase/compat/app"
/* tslint:disable */
import "firebase/firestore"
import { Globals } from "../helpers/globals"
import * as dayjstimezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { DataSnapshot } from "firebase/database"
import { StockReportFilter } from "../components/protected/StockCountReports/StockCountReportModal"
import { Comparison } from "../components/protected/DiscountRules/AppliesToSelector"
dayjs.extend(utc as any)
dayjs.extend(dayjstimezone as any)
/* tslint:enable */

// picked pretty arbitrarily but it should hold that it's not too much memory and not too slow for stock counts in the area of 10.000 lines
const fetchLimit = 500

class StockLocation {
    id: string
    name: string

    constructor(id: string, name: string) {
        this.id = id
        this.name = name
    }

    json() {
        return {
            id: this.id,
            name: this.name
        }
    }
}

interface AttributeFilter {
    value: string
    comparison: Comparison
}

export class StockReportLine {
    private barcode?: string
    private count?: number
    private name?: string
    private productId: string
    private variantId?: string
    private costPrice?: number
    private retailPrice?: number
    private productGroup?: string
    private deleted: boolean

    constructor(
        barcode: string | undefined,
        count: number | undefined,
        name: string | undefined,
        productId: string,
        variantId: string | undefined,
        prices: ProductPrices,
        productGroup: string | undefined,
        deleted: boolean
    ) {
        this.barcode = barcode
        this.count = count
        this.name = name
        this.productId = productId
        this.variantId = variantId
        this.costPrice = prices.costPrice
        this.retailPrice = prices.retailPrice
        this.productGroup = productGroup
        this.deleted = deleted
    }

    csvLine(fieldDelimiter: string): string {
        const formattedValues = this.formattedValues()
        const result = formattedValues.join(fieldDelimiter)
        return result
    }

    // BG: Trying to circumvent a weird error when deploying - from https://stackoverflow.com/questions/63822198/cryptic-error-when-deploying-typescript-react-app-to-heroku
    // private formattedValues(): [string, string, string, string, string, string, string, string] {
    private formattedValues() {
        const decimalSeparator = ","
        const variantId = `"${this.variantId ?? ""}"`
        const productId = `"${this.productId}"`
        const id = `"${this.variantId ?? this.productId}"`
        const name = `"${this.name ?? ""}"`
        const barcode = `"${this.barcode ?? ""}"`
        const count = `${this.count ?? ""}`
        const costPrice = `${!_.isNil(this.costPrice) ? this.formatPriceValue(this.costPrice, decimalSeparator) : ""}`
        const costPriceCount = `${!_.isNil(this.costPrice) && !_.isNil(this.count) && this.count >= 0 ? this.formatPriceValue(this.costPrice * this.count, decimalSeparator) : ""}`
        const retailPrice = `${!_.isNil(this.retailPrice) ? this.formatPriceValue(this.retailPrice, decimalSeparator) : ""}`
        const retailPriceCount = `${!_.isNil(this.retailPrice) && !_.isNil(this.count) && this.count >= 0 ? this.formatPriceValue(this.retailPrice * this.count, decimalSeparator) : ""}`
        const productGroup = _.isNil(this.productGroup) ? `""` : `"${this.productGroup}"`
        return [productId, variantId, id, this.deleted ? `"Deleted"` : `""`, name, barcode, count, costPrice, costPriceCount, retailPrice, retailPriceCount, productGroup]
    }

    private formatPriceValue(price: number, decimalSeparator: string): string {
        const value = Numeral(price).format("0.00")
        if (decimalSeparator !== ".") {
            return value.replace(".", decimalSeparator)
        } else {
            return value
        }
    }
}

class MarketsInfo {
    markets: Set<string>
    shopToMarketMap: Map<string, string>

    constructor(markets: Set<string>, shopToMarketMap: Map<string, string>) {
        this.markets = markets
        this.shopToMarketMap = shopToMarketMap
    }
}

export class StockNotCountedLine {
    private barcode?: string
    private name?: string
    private productId: string
    private variantId?: string
    private count?: number

    constructor(
        barcode: string | undefined,
        name: string | undefined,
        productId: string,
        variantId: string | undefined,
        count: number | undefined
    ) {
        this.barcode = barcode
        this.name = name
        this.productId = productId
        this.variantId = variantId
        this.count = count
    }

    csvLine(fieldDelimiter: string): string {
        const formattedValues = this.formattedValues()
        const result = formattedValues.join(fieldDelimiter)
        return result
    }

    private formattedValues() {
        const variantId = `"${this.variantId ?? ""}"`
        const productId = `"${this.productId}"`
        const barcode = `"${this.barcode ?? ""}"`
        const count = `${this.count ?? ""}`
        const name = `"${this.name ?? ""}"`
        return [productId, variantId, barcode, count, name]
    }
}

export class StockLastCounted {
    private barcode?: string
    private name?: string
    private productId: string
    private variantId?: string
    private lastCountedDate?: Date
    private currentStockValue?: number
    private stockCountId?: string

    constructor(
        barcode: string | undefined,
        name: string | undefined,
        productId: string,
        variantId: string | undefined,
        lastCountedDate: Date | undefined,
        currentStockValue: number | undefined,
        stockCountId: string | undefined
    ) {
        this.barcode = barcode
        this.name = name
        this.productId = productId
        this.variantId = variantId
        this.lastCountedDate = lastCountedDate
        this.currentStockValue = currentStockValue
        this.stockCountId = stockCountId
    }

    csvLine(fieldDelimiter: string): string {
        const formattedValues = this.formattedValues()
        const result = formattedValues.join(fieldDelimiter)
        return result
    }

    private formattedValues() {
        const variantId = `"${this.variantId ?? ""}"`
        const productId = `"${this.productId}"`
        const barcode = `"${this.barcode ?? ""}"`
        const lastCountedDate = `"${this.lastCountedDate?.toISOString() ?? "NOT COUNTED"}"`
        let timezone = ""
        if (!_.isNil(this.lastCountedDate)) {
            timezone = dayjs.tz.guess()
        }
        const count = `${this.currentStockValue ?? ""}`
        const name = `"${this.name ?? ""}"`
        const stockCountId = `"${this.stockCountId ?? ""}"`
        return [productId, variantId, barcode, lastCountedDate, timezone, count, stockCountId, name]
    }
}

export class StockCountPercentage {
    private shopId: string
    private shopName?: string
    private totalProductCount: number
    private countedProducts: number
    private countedPercentage: number

    constructor(
        shopId: string,
        shopName: string | undefined,
        totalProductCount: number,
        countedProducts: number,
        countedPercentage: number
    ) {
        this.shopId = shopId
        this.shopName = shopName
        this.totalProductCount = totalProductCount
        this.countedProducts = countedProducts
        this.countedPercentage = countedPercentage
    }

    csvLine(fieldDelimiter: string): string {
        const formattedValues = this.formattedValues()
        const result = formattedValues.join(fieldDelimiter)
        return result
    }

    private formattedValues() {
        const shopName = `${this.shopName ?? ""}`
        const totalProductCount = `${this.totalProductCount}`
        const countedProducts = `${this.countedProducts}`
        let countedPercentage = "0%"
        if (this.countedPercentage > 0) {
            countedPercentage = `${this.countedPercentage.toFixed(1).replace(".", ",")}%`
        }
        return [this.shopId, shopName, totalProductCount, countedProducts, countedPercentage]
    }
}


function csvString(input: string | undefined) {
    return `"${input ?? ""}"`
}

function matchesFilter(attributes: _.Dictionary<AttributeValue>, filter: _.Dictionary<AttributeFilter[]>) {
    for (const attributeId in filter) {
        const options = filter[attributeId]
        const productValue = attributes[attributeId]
        if (_.isNil(productValue)) {
            return false
        }

        let optionsMatch = false
        for (const option of options) {
            switch (option.comparison) {
                case "==": {
                    optionsMatch = productValue.stringValue() === option.value
                    break
                }
                case "<": {
                    optionsMatch = productValue.stringValue() < option.value
                    break
                }
                case ">": {
                    optionsMatch = productValue.stringValue() > option.value
                    break
                }
                case "<=": {
                    optionsMatch = productValue.stringValue() <= option.value
                    break
                }
                case ">=": {
                    optionsMatch = productValue.stringValue() >= option.value
                    break
                }
            }
            if (optionsMatch) {
                break
            }
        }
        if (!optionsMatch) {
            return false
        }
    }
    return true
}

export class StockReportBuilder {

    // Props

    private accountId: string
    private shopId: string
    private productCatalogService: ProductCatalogService
    private productsLoaded: number = 0

    // Cosntructor

    constructor(accountId: string, shopId: string, productCatalogService: ProductCatalogService) {
        this.accountId = accountId
        this.shopId = shopId
        this.productCatalogService = productCatalogService
    }

    // Public methos

    // BG: Trying to circumvent a weird error when deploying - from https://stackoverflow.com/questions/63822198/cryptic-error-when-deploying-typescript-react-app-to-heroku
    // async buildStockReport(progress: (loaded: number, aggregated: number) => void): Promise<[string, string]> {
    async buildStockReport(progress: (loaded: number, aggregated: number) => void): Promise<string[]> {
        await this.productCatalogService.allProductsInCatalogue(this.accountId, this.shopId, (loaded) => {
            this.productsLoaded = loaded
            progress(loaded, 0)
        })
        const fieldDelimiter = ";"
        const reportModels = await this.buildReportModels((created: number) => { progress(this.productsLoaded, created) })
        const reportLines = reportModels.map((model) => {
            return model.csvLine(fieldDelimiter)
        })
        const fileName = await this.buildDocumentName()
        const headerLine = ["Product id", "Variant id", "Id / Article number", "Deleted", "Name", "Barcode", "Stock", "Cost price per item", "Cost price total (count)", "Retail price per item", "Retail price total (count)", "Product group"].join(fieldDelimiter)
        let lines: string[] = [headerLine]
        lines = lines.concat(reportLines)
        return [lines.join("\n"), fileName]
    }

    async buildLastCountedStockReport(filter: StockReportFilter, displayOnlyUncounted: boolean, progress: (loaded: number, aggregated: number) => void): Promise<string[]> {
        await this.productCatalogService.allProductsInCatalogue(this.accountId, this.shopId, (loaded) => {
            this.productsLoaded = loaded
            progress(loaded, 0)
        })
        const fieldDelimiter = ";"
        const reportModels = await this.buildCountedReportModels(filter, displayOnlyUncounted, (created: number) => { progress(this.productsLoaded, created) })
        const reportLines = reportModels.map((model) => {
            return model.csvLine(fieldDelimiter)
        })
        const fileName = await this.buildLastCountedDocumentName(displayOnlyUncounted)
        let headerLine: string
        if (displayOnlyUncounted) {
            headerLine = ["Product id", "Variant id", "Barcode", "Current count", "Name"].join(fieldDelimiter)
        } else {
            headerLine = ["Product id", "Variant id", "Barcode", "Last counted date (UTC)", "Timezone", "Current count", "Stock count id", "Name"].join(fieldDelimiter)
        }

        let lines: string[] = [headerLine]
        lines = lines.concat(reportLines)
        return [lines.join("\n"), fileName]
    }

    async buildCountedPercentageStockReport(filter: StockReportFilter, progress: (loadedProducts?: number, countedStock?: number) => void): Promise<string[]> {
        const fieldDelimiter = ";"
        const reportModels = await this.buildLineStockCountePercentageLineItems(filter, progress)
        const reportLines = reportModels.map((model) => {
            return model.csvLine(fieldDelimiter)
        })

        const fileName = await this.buildCountPercentageDocumentName(filter.filterDate)

        let headerLine = ["Shop id", "Shop name", "Total active products/variants", "Counted stock", "Counted percentage"].join(fieldDelimiter)

        let lines: string[] = [headerLine]
        lines = lines.concat(reportLines)
        return [lines.join("\n"), fileName]
    }

    async buildLineStockCountePercentageLineItems(filter: StockReportFilter, progress: (productsLoaded?: number, stockCounted?: number) => void): Promise<StockCountPercentage[]> {
        const locations = await this.getStockLocations()
        const marketsInfo = await this.getMarketsInfo(locations.map((location) => location.id))
        const marketToProductMap = await this.getMarketToProductsMap(Array.from(marketsInfo.markets), (loadedProducts) => { progress(loadedProducts, undefined) })
        let totalStockCounted = 0
        const countedPercentagePromises = locations.map((location) => {
            const marketForLocation = marketsInfo.shopToMarketMap.get(location.id) ?? ""
            const activeProductsForMarket: _.Dictionary<Product> = marketToProductMap.get(marketForLocation) ?? {}
            return this.buildCountedPercentage(filter, location, activeProductsForMarket, (stockCounted) => {
                totalStockCounted += stockCounted
                progress(undefined, totalStockCounted)
            })
        })

        const lines = await Promise.all(countedPercentagePromises)

        return lines
    }

    async getMarketsInfo(shopIds: string[]): Promise<MarketsInfo> {
        const tuples = await Promise.all(shopIds.map(async (id) => {
            const marketPath = `v1/accounts/${this.accountId}/stock_locations/${id}/market`
            let value = await ref().child(marketPath).get()
            if (!value.exists()) {
                const shopMarketPath = `v1/accounts/${this.accountId}/shops/${id}/market`
                value = await ref().child(shopMarketPath).get()
            }
            const tuple: [firebase.database.DataSnapshot, string] = [value, id]
            return tuple
        }))

        const allMarkets: Set<string> = new Set()
        const shopToMarket: Map<string, string> = new Map()
        for (const [marketSnapshot, shopId] of tuples) {
            if (!marketSnapshot.exists() || typeof marketSnapshot.val() !== "string") {
                continue
            }
            const marketId: string = marketSnapshot.val()
            allMarkets.add(marketId)
            shopToMarket.set(shopId, marketId)
        }
        return new MarketsInfo(allMarkets, shopToMarket)
    }

    async getMarketToProductsMap(markets: string[], progress: (productsLoaded: number) => void) {
        let marketToProducsMap: Map<string, _.Dictionary<Product>> = new Map()
        let productsProgress: _.Dictionary<Product> = {}
        const productSnaps = markets.map(async (id) => {
            await this.productCatalogService.allProductsForMarket(this.accountId, id, (productsLoaded) => {
                let marketToProducts = marketToProducsMap.get(id) ?? {}
                marketToProducts = _.merge(marketToProducts, productsLoaded)
                marketToProducsMap.set(id, marketToProducts)

                productsProgress = _.merge(productsProgress, productsLoaded)
                progress(Object.values(productsProgress).length - 1)
            })
        })

        await Promise.all(productSnaps)
        return marketToProducsMap
    }

    private async getStockLocations() {
        const path = `v1/accounts/${this.accountId}/stock_location_index`
        const stockLocationsSnapshot = await ref().child(path).get()
        const stockLocations: StockLocation[] = []
        const fallbackIds: string[] = []
        stockLocationsSnapshot.forEach((child) => {
            if (child.exists()) {
                let value = child.val()
                if (value.deactivated) { return }
                let id = child.key
                let name = value.name
                if (!_.isNil(name)) {
                    let shop = new StockLocation(id, name)
                    stockLocations.push(shop)
                } else {
                    fallbackIds.push(id)
                }
            }
        })
        for (const id of fallbackIds) {
            const shop = await this.fallbackToShopLocation(id)
            if (!_.isNil(shop)) {
                stockLocations.push(shop)
            }
        }
        return stockLocations
    }

    private async fallbackToShopLocation(id: string): Promise<StockLocation | undefined> {
        const path = `v1/accounts/${this.accountId}/shop_index/${id}`
        const stockLocationsSnapshot = await ref().child(path).get()

        if (stockLocationsSnapshot.exists()) {
            let value = stockLocationsSnapshot.val()
            if (value.deactivated) { return }
            let name = value.name
            let shop = new StockLocation(id, name)
            return shop
        }
        return undefined
    }

    private activeProductsInStock(products: _.Dictionary<Product>, stock: _.Dictionary<any>, filter: StockReportFilter): Set<string> {
        const sellables: Set<string> = new Set()
        const attributes = filter.filterAttributes ?? []
        // The logic that we want is that if the same attribute is present with more options
        // then we want to include products that have either of the options. So we transform the
        // list of attributes to a dictionary from attribute id to array of options:
        const attributeDict: _.Dictionary<AttributeFilter[]> = {}
        for (const attribute of attributes) {
            const existing = attributeDict[attribute.attributeId] ?? []
            existing.push({ value: attribute.optionId, comparison: attribute.comparison ?? "==" })
            attributeDict[attribute.attributeId] = existing
        }

        for (const productId in stock) {
            const stockValue = stock[productId]
            const product = products[productId]
            if (_.isNil(product)) {
                // Not an active product, continue
                continue
            }
            if (product.archived === true) {
                // Not an active product, continue
                continue
            }
            if (_.isObject(stockValue)) {
                for (const variantId in stockValue) {
                    const stockAmount = stockValue[variantId]
                    if (filter.excludeZeroStockProducts && stockAmount <= 0) {
                        continue
                    }
                    for (const variant of product.variants ?? []) {
                        if (variant.id === variantId) {
                            const mergedAttributes = _.merge(product.attributes ?? {}, variant.attributes)
                            if (!matchesFilter(mergedAttributes, attributeDict)) {
                                break
                            }

                            sellables.add(`${productId}.${variantId}`)
                            break
                        }
                    }
                }
            } else {
                // A plain product
                if (filter.excludeZeroStockProducts && stockValue <= 0) {
                    continue
                }
                if (!matchesFilter(product.attributes ?? {}, attributeDict)) {
                    continue
                }

                sellables.add(productId)
            }
        }
        return sellables
    }

    private countedActiveProductsInStock(shopId: string, countedStock: _.Dictionary<any>, activeProductsInStock: Set<string>): number {
        let count = 0
        for (const combined in countedStock) {
            if (activeProductsInStock.has(combined)) {
                count += 1
            } else {
                console.log(`Previously counted product no longer active or in stock: SHOP: ${shopId} ${combined}`, countedStock[combined])
            }
        }
        return count
    }

    private async buildCountedPercentage(filter: StockReportFilter, stockLocation: StockLocation, activeProducts: _.Dictionary<Product>, progress: (stockCounted: number) => void): Promise<StockCountPercentage> {
        let shopId = stockLocation.id
        let countedProducts = await this.getAllCountedProducts(filter.filterDate, shopId, (stockCounted) => {
            progress(stockCounted)
        })
        const shopStock = await this.getStock(shopId)

        // The number of active products / variants that are currently in stock
        const activeProductsInStock = this.activeProductsInStock(activeProducts, shopStock, filter)
        const activeProductCount = activeProductsInStock.size

        // The number of counted stock, that is also still in the active list of products and currently in stock.
        const countedProductCount = this.countedActiveProductsInStock(shopId, countedProducts, activeProductsInStock)

        const countedPercentage = (countedProductCount / activeProductCount) * 100
        const shopName = stockLocation.name
        const line = new StockCountPercentage(shopId, shopName, activeProductCount, countedProductCount, countedPercentage)
        return line
    }

    async buildStockMoveReport(type: "all" | "removal" | "received" | "sale" | "return", progress: (loaded: number, aggregated: number) => void): Promise<string[]> {
        const fileName = await this.buildDocumentName()

        const market = await Globals.getMarket(this.shopId)

        const snapshot = await this.getStockEvents(type)
        const fieldDelimiter = ";"
        const header = [
            csvString("Product Id"),
            csvString("Variant Id"),
            csvString("Name"),
            csvString("Barcode"),
            csvString("Adjustment"),
            csvString("New stock value"),
            csvString("Type")
        ].join(fieldDelimiter)

        const productCache: any = {}

        let lines: string[] = [header]
        for (const doc of snapshot.docs) {
            const data = doc.data()
            let { name, barcode } = await this.getProductInfo(productCache, data, market)

            lines.push([
                csvString(data.product_id),
                csvString(data.variant_id),
                csvString(name),
                csvString(barcode),
                data.adjustment,
                data.new_stock_value,
                csvString(data.type)
            ].join(fieldDelimiter))
        }
        return [lines.join("\n"), fileName]
    }

    private async getStockEvents(type: string) {
        const now = dayjs()
        const beginningOfDay = now.startOf("day")

        const stockEvents = firestore.collection(`accounts/${this.accountId}/stock_locations/${this.shopId}/stock_events`)
        const timestamp = new firebase.firestore.Timestamp(beginningOfDay.unix(), 0)
        let query: firebase.firestore.Query<firebase.firestore.DocumentData> = stockEvents
        if (type !== "all") {
            query = query.where("type", "==", type)
        }
        query = query.orderBy("timestamp").startAt(timestamp)
        const snap = await query.get()
        return snap
    }

    private async getStock(shopId: string) {
        const stockSnap = await ref().child(`v1/accounts/${this.accountId}/stock_locations/${shopId}/inventory/stock`).get()
        return stockSnap.val() ?? {}
    }

    private async getAllCountedProducts(date: Date, shopId: string, progress: (stockCounted: number) => void) {
        const now = dayjs(date)
        const beginningOfDay = now.startOf("day")

        const collection = firestore.collection(`accounts/${this.accountId}/stock_locations/${shopId}/stock_events`)
        const timestamp = new firebase.firestore.Timestamp(beginningOfDay.unix(), 0)

        let limit = 500
        var done = false

        const countedProductSet: _.Dictionary<any> = {}
        let lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null = null
        while (!done) {
            let query: firebase.firestore.Query<firebase.firestore.DocumentData>
            if (_.isNil(lastDoc)) {
                query = this.createQuery(collection, limit, new StartAt(timestamp))
            } else {
                query = this.createQuery(collection, limit, new StartAfter(lastDoc))
            }
            const snap = await query.get()
            done = snap.docs.length < limit
            const previousCount = Object.values(countedProductSet).length
            this.addToCountedProductMap(snap, countedProductSet)
            const currentCount = Object.values(countedProductSet).length

            const addedProducts = currentCount - previousCount
            progress(addedProducts)

            if (!done) {
                lastDoc = snap.docs[snap.docs.length - 1]
            }
        }
        return countedProductSet
    }

    private addToCountedProductMap(snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>, countedProductSet: _.Dictionary<any>) {
        for (const doc of snap.docs) {
            let data = doc.data()
            let productId = data.product_id
            let variantId = data.variant_id
            let combinedId = productId
            if (!_.isNil(variantId)) {
                combinedId = combinedId + "." + variantId
            }
            countedProductSet[combinedId] = data
        }
    }

    private async getProductInfo(productCache: any, data: firebase.firestore.DocumentData, market: any) {
        let product = productCache[data.product_id]
        if (product === undefined) {
            const productSnap = await ref().child(`v1/accounts/${this.accountId}/inventory/products/pos/${market}/${data.product_id}`).get()
            product = productSnap.val()
            productCache[data.product_id] = productSnap.val()
        }
        const productName = new L10nString(product.name ?? "-")
        let name = productName.localized(LanguageCode.da)
        let barcode = product.barcode
        if (!_.isNil(data.variant_id)) {
            const variant = product.variants.find((v: any) => { return v.id === data.variant_id })
            if (!_.isNil(variant)) {
                if (!_.isNil(variant.name)) {
                    name = new L10nString(variant.name).localized(LanguageCode.da)
                }
                if (!_.isNil(variant.barcode)) {
                    barcode = variant.barcode
                }
            }
        }
        return { name, barcode }
    }


    createQuery(collection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>, limit: number, type: StartAt | StartAfter) {
        let query: firebase.firestore.Query<firebase.firestore.DocumentData>
        if (type instanceof StartAt) {
            query = collection
                .orderBy("timestamp", "asc")
                .startAt(type.timestamp)
                .where("type", "==", "stock_count_reset")
                .limit(limit)
        } else {
            query = collection
                .orderBy("timestamp", "asc")
                .startAfter(type.lastDoc)
                .where("type", "==", "stock_count_reset")
                .limit(limit)
        }

        return query
    }

    async buildDocumentName(): Promise<string> {
        const shopNameSnapshot = await ref().child(`v1/accounts/${this.accountId}/shop_index/${this.shopId}/name`).once("value")
        const shopName = shopNameSnapshot.exists() ? shopNameSnapshot.val() : undefined

        const now = dayjs().format("MMMM Do YYYY, H_mm_ss")
        let result = "Stock report - "
        if (shopName) {
            result += shopName
            result += ", "
        }
        result += now
        return result
    }

    async buildCountPercentageDocumentName(fromDate: Date): Promise<string> {
        const shopNameSnapshot = await ref().child(`v1/accounts/${this.accountId}/shop_index/${this.shopId}/name`).once("value")
        const shopName = shopNameSnapshot.exists() ? shopNameSnapshot.val() : undefined

        const fromDateFormatted = dayjs(fromDate).format("MMMM Do YYYY, H_mm_ss")
        const now = dayjs().format("MMMM Do YYYY, H_mm_ss")
        let result = "Stock count percentage - "
        if (shopName) {
            result += shopName
            result += ", "
        }
        result += fromDateFormatted + " - " + now
        return result
    }

    async buildLastCountedDocumentName(displayOnlyUncounted: boolean): Promise<string> {
        const shopNameSnapshot = await ref().child(`v1/accounts/${this.accountId}/shop_index/${this.shopId}/name`).once("value")
        const shopName = shopNameSnapshot.exists() ? shopNameSnapshot.val() : undefined

        const now = dayjs().format("MMMM Do YYYY, H_mm_ss")
        let result: string
        if (displayOnlyUncounted) {
            result = "Unounted stock report - "
        } else {
            result = "Last counted stock report - "
        }
        if (shopName) {
            result += shopName
            result += ", "
        }
        result += now
        return result
    }

    // Private methods

    private async buildReportModels(progress: (created: number) => void): Promise<StockReportLine[]> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock`

        const result: StockReportLine[] = []
        let count = 0
        let done = false
        let fromLineItemId: string | undefined = undefined
        while (!done) {
            let query = ref().child(path).limitToFirst(fetchLimit + 1).orderByKey()
            if (fromLineItemId) {
                query = query.startAt(fromLineItemId)
            }

            const snapshot = await query.once("value")
            if (!snapshot.exists()) {
                done = true
                continue
            }

            const stockValues = snapshot.val() || {}
            const keys = Object.keys(stockValues)
            const batchKeys = _.take(keys, fetchLimit)
            for (const productId of batchKeys) {
                const product = this.productCatalogService.product(productId)
                const stockValue = stockValues[productId]
                if (typeof stockValue === "number") {
                    result.push(this.lineFrom(product, productId, undefined, stockValue))
                } else if (typeof stockValue === "object") {
                    for (const variantId in stockValue) {
                        const variantValue = stockValue[variantId]
                        result.push(this.lineFrom(product, productId, variantId, variantValue))
                    }
                } else {
                    console.info(`Stock value is not number or object: ${JSON.stringify(stockValue)}`)
                    continue
                }
            }

            count += batchKeys.length
            progress(count)

            const sortedKeys = sortLikeFirebaseOrderByKey(batchKeys)
            const lastId = _.last(sortedKeys)
            if (!lastId) {
                done = true
                continue
            } else {
                fromLineItemId = lastId
            }

            if (keys.length < fetchLimit + 1) {
                done = true
            }
        }
        return result
    }

    private async buildCountedReportModels(filter: StockReportFilter, displayOnlyUncounted: boolean, progress: (created: number) => void): Promise<StockNotCountedLine[] | StockLastCounted[]> {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock`

        const result: StockNotCountedLine[] = []
        const lastCounted: StockLastCounted[] = []
        let count = 0
        let done = false
        let fromLineItemId: string | undefined = undefined
        let countedProducts = await this.getAllCountedProducts(filter.filterDate, this.shopId, (stockCounted) => { })

        const stockValues = await this.getStock(this.shopId)

        const attributes = filter.filterAttributes ?? []
        // The logic that we want is that if the same attribute is present with more options
        // then we want to include products that have either of the options. So we transform the
        // list of attributes to a dictionary from attribute id to array of options:
        const attributeDict: _.Dictionary<AttributeFilter[]> = {}
        for (const attribute of attributes) {
            const existing = attributeDict[attribute.attributeId] ?? []
            existing.push({ value: attribute.optionId, comparison: attribute.comparison ?? "==" })
            attributeDict[attribute.attributeId] = existing
        }

        const keys = Object.keys(stockValues)
        for (const productId of keys) {
            const product = this.productCatalogService.product(productId)
            if (_.isNil(product)) {
                continue
            }
            const productDeleted: boolean = product.archived ?? false
            if (productDeleted) {
                continue
            }
            const stockValue = stockValues[productId]
            if (typeof stockValue === "number") {
                if (filter.excludeZeroStockProducts && stockValue <= 0) {
                    continue
                }
                if (!matchesFilter(product.attributes ?? {}, attributeDict)) {
                    continue
                }

                if (displayOnlyUncounted) {
                    if (productId in countedProducts) {
                        continue
                    }
                    result.push(this.notCountedLineFrom(product, productId, undefined, stockValue))
                } else {
                    let countedStockEvent = countedProducts[productId]
                    lastCounted.push(this.lastCountedLineFrom(product, productId, undefined, stockValue, countedStockEvent))
                }

            } else if (typeof stockValue === "object") {
                for (const variantId in stockValue) {
                    const variantValue = stockValue[variantId]
                    if (filter.excludeZeroStockProducts && variantValue <= 0) {
                        continue
                    }
                    const variant = product.variant(variantId)
                    const isDeleted = variant === null
                    if (isDeleted) { continue }

                    const mergedAttributes = _.merge(product.attributes ?? {}, variant.attributes ?? {})
                    if (!matchesFilter(mergedAttributes, attributeDict)) {
                        continue
                    }
                    
                    let combinedId: string = productId + "." + variantId
                    if (displayOnlyUncounted) {
                        if (combinedId in countedProducts) {
                            continue
                        }
                        result.push(this.notCountedLineFrom(product, productId, variantId, variantValue))
                    } else {
                        let countedStockEvent = countedProducts[combinedId]
                        lastCounted.push(this.lastCountedLineFrom(product, productId, variantId, variantValue, countedStockEvent))
                    }
                }
            } else {
                console.info(`Stock value is not number or object: ${JSON.stringify(stockValue)}`)
                continue
            }
        }

        count += keys.length
        progress(count)

        if (displayOnlyUncounted) {
            return result
        } else {
            return lastCounted
        }
    }

    private notCountedLineFrom(product: Product | undefined, productId: string, variantId: string | undefined, count: number): StockNotCountedLine {
        let barcode: string | undefined = undefined
        if (!_.isNil(product)) {
            if (!_.isNil(variantId) && !_.isNil(product.variant(variantId))) {
                barcode = product.variant(variantId)?.barcode ?? product.barcode
            } else {
                barcode = product.barcode
            }
        }
        const variant = variantId && product ? product.variant(variantId) : null
        const name = product ? productName(product, variant, LanguageCode.da) : "PRODUCT MISSING"
        const line = new StockNotCountedLine(barcode, name, productId, variantId, count)
        return line
    }

    private lastCountedLineFrom(product: Product | undefined, productId: string, variantId: string | undefined, count: number, stockEvent: any): StockLastCounted {
        let barcode: string | undefined = undefined
        if (!_.isNil(product)) {
            if (!_.isNil(variantId) && !_.isNil(product.variant(variantId))) {
                barcode = product.variant(variantId)?.barcode ?? product.barcode
            } else {
                barcode = product.barcode
            }
        }
        const variant = variantId && product ? product.variant(variantId) : null
        const name = product ? productName(product, variant, LanguageCode.da) : "PRODUCT MISSING"
        let lastCountedDate = stockEvent?.timestamp?.toDate()
        let stockCountId = stockEvent?.source?.stock_count?.stock_count_id
        const line = new StockLastCounted(barcode, name, productId, variantId, lastCountedDate, count, stockCountId)
        return line
    }

    private lineFrom(product: Product | undefined, productId: string, variantId: string | undefined, value: number): StockReportLine {
        let barcode: string | undefined = undefined
        if (!_.isNil(product)) {
            if (!_.isNil(variantId) && !_.isNil(product.variant(variantId))) {
                barcode = product.variant(variantId)?.barcode ?? product.barcode
            } else {
                barcode = product.barcode
            }
        }
        const variant = variantId && product ? product.variant(variantId) : null
        const name = product ? productName(product, variant, LanguageCode.da) : "PRODUCT MISSING"
        const prices = this.productCatalogService.productPrices(productId, variantId)
        const productGroup = product ? product.product_group : undefined
        const productDeleted: boolean = product?.archived ?? true
        const isDeleted = productDeleted || (variantId !== undefined && product?.variant(variantId) === null)
        const line = new StockReportLine(barcode, value, name, productId, variantId, prices, productGroup, isDeleted)
        return line
    }
}

class StartAt {
    timestamp: firebase.firestore.Timestamp

    constructor(timestamp: firebase.firestore.Timestamp) {
        this.timestamp = timestamp
    }
}

class StartAfter {
    lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>

    constructor(lastDoc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
        this.lastDoc = lastDoc
    }
}