import _ from "lodash";
import React from "react";
import { EmailTemplateLine } from "src/models/email_template";
import { DeleteButton } from "src/components/DeleteButton";

export interface LineItemProps {
    lineItem: EmailTemplateLine;
    onDelete: () => void;
}

export function LineItem(props: LineItemProps) {
    return (
        <div className="component-container">
            <div className="info-container">
                <div className="container">
                    <span className="label">Text:</span>
                    <span className="value">{props.lineItem.text}</span>
                </div>
                {!_.isNil(props.lineItem.textColor) ? (
                    <div className="container">
                        <span className="label">Text color:</span>
                        <span className="value">{props.lineItem.textColor}</span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.alignment) ? (
                    <div className="container">
                        <span className="label">Alignment:</span>
                        <span className="value">{props.lineItem.alignment}</span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.fontFamily) ? (
                    <div className="container">
                        <span className="label">Font family:</span>
                        <span className="value">{props.lineItem.fontFamily}</span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.fontStyle) ? (
                    <div className="container">
                        <span className="label">Font style:</span>
                        <span className="value"> {props.lineItem.fontStyle} </span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.fontWeight) ? (
                    <div className="container">
                        <span className="label">Font weight:</span>
                        <span className="value"> {props.lineItem.fontWeight} </span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.fontSize) ? (
                    <div className="container">
                        <span className="label">Font size:</span>
                        <span className="value"> {props.lineItem.fontSize} </span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.walletURL) && props.lineItem.walletURL == true ? (
                    <div className="container">
                        <span className="label">Wallet URL:</span>
                        <span className="value"> {props.lineItem.walletURL ? "True" : "False"} </span>
                    </div>
                ) : null}
            </div>
            <div className="button-container">
                <DeleteButton onDelete={props.onDelete}></DeleteButton>
            </div>
        </div>
    );
}
