//
//  AttributeList.tsx
//  POSFirebaseHosting
//
//  Created by Flemming Pedersen on 12/07/2018.
//  Copyright © 2018 Ka-ching. All rights reserved.
//

import * as React from "react"
import { Button, Card } from "../wrappers"
import { forEach } from "lodash"
import { ref } from "../../config/constants"
import { PageState } from "../PageState"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { StripedTable } from "../StripedTable"
import { Role } from "../../config/role"
import { Attribute, attributeTypeName } from "../../models/Product"
import { LanguageCode } from "../../helpers/L10n"
import { RoleRouterProps, withRoleRouter } from "src/routes"

export type AttributeMode = "product" | "customer"

interface AttributeListProps extends RoleRouterProps {
    mode: AttributeMode
}

interface AttributeListState {
    attributes: Attribute[]
    currentLanguage: LanguageCode | null
    loaded: boolean
}

class AttributeList extends React.Component<AttributeListProps, AttributeListState> {

    // Properties

    initialState: AttributeListState = {
        attributes: [],
        currentLanguage: LanguageCode.en,
        loaded: false
    }

    // Component

    constructor(props: AttributeListProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.setState({ loaded: false })

        this.loadState()
    }

    componentWillUnmount() {
        this.attributesRef().off()
    }

    componentDidUpdate(prevProps: Readonly<AttributeListProps>, prevState: Readonly<AttributeListState>, snapshot?: any): void {
        if (prevProps.mode !== this.props.mode) {
            this.loadState()
        }        
    }

    typeName() {
        if (this.props.mode === "product") {
            return "Product attributes"
        } else {
            return "Customer attributes"
        }
    }

    newTypeName() {
        if (this.props.mode === "product") {
            return "New product attribute"
        } else {
            return "New customer attribute"
        }
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName={this.typeName()}>
                <Card className="my-4">
                    <Card.Header>
                        <Button onClick={this.newAttribute}>{this.newTypeName()}</Button>
                    </Card.Header>
                    <StripedTable>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.attributes.map(attribute => {
                                    return (
                                        <tr key={attribute.id} onClick={() => { this.editAttribute(attribute.id) }}>
                                            <td>{attribute.name.localized(this.state.currentLanguage) ?? attribute.name.value}</td>
                                            <td>{attributeTypeName(attribute.typeKey())}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Really delete ${attribute.name.localized(this.state.currentLanguage)} attribute?`}
                                                    onDelete={async () => { await this.removeAttribute(attribute.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </StripedTable>
                    <Card.Footer>
                        <Button onClick={this.newAttribute}>{this.newTypeName()}</Button>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }

    // State

    loadState() {
        this.attributesRef()
            .on("value", snapshot => {
                if (!snapshot || (snapshot && !snapshot.exists())) {
                    this.setState({ attributes: [], loaded: true })
                    return
                }

                const attributes: Attribute[] = []
                forEach(snapshot.val(), (value) => {
                    const attribute = new Attribute(value)
                    attributes.push(attribute)
                })

                const sorted = attributes.sort((lhs, rhs) => {
                    return lhs.name.localized(this.state.currentLanguage) < rhs.name.localized(this.state.currentLanguage) ? -1 : 1
                })

                this.setState({
                    attributes: sorted,
                    loaded: true
                })
            })
    }

    // Attribute

    newAttribute = () => {
        if (this.props.mode === "product") {
            this.props.router.navigate("/attribute/new")
        } else {
            this.props.router.navigate("/customer_attribute/new")
        }
    }

    editAttribute = (key: string) => {
        if (this.props.mode === "product") {
            this.props.router.navigate(`/attribute/${key}`)
        } else {
            this.props.router.navigate(`/customer_attribute/${key}`)
        }
    }

    async removeAttribute(key: string) {
        await this.attributesRef().child(key).set(null)
    }

    // Helpers

    attributesRef() {
        if (this.props.mode === "product") {
            return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/attributes")
        } else {
            return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/customer_attributes")            
        }
    }
}

export default withRoleRouter(AttributeList)
