import * as React from "react"
import {
    Button,
    Card,
    Table
} from "../../wrappers"
import { ConfirmDeleteButton } from "../../ConfirmDeleteButton"
import { ModalPicker } from "../../ModalPicker"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { RoleRouterProps, withRoleRouter } from "src/routes"

interface ProductRuleElement {
    id: string
    name: string
}

interface AvailableElement {
    id: string
    name: string
}

interface ProductRuleListState {
    rules: ProductRuleElement[]
    availableRules: AvailableElement[]
    isAvailableRulesPickerVisible: boolean
    loaded: boolean
}

const availableRules: AvailableElement[] = [
    {
        id: "container_deposit",
        name: "Container deposit"
    }
]

class ProductRuleList extends React.Component<RoleRouterProps, ProductRuleListState> {

    // Properties

    initialState: ProductRuleListState = {
        rules: [],
        availableRules: availableRules,
        isAvailableRulesPickerVisible: false,
        loaded: false
    }

    // Component

    constructor(props: RoleRouterProps) {
        super(props)

        this.state = this.initialState
    }

    componentDidMount() {
        this.productRuleRepoRef()
            .on("value", snapshot => {
                if (!snapshot || !snapshot.exists()) {
                    this.setState({ rules: [], loaded: true })
                    return
                }

                const dict = snapshot.val()
                let rules: ProductRuleElement[] = []

                Object.keys(dict).forEach(key => {
                    const val = dict[key]
                    const containerDepositRule = val.container_deposit
                    rules.push({
                        id: key,
                        name: containerDepositRule.name,
                    })
                })
                rules = rules.sort((a: ProductRuleElement, b: ProductRuleElement) => {
                    return b.name > a.name ? 1 : -1
                })
                this.setState({ rules: rules, loaded: true })
            })
    }

    componentWillUnmount() {
        this.productRuleRepoRef().off()
    }

    render() {
        return (
            <PageState loading={!this.state.loaded} typeName="product rules">
                <div>
                    {this.state.isAvailableRulesPickerVisible && <ModalPicker
                        elements={this.state.availableRules}
                        onCancel={this.cancelAvailableRulesPicker}
                        onSelect={(element: AvailableElement) => { this.createRule(element.id) }}
                        title="New rule"
                    />}

                    <Card className="my-4">
                        <Card.Header>
                            <Button onClick={this.showAvailableRulesPicker}>New rule</Button>
                        </Card.Header>
                        <Table striped={true} bordered={true} condensed={true} hover={true} style={{ cursor: "pointer" }} fill={("true" as any) as boolean} >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.rules.map((rule) => {
                                    return (
                                        <tr key={rule.id} onClick={() => { this.editRule(rule.id) }} >
                                            <td>{rule.name}</td>
                                            <td className="narrow">
                                                <ConfirmDeleteButton
                                                    message={`Really delete ${rule.name}???`}
                                                    onDelete={() => { this.removeRule(rule.id) }}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                        <Card.Footer>
                            <Button onClick={this.showAvailableRulesPicker}>New rule</Button>
                        </Card.Footer>
                    </Card>
                </div>
            </PageState>
        )
    }

    // Methods

    productRuleRepoRef() {
        const accountId = this.props.role.account_id
        return ref().child(`v1/accounts/${accountId}/inventory/product_rule_repo`)
    }

    // edit rule /rules/rule_key
    editRule(key: string) {
        this.props.router.navigate(`/product_rule/${key}`)
    }

    //new rule /rules/new?new=ruletype
    createRule(type: string) {
        const path = "/product_rule/new"

        this.props.router.navigate({
            pathname: path,
            search: `?new=${type}`,
        })
    }

    removeRule(key: string) {
        const accountId = this.props.role.account_id

        void ref()
            .child(`v1/accounts/${accountId}/inventory/product_rule_repo/${key}`)
            .set(null)
    }

    showAvailableRulesPicker = () => {
        this.setState({ isAvailableRulesPickerVisible: true })
    }

    cancelAvailableRulesPicker = () => {
        this.setState({ isAvailableRulesPickerVisible: false })
    }
}

export default withRoleRouter(ProductRuleList)
