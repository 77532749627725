import React, { useEffect, useState } from "react";
import { RoleRouterProps, withRoleRouterFunc } from "src/routes";
import { Button, Card, Col, DescriptionCol, FormControl, FormGroup, Row, ToggleButtonGroup } from "../wrappers";
import { Form } from "react-bootstrap";
import firebase from "firebase/compat/app";
import { GiftcardType } from "src/models/GiftcardType";
import { FormattedTime } from "react-intl";
import { ToggleButton } from "../ToggleButton";
import { ToggleButton  as ToggleButtonWrapper } from "../wrappers";
import { Globals } from "src/helpers/globals";
import { Currency } from "src/models/MarketModels";
import _, { cloneDeep } from "lodash";
import { PageState } from "../PageState";

function GiftcardTypeDetail(props: RoleRouterProps) {
    const [giftcardType, setGiftcardType] = useState<GiftcardType | null>(null)
    const [currencies, setCurrencies] = useState<Currency[]>([{currencyCode: "DKK"}])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isDirty, setIsDirty] = useState<boolean>(false)
    const [isPublishing, setIsPublishing] = useState<boolean>(false)
    const accountId = props.role.account_id
    const typeId = props.router.params.typeId

    useEffect(() => {
        const promises: Promise<any>[] = [Globals.shared.getCurrencies(), fetchGiftcardType(accountId, typeId)()]
        Promise.all(promises).then(promise => {
            const [currencies, value] = promise
            const data = (value.data as any)
            const giftcardType = GiftcardType.fromJSON(data)
            setGiftcardType(giftcardType)
            setCurrencies(currencies)
            setIsLoading(false)
        })
        
    }, [props.role.account_id])

    
    return (
        <PageState loading={isLoading} publishing={isPublishing} dirty={isDirty} typeName="giftcard type" submit_action={async () => { await submit(accountId, typeId, giftcardType?.toJSON()) }}>
        <Button //style={{ marginLeft: "10px" }}  
        onClick={() => { 
            let path = `pdf`
            props.router.navigate(path) 
        }} style={{marginRight: "10px"}}>PDF template</Button>
        <Button
        onClick={() => { 
            let path = `email`
            props.router.navigate(path) 
        }}>Email template</Button>
        <Card className="my-4">
        <Card.Header> Giftcard Type: {giftcardType?.name}</Card.Header>
        <Card.Body> 
            <Form>
            <FormGroup className="mb-3" as={Row}> 
                <DescriptionCol sm={2}>
                    <Form.Label>Identifier</Form.Label>
                </DescriptionCol>
                
                <Col sm={10}>
                    <FormControl
                        disabled={true}
                        type="text"
                        name="identifier"
                        value={giftcardType?.id  ?? ""}
                        placeholder="Identifier"
                    />
                </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                <DescriptionCol sm={2}>
                    <Form.Label>Name</Form.Label>
                </DescriptionCol>
                
                <Col sm={10}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="name"
                        value={giftcardType?.name ?? ""}
                        placeholder="Name"
                        onChange={handleEvent}
                    />
                </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                <DescriptionCol sm={2}>
                    <Form.Label>Email from address</Form.Label>
                </DescriptionCol>
                
                <Col sm={10}>
                    <FormControl
                        disabled={false}
                        type="text"
                        name="email_from_address"
                        value={giftcardType?.email_from_address ?? ""}
                        placeholder="Email from address"
                        onChange={handleEvent}
                    />
                </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Email from name</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            name="email_from_name"
                            value={giftcardType?.email_from_name ?? ""}
                            placeholder="Email from name"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Email subject</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            name="email_subject"
                            value={giftcardType?.email_subject ?? ""}
                            placeholder="Email subject"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Max amount</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="number"
                            name="max_amount"
                            value={giftcardType?.max_amount ?? ""}
                            placeholder="Max amount"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Expiry days</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="number"
                            name="expiry_days"
                            value={giftcardType?.expiry_days ?? ""}
                            placeholder="Expiry days"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>Currency</DescriptionCol>
                    <Col sm={10}>
                        <ToggleButtonGroup
                            type="radio"
                            name="currency"
                            value={giftcardType?.currency ?? ""}
                            onChange={(value) => {
                                let giftcardTypeCopy = _.cloneDeep(giftcardType);
                                if (!_.isNil(giftcardTypeCopy)) {
                                    giftcardTypeCopy.currency = value
                                    setIsDirty(true)
                                    setGiftcardType(giftcardTypeCopy);
                                }
                            }}
                        >
                            {
                                currencies.map((currency) => {
                                    return <ToggleButtonWrapper id={currency.currencyCode} key={currency.currencyCode} value={currency.currencyCode}>{currency.currencyCode}</ToggleButtonWrapper>
                                })
                            }
                        </ToggleButtonGroup>
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Created</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                            <FormattedTime
                                value={giftcardType?.created ?? ""}
                                day="numeric"
                                month="long"
                                year="numeric"
                                hour="numeric"
                                minute="numeric"
                            />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Locale</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            name="locale"
                            value={giftcardType?.locale ?? ""}
                            placeholder="Locale"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                    <DescriptionCol sm={2}>
                        <Form.Label>Timezone</Form.Label>
                    </DescriptionCol>
                    
                    <Col sm={10}>
                        <FormControl
                            disabled={false}
                            type="text"
                            name="timezone"
                            value={giftcardType?.timezone ?? ""}
                            placeholder="Timezone"
                            onChange={handleEvent}
                        />
                    </Col>
                </FormGroup>

                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>
                        <Form.Label>Set expiry on creation</Form.Label>
                    </DescriptionCol>
                    <Col sm={10}>
                        <ToggleButton
                            enabledTitle="True"
                            disabledTitle="False"
                            disabled={!giftcardType?.set_expiry_on_creation}
                            active={giftcardType?.set_expiry_on_creation ?? false}
                            performToggle={(() => {  
                                let giftcardTypeCopy = _.cloneDeep(giftcardType);
                                if (!_.isNil(giftcardTypeCopy)) {
                                    giftcardTypeCopy.set_expiry_on_creation = !giftcardTypeCopy.set_expiry_on_creation
                                    setIsDirty(true)
                                    setGiftcardType(giftcardTypeCopy);
                                }
                            })}
                        />
                    </Col>
                </FormGroup>
                <FormGroup className="mb-3" as={Row}> 
                <DescriptionCol sm={2}>
                        <Form.Label>Use Wallet</Form.Label>
                    </DescriptionCol>
                    <Col sm={10}>
                        <ToggleButton
                            enabledTitle="True"
                            disabledTitle="False"
                            disabled={!giftcardType?.use_wallet}
                            active={giftcardType?.use_wallet ?? false}
                            performToggle={() => {  
                                let giftcardTypeCopy = _.cloneDeep(giftcardType);
                                if (!_.isNil(giftcardTypeCopy)) {
                                    giftcardTypeCopy.use_wallet = !giftcardTypeCopy.use_wallet 
                                    setIsDirty(true)
                                    setGiftcardType(giftcardTypeCopy);
                                }
                            }}
                        />
                    </Col>
                </FormGroup>
                { giftcardType?.use_shopify ? 
                <FormGroup className="mb-3" as={Row}>
                    <DescriptionCol sm={2}>
                        <Form.Label>Synchronize in Shopify</Form.Label>
                    </DescriptionCol>
                    <Col sm={10} style={{paddingTop: 3}}> This gift card type is configured to be synchronized in Shopify </Col>
                </FormGroup>
                : null
                }
                
            </Form>
        </Card.Body>
        </Card>
        </PageState>
    )

    function handleEvent(event: any) {
        let giftcardTypeCopy = _.cloneDeep(giftcardType);
        if (!_.isNil(giftcardTypeCopy)) {
            const name = event.target.name
            let value = event.target.value
            if (name === "max_amount" || name === "expiry_days") {
                value = Number(value)
            }
            giftcardTypeCopy[event.target.name] = value
            setIsDirty(true)
            setGiftcardType(giftcardTypeCopy)
        }
    }

    async function submit(accountId: string, type: string, giftcardType: any) {
        const args: any = {
            action: "giftcard-type-create",
            account: accountId,
            type: type,
            data: giftcardType
        };
        setIsPublishing(true)
        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
        setIsPublishing(false)
        setIsDirty(false)
    }
}

export default withRoleRouterFunc(GiftcardTypeDetail)


function isActive(value: boolean | undefined, loaded: boolean): boolean {
    if (!loaded) { return false }
    return value ?? false;
}

function fetchGiftcardType(accountId: string, type: string): () => Promise<firebase.functions.HttpsCallableResult> {
    const args: any = {
        action: "giftcard-type-read",
        account: accountId,
        type: type
    };

    const fetchGiftcardType = async () => {
        const client = firebase.functions().httpsCallable("clientApi");
        return await client(args);
    };
    return fetchGiftcardType
}