import * as React from "react"
import {
    Button,
    Col,
    
    Form,
    FormControl,
    FormGroup,
    Label,
    Card
} from "../../wrappers"
import { EmailValidation } from "../../../helpers/validation"
import { PageState } from "../../PageState"
import { ref } from "../../../config/constants"
import { Role } from "../../../config/role"
import firebase from "firebase/compat"
import { RoleProps, RoleRouterProps, withRole, withRoleRouter } from 'src/routes';
import { useNavigate } from "react-router"
import { Row } from "react-bootstrap"

const defaultIntervalMinutes = 60

interface IntegrationNotificationConfigurationState {
    dirty: boolean
    integrationFailureEmail?: string
    intervalMinutes: number
    loaded: boolean
    publishing: boolean
}

class IntegrationNotificationConfiguration extends React.Component<RoleRouterProps, IntegrationNotificationConfigurationState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            dirty: false,
            intervalMinutes: defaultIntervalMinutes,
            loaded: false,
            publishing: false
        }
    }

    // Helpers

    handleEmailChange = (event: any) => {
        const target = event.target
        const value = target.value

        const emailValue = value ? value : null

        this.setState({ integrationFailureEmail: emailValue, dirty: true })
    }

    intervalChanged = (value: number) => {
        if (value <= 0) {
            return
        }

        this.setState({ intervalMinutes: Math.round(value), dirty: true })
    }

    isPublishDisabled = (): boolean => {
        if (!this.state.dirty) {
            return true
        }
        if (!this.state.integrationFailureEmail) {
            return false
        }
        return !EmailValidation.valid(this.state.integrationFailureEmail)
    }

    publish = async () => {
        if (!this.state.intervalMinutes) {
            return
        }

        this.setState({ publishing: true })

        await this.configRef().set({
            email: this.state.integrationFailureEmail,
            interval_minutes: this.state.intervalMinutes
        })

        this.setState({ dirty: false, publishing: false })
    }

    configRef = (): firebase.database.Reference => {
        const account = this.props.role.account_id
        return ref().child(`v1/accounts/${account}/configuration/notifications/integration_failure`)
    }

    // Component

    async componentDidMount() {
        const state: any = { loaded: true }

        const configSnapshot = await this.configRef().once("value")
        if (configSnapshot.exists()) {
            const configValue = configSnapshot.val()
            state.integrationFailureEmail = configValue.email
            state.intervalMinutes = configValue.interval_minutes || defaultIntervalMinutes
        }

        this.setState(state)
    }

    pop() {
        this.props.router.navigate(-1)
    }
    
    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="email configuration"
                submit_action={async () => { await this.publish() }}
                discard_action={() => { this.pop() }}
            >
                <Card className="my-4">
                    <Card.Header>
                        Integration failure email
                    </Card.Header>

                    <Card.Body>
                        <p>
                            If an email is specified here you will receive email notifications when an integration error happens. The mails will be limited to 1 mail per error message per integration per interval. Default interval is {defaultIntervalMinutes} minutes. It can be adjusted below.
                        </p>
                        <ul>
                            <li>Export trigger that fails, for example a export of a sale.</li>
                            <li>Import element that fails, for example import of a product.</li>
                            <li>Runtime request that fails, for example a customer lookup request.</li>
                        </ul>
                        <p>
                            <Label variant="info">Info</Label> Failures that are reported in the reponse to the import endpoints will be ignored. Only errors found during import processing will be reported.
                        </p>
                        <Form>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Email address</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="text"
                                        name="invite_email"
                                        value={this.state.integrationFailureEmail || ""}
                                        placeholder="Enter email"
                                        onChange={this.handleEmailChange}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-3" as={Row}>
                                <Col sm={2}>Interval minutes</Col>
                                <Col sm={10}>
                                    <FormControl
                                        type="number"
                                        name="interval"
                                        value={this.state.intervalMinutes}
                                        placeholder="Enter interval in minutes"
                                        onChange={(e: any) => { this.intervalChanged(e.target.value) }}
                                        autoComplete="off"
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </Card.Body>

                    <Card.Footer>
                        <Button onClick={this.publish} disabled={this.isPublishDisabled()}>Publish</Button>
                    </Card.Footer>
                </Card>
            </PageState>
        )
    }
}

export default withRoleRouter(IntegrationNotificationConfiguration)
