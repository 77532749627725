import * as _ from "lodash"
import firebase from "firebase/compat/app"
import * as JSMapping from "../../../helpers/jsMapValue"
import { buildStockCountLines } from "../../../helpers/StockCountHelpers"
import {
    fetchPage,
    StockCountFilterType
} from "../../../services/StockCountLinesQueryService"
import { ProductCatalogService } from "../../../services/ProductCatalogService"
import { firestore, ref } from "../../../config/constants"
import { sortLikeFirebaseOrderByKey } from "../../../helpers/sorting"
import {
    StockCountDevice,
    StockCountLine,
    StockCountLineItem,
    StockCountProductInfo
} from "../../../models/StockCountModels"
import { StockCountReportBuilder } from "../../../services/StockCountReportBuilder"
import { Globals } from "../../../helpers/globals"

const pageLimit = 25

enum StockCountPastUpdateType {
    Next,
    Previous,
    ReloadCurrent
}

class StockCountPastViewModelSearch {
    text: string
    constructor(text: string) {
        this.text = text
    }
}

export class StockCountPastViewModel {

    // Props

    private accountId: string
    private currentSearch?: StockCountPastViewModelSearch
    private devices: StockCountDevice[]
    private filter: StockCountFilterType
    private lineItemIdPagingStack: string[]
    private nextDisabled: boolean
    private page: number
    private previousDisabled: boolean
    private searchText: string
    private shopId: string
    private stockCountId: string
    private productCatalogService: ProductCatalogService

    // Outputs

    csvReportCreationCompleted: (report?: string, fileName?: string, errorMessage?: string) => void = () => { }
    devicesChanged: (devices: StockCountDevice[]) => void = () => { }
    largeStockCountCreated: () => void = () => { }
    indexLoaded: (index: any) => void = () => { }
    searchCompleted: (lines?: StockCountLine[], errorMessage?: string) => void = () => { }
    stockCountLinesChanged: (lines: StockCountLine[]) => void = () => { }

    // Constructor

    constructor(accountId: string, shopId: string, stockCountId: string) {
        this.accountId = accountId
        this.devices = []
        this.filter = StockCountFilterType.ALL
        this.lineItemIdPagingStack = []
        this.nextDisabled = true
        this.page = 0
        this.previousDisabled = true
        this.searchText = ""
        this.shopId = shopId
        this.stockCountId = stockCountId
        this.productCatalogService = new ProductCatalogService()
    }

    // Public Methods

    closeSearch() {
        this.currentSearch = undefined
    }

    createCSVReport(type: StockCountFilterType, progress: (loaded: number, aggregated: number) => void) {
        const builder = new StockCountReportBuilder(this.accountId, this.shopId, this.stockCountId, this.productCatalogService)
        builder.buildStockCountReport(type, progress)
            .then(([report, fileName]) => {
                if (!report) {
                    throw new Error("Internal error")
                }
                if (this.csvReportCreationCompleted) {
                    this.csvReportCreationCompleted(report, fileName, undefined)
                }
            })
            .catch((error) => {
                if (this.csvReportCreationCompleted) {
                    this.csvReportCreationCompleted(undefined, undefined, error.message)
                }
            })
    }

    isNextDisabled(): boolean {
        return this.nextDisabled
    }

    isPreviousDisabled(): boolean {
        return this.previousDisabled
    }

    isSearchButtonDisabled(): boolean {
        return (this.searchText || "").length < 3
    }

    isShowingSearchResults(): boolean {
        return this.currentSearch !== undefined
    }

    filterValue(): StockCountFilterType {
        return this.filter
    }

    filterValueSelected(data: any): boolean {
        // If not new
        if (data.length === 0) {
            return false
        }

        // Remove already selected
        const strings: string[] = data
        _.remove(strings, (val) => { return val === this.filter })

        // get remaining
        const identifier = _.head(strings) as StockCountFilterType
        if (identifier === this.filter) {
            return false
        }

        // reset state and start load
        this.page = 0
        this.filter = identifier
        this.lineItemIdPagingStack = []
        this.startLoadOfNextStockCountLines()

        return true
    }

    performSearch() {
        const text = this.searchText
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            count_id: this.stockCountId,
            search_term: text,
            action: "search"
        }
        const search = firebase.functions().httpsCallable("StockCount-client")
        search(args)
            .then((value) => {
                const success = value.data.success
                const result = value.data.result
                if (!success || typeof result !== "object") {
                    throw new Error("Could not perform search")
                }
                if (result.length === 0) {
                    throw new Error(`No products found matching: ${text}`)
                }
                if (this.searchCompleted) {
                    this.currentSearch = new StockCountPastViewModelSearch(text)
                    const lineItems: StockCountLineItem[] = result.map((item: any) => {
                        const lineItem = new StockCountLineItem(item)
                        lineItem.product = new StockCountProductInfo(item.product_data, lineItem.product.productId, lineItem.product.variantId)
                        return lineItem
                    })
                    this.searchCompleted(buildStockCountLines(lineItems, pageLimit))
                }
            })
            .catch((error: Error) => {
                if (this.searchCompleted) {
                    this.searchCompleted(undefined, error.message)
                }
            })
    }

    searchTextChanged(text: string) {
        this.searchText = text
    }

    searchTextValue(): string {
        return this.searchText
    }

    startLoadOfName() {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/count_index/${this.stockCountId}`
        ref().child(path).once("value")
            .then((snapshot) => {
                if (!snapshot.exists()) {
                    return
                }
                if (this.indexLoaded) {
                    this.indexLoaded(snapshot.val())
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    startLoadOfCurrentStockCountLines() {
        this.lineItemIdPagingStack.pop()
        const fromLineItemId = _.last(this.lineItemIdPagingStack)
        this.loadBatchOfStockCountLineItems(fromLineItemId, StockCountPastUpdateType.ReloadCurrent)
            .catch((error: any) => {
                console.error(error)
            })
    }

    startLoadOfNextStockCountLines() {
        const fromLineItemId = _.last(this.lineItemIdPagingStack)
        this.loadBatchOfStockCountLineItems(fromLineItemId, StockCountPastUpdateType.Next)
            .catch((error: any) => {
                console.error(error)
            })
    }

    startLoadOfPreviousStockCountLines() {
        this.lineItemIdPagingStack.pop()
        this.lineItemIdPagingStack.pop()
        this.loadBatchOfStockCountLineItems(_.last(this.lineItemIdPagingStack), StockCountPastUpdateType.Previous)
            .catch((error: any) => {
                console.error(error)
            })
    }

    startLoadDevices() {
        this.devicesRef().once("value")
            .then(snapshot => {
                if (!snapshot.exists()) {
                    this.devices = []
                } else {
                    const devicesData = snapshot.val()
                    this.devices = Object.values(devicesData).map((d: any) => {
                        return new StockCountDevice(d)
                    })
                }
                const backofficeDeviceJSON = {
                    active: false,
                    device_id: "_portal",
                    device_name: "Back Office",
                    sessions: []
                }
                const backofficeDevice = new StockCountDevice(backofficeDeviceJSON)
                this.devices.push(backofficeDevice)
                this.devicesChanged(this.devices)
            })
            .catch(error => {
                console.error(error)
            })
    }

    devicesRef(): firebase.database.Reference {
        const path = `/v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/counts/${this.stockCountId}/devices`
        return ref().child(path)
    }

    // Private methods

    private async loadBatchOfStockCountLineItems(fromLineItemId: string | undefined, type: StockCountPastUpdateType) {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/counts/${this.stockCountId}/lines`
        const values = await fetchPage(path, pageLimit, this.filter, fromLineItemId)
        if (_.isNil(values)) {
            return
        }

        await this.buildStockCountLinesAndUpdatePaging(values, type)
    }

    private async buildStockCountLinesAndUpdatePaging(lineItemJSONDictionary: any, type: StockCountPastUpdateType) {
        // saving original count for paging
        const originalCount = Object.keys(lineItemJSONDictionary).length

        const marketId = await Globals.getMarket(this.shopId)

        // filtering out any with missing product info since new lines won't have product info until a function has run
        const validLineItemsJSON: any = {}
        for (const key in lineItemJSONDictionary) {
            const value = lineItemJSONDictionary[key]
            if (_.isObject(value.product)) {
                validLineItemsJSON[key] = value
            }
        }

        const mappedValues = JSMapping.mapValuesToModelClass(validLineItemsJSON, StockCountLineItem) || {}
        const sortedKeys = sortLikeFirebaseOrderByKey(Object.keys(mappedValues))
        const lineItems = sortedKeys.map((key) => {
            return mappedValues[key]
        })

        // side effect - updating paging state
        if (type !== StockCountPastUpdateType.ReloadCurrent) {
            this.updateIndexIdPagingStack(lineItems, originalCount, type === StockCountPastUpdateType.Next)
        }

        const lineItemsByProductId: _.Dictionary<StockCountLineItem[]> = {}
        for (const lineItem of lineItems) {
            if (_.isObject(lineItem.product)) {
                lineItemsByProductId[lineItem.product.productId] = (lineItemsByProductId[lineItem.product.productId] ?? []).concat([lineItem])
            }
        }
        for (const productId in lineItemsByProductId) {
            // Calling synchronously in order to start all requests in parallel
            this.fetchProduct(productId, marketId, lineItemsByProductId, lineItems)
        }
    }

    private async fetchProduct(productId: string, marketId: string, lineItemsByProductId: _.Dictionary<StockCountLineItem[]>, lineItems: StockCountLineItem[]) {
        if (_.isNil(this.productCache[productId])) {
            const path = `accounts/${this.accountId}/inventory/${marketId}.pos/product_search_index/${productId}`
            const snapshot = await firestore.doc(path).get()
            if (snapshot.exists) {
                this.productCache[snapshot.id] = snapshot.data()?.product
            } else {
                // Find name if product deleted
                const rtdbPath = `v1/accounts/${this.accountId}/inventory/products/pos/${marketId}/${productId}`
                const snap = await ref().child(rtdbPath).get()
                const val = snap.val() ?? {}
                val.deleted = true
                this.productCache[snapshot.id] = val
            }
        }
        for (const lineItem of lineItemsByProductId[productId]) {
            lineItem.product = new StockCountProductInfo(this.productCache[productId], lineItem.product.productId, lineItem.product.variantId)
        }
        if (this.stockCountLinesChanged) {
            this.stockCountLinesChanged(buildStockCountLines(lineItems, pageLimit))
        }
    }

    private productCache: _.Dictionary<any> = {}

    private updateIndexIdPagingStack(lineItems: StockCountLineItem[], originalCount: number, isNext: boolean) {
        this.page += isNext ? 1 : -1
        // BG: Workaround for bug in firebase order by key scenario where we've seen that certain children are not found in the query here
        // where the CLI with the same query returns them all.
        // This can result is a erroneous disabling of the next button, so we've disabled it for now.
        // Firebase bug filed with id: 00012291
        this.nextDisabled = false // originalCount < pageLimit + 1
        if (!this.nextDisabled) {
            const lastLineItem = _.last(lineItems)
            if (lastLineItem) {
                this.lineItemIdPagingStack.push(lastLineItem.id)
            }
        }
        this.previousDisabled = this.page === 1
    }

}
