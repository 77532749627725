import CopyToClipboard from "react-copy-to-clipboard"
import * as React from "react"
import {
    Alert,
    Button,
    FormControl,
    FormGroup,
    InputGroup,
    InputGroupAddon,
    Card
} from "../../../wrappers"
import { ChannelSelector } from "../../../ChannelSelector"
import { MarketSelector } from "../../../MarketSelector"
import { shopifyAPIVersion, ShopifyConfiguration } from "./Models"
import { ToggleButton } from "../../../ToggleButton"
import _ from "lodash"
import { ref } from "../../../../config/constants"
import { Row } from "react-bootstrap"
import { BoxArrowUpRight, Clipboard2Check } from "react-bootstrap-icons"

interface ProductChangesCardProps {
    accountId: string
    channels: _.Dictionary<boolean>
    markets: _.Dictionary<boolean>
    configuration: ShopifyConfiguration
    enabled: boolean
    openShopifyNotifications: () => void
    updateDatabaseUpdates: (updates: _.Dictionary<any>) => void
    updateEnabled: (enabled: boolean) => void
    updateChannels: (channels: string[]) => void
    updateMarkets: (markets: string[]) => void
}

interface ProductChangesCardState {
    copied: boolean
    serviceUserUID?: string
}

export class ProductChangesCard extends React.Component<ProductChangesCardProps, ProductChangesCardState> {

    constructor(props: ProductChangesCardProps) {
        super(props)
        this.state = {
            copied: false
        }
    }

    async componentDidMount() {
        await this.load()
    }

    async load() {
        const accountRef = ref().child(`v1/accounts/${this.props.accountId}`)
        const serviceUserUID = (await accountRef.child("api_keys").child("service_user_uid").once("value")).val()
        this.setState({ serviceUserUID: serviceUserUID })
    }

    shopifyHelpText = (
        <div key="help_text">
            Follow these steps to configure the integration that synchronizes product changes from Shopify to Ka-ching:
            <ol>
                {
                    Object.keys(this.props.channels).length > 1 ? (
                        <li>
                            First use the channel selector below to select the channels you want the products to be available in.
                        </li>
                    ) : null
                }
                {
                    Object.keys(this.props.markets).length > 1 ? (
                        <li>
                            Use the market selector below to select the markets you want the products to be available in.
                        </li>
                    ) : null
                }
                <li>
                    Click the url to copy it to the clipboard.
                </li>
                <li>
                    Click the &quot;Open Shopify Notifications&quot; button above to jump to this page. Scroll all the way to the bottom of the page.
                </li>
                <li>
                    Click &quot;Create Webhook&quot; and select the &quot;Product creation&quot; event. Choose &quot;{shopifyAPIVersion}&quot; as the &quot;Webhook API version&quot;. Leave the format as &quot;JSON&quot;. Select the URL field and paste the URL that you copied in step 2.
                </li>
                <li>
                    Repeat the previous step for &quot;Product deletion&quot; and &quot;Product update&quot; events.
                </li>
                <li>
                    Click below to enable the integration in Ka-ching.
                </li>
                {
                    Object.keys(this.props.channels).length > 1 ? (
                        <li>
                            IMPORTANT: If you change the channel selection you need to update the URLs on the Shopify Notifications page.
                        </li>
                    ) : null
                }
                {
                    Object.keys(this.props.markets).length > 1 ? (
                        <li>
                            IMPORTANT: If you change the markets selection you need to update the URLs on the Shopify Notifications page.
                        </li>
                    ) : null
                }
                <li>
                    IMPORTANT: If you disable this integation remember to remove the newly created webhooks in Shopify&apos;s Notification settings.
                </li>
            </ol>
        </div>
    )

    shopifyImportURL(): string {
        if (this.props.configuration.apiKey) {
            const channelsParameter = Object.keys(this.props.configuration.channels).join(",")
            const marketsParameter = Object.keys(this.props.configuration.markets).join(",")
            return `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/imports/product/?apikey=${this.props.configuration.apiKey}&integration=shopify&account=${this.props.accountId}&transform=shopify&channels=${channelsParameter}&markets=${marketsParameter}`
        }
        return ""
    }

    toggleEnabled = () => {
        const enabled = !this.props.enabled
        this.props.updateEnabled(enabled)

        const uid = this.state.serviceUserUID
        if (_.isNil(uid)) {
            console.error("No service user defined on system")
            return
        }

        // update active flag on api_keys based on enabled status
        if (enabled && uid) {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/api_keys/import_integrations/products/shopify/${this.props.configuration.apiKey}/metadata`] = { active: true, uid: uid }
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/products/shopify`] = {
                name: "Shopify Product Import",
                shopify_id: this.props.configuration.shopifyShopName,
                access_token: this.props.configuration.shopifyAccessToken ?? null,
                api_key: this.props.configuration.shopifyAPIKey ?? null,
                password: this.props.configuration.shopifyPassword ?? null
            }
            this.props.updateDatabaseUpdates(dbUpdates)
        }
        if (!enabled && uid) {
            const dbUpdates: _.Dictionary<any> = {}
            dbUpdates[`v1/accounts/${this.props.accountId}/api_keys/import_integrations/products/shopify/${this.props.configuration.apiKey}/metadata`] = { active: false, uid: uid }
            dbUpdates[`v1/accounts/${this.props.accountId}/configuration/import_integrations/products/shopify`] = null
            this.props.updateDatabaseUpdates(dbUpdates)
        }
    }

    // Component

    render() {
        return (
            <Card className="my-4" key="product_changes" /*defaultExpanded={false}*/>
                <Card.Header>
                    Product changes
                </Card.Header>
                <Card.Body>
                    <Button onClick={() => { this.props.openShopifyNotifications() }}>Open Shopify Notifications <BoxArrowUpRight /> </Button>
                    <br /> <br />
                    {this.shopifyHelpText}
                    <FormGroup className="mb-3" as={Row}>
                        <ChannelSelector
                            selectedChannels={Object.keys(this.props.channels)}
                            onChange={channels => { this.props.updateChannels(channels) }}
                        />
                        <MarketSelector
                            typeName="Shopify integration"
                            showDeleteWarning={false}
                            selectedMarkets={Object.keys(this.props.markets)}
                            addMarket={market => {
                                const markets = _.cloneDeep(this.props.markets)
                                markets[market] = true
                                this.props.updateMarkets(Object.keys(markets))
                            }}
                            removeMarket={market => {
                                const markets = _.cloneDeep(this.props.markets)
                                delete markets[market]
                                this.props.updateMarkets(Object.keys(markets))
                            }}
                        />
                    </FormGroup>
                    <br /> <br />
                    <CopyToClipboard text={this.shopifyImportURL()} onCopy={() => this.setState({ copied: true })}>
                        <FormGroup className="mb-3" as={Row}>
                            <InputGroup>
                                <InputGroupAddon>Webhook URL</InputGroupAddon>
                                <FormControl type="text" readOnly={true} value={this.shopifyImportURL()} />
                                <InputGroupAddon>
                                    <Clipboard2Check />
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </CopyToClipboard>
                    {
                        this.state.copied
                            ?
                            <Alert variant="success"> Integration URL copied to clipboard. Now go to Shopify and configure your web hooks.</Alert>
                            :
                            null
                    }
                    <ToggleButton active={this.props.enabled} performToggle={() => { this.toggleEnabled() }} />
                    <br />
                    <br />
                </Card.Body>
            </Card>
        )
    }
}