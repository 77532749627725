import firebase from "firebase/compat/app"
/* tslint:disable */
import "firebase/compat/database"
import "firebase/compat/storage"
import "firebase/compat/auth"
import "firebase/compat/functions"
import "firebase/compat/firestore"
/* tslint:enable */

const config: any = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}

firebase.initializeApp(config)

let rootRef: firebase.database.Reference

export function ref(): firebase.database.Reference {
    if (!rootRef) {
        throw new Error("Please initialize database")
    }
    return rootRef
}

function setRef(theRef: firebase.database.Reference) {
    rootRef = theRef
}

export function selectRTDBInstance(rtdbInstance: string | undefined, region: string | undefined) {
    let databaseURL: string | undefined
    if (rtdbInstance) {
        if (region) {
            databaseURL = `https://${rtdbInstance}.${region}.firebasedatabase.app`
        } else {
            databaseURL = `https://${rtdbInstance}.firebaseio.com`
        }
    }
    setRef(firebase.app().database(databaseURL).ref())
}

const firestoreSetup = firebase.firestore()
firestoreSetup.settings({ignoreUndefinedProperties: true, merge: true})

export const defaultRef = firebase.database().ref()
export const firestore = firestoreSetup

export const firebaseAuth = firebase.auth
export const storage = firebase.storage()
export const displayLanguage = "en"
