import React from "react";
import { PDFTemplateLine } from "src/models/pdf_template/pdf_template_line";
import _ from "lodash";
import { DeleteButton } from "src/components/DeleteButton";

export interface LineItemProps {
    lineItem: PDFTemplateLine;
    onDelete: () => void;
}
export function TextLineItem(props: LineItemProps) {
    return (
        <div className="component-container">
            <div className="info-container">
                <div className="container">
                    <span className="label">Type:</span>
                    <span className="value">Text</span>
                </div>
                <div className="container">
                    <span className="label">Value:</span>
                    <span className="value">{props.lineItem.text}</span>
                </div>
                {!_.isNil(props.lineItem.textColor) ? (
                    <div className="container">
                        <span className="label">Text color:</span>
                        <span className="value">{props.lineItem.textColor}</span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.alignment) ? (
                    <div className="container">
                        <span className="label">Alignment:</span>
                        <span className="value">{props.lineItem.alignment}</span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.link) ? (
                    <div className="container">
                        <span className="label">Link:</span>
                        <span className="value">{props.lineItem.link}</span>
                    </div>
                ) : null}
                {!_.isNil(props.lineItem.background) ? (
                    <div className="container">
                        <span className="label">Background:</span>
                        <span className="value"> Set </span>
                    </div>
                ) : null}
            </div>
            <div className="button-container">
                <DeleteButton onDelete={props.onDelete}></DeleteButton>
            </div>
        </div>
    );
}
