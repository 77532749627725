import { L10nString, LanguageCode } from "../../../helpers/L10n"
import { v4 as uuid } from "uuid"
import _ from "lodash"

export type FrontPageSectionType = "shortcuts" | "curated_products" | "most_sold_products"

export const allSectionTypes: FrontPageSectionType[] = [
    "shortcuts", "most_sold_products", "curated_products"
]

export function sectionTypeName(type: FrontPageSectionType): string {
    switch (type) {
        case "shortcuts":
            return "Shortcuts"
        case "most_sold_products":
            return "Most sold products"
        case "curated_products":
            return "Curated products"
    }
}

export function sectionTypeDescription(type: FrontPageSectionType): string {
    switch (type) {
        case "shortcuts":
            return "A section containing a list of configurable shortcut commands"
        case "most_sold_products":
            return "An automatically generated section of products based on the sales from the previous days"
        case "curated_products":
            return "A manually curated list of products"
    }
}

export type ShortcutItem = "add_giftcard" |
    "basket_discount" |
    "basket_comment" |
    "cashier_dashboard" |
    "check_giftcard" |
    "clear_basket" |
    "customer_lookup" |
    "expenses" |
    "generate_sales_quote" |
    "open_cash_drawer" |
    "manual_product" |
    "previous_sales" |
    "sales_quotes_list" |
    "setup_printers" |
    "save_basket" |
    "saved_baskets_list" |
    "support" |
    "orders" |
    "employee_purchase" |
    "placed_orders" |
    "redeem_giftcard" |
    "manual_return_find_product" |
    "manual_return_manual_product" |
    "coupon_use" |
    "coupon_issue" |
    "select_cashier" |
    "customer_signup" |
    "insights" |
    "close_register" |
    "generate_x_report" |
    "previous_register_close_statements" |
    "register_add_remove_cash" |
    "electronic_journal" |
    "edit_register_name" |
    "app_mode" |
    "select_language" |
    "show_logs" |
    "mobilepay_configuration" |
    "nets_baxi_operations" |
    "label_printing" |
    "configure_zebra_scanner" |
    "shop_selection" |
    "log_out" |
    "izettle_configuration" |
    "payex_configuration" |
    "payex_commands" |
    "verifone_commands" |
    "external_register_id" |
    "about" |
    "show_deposit_box_info" |
    "deposit_from_deposit_box" |
    "reconcile_deposit_box" |
    "add_offer"

export function shortcutName(item: ShortcutItem): string {
    switch (item) {
        case "add_giftcard":
            return "Add giftcard"
        case "basket_discount":
            return "Add basket discount"
        case "basket_comment":
            return "Add basket comment"
        case "cashier_dashboard":
            return "Show cashier dashboard"
        case "check_giftcard":
            return "Check giftcard"
        case "redeem_giftcard":
            return "Redeem giftcard"
        case "clear_basket":
            return "Clear basket"
        case "customer_lookup":
            return "Customer lookup"
        case "expenses":
            return "Expenses"
        case "generate_sales_quote":
            return "Generate sales quote"
        case "open_cash_drawer":
            return "Open cash drawer"
        case "manual_product":
            return "Add manual product"
        case "previous_sales":
            return "Show previous sales"
        case "orders":
            return "Orders"
        case "placed_orders":
            return "Placed orders"
        case "sales_quotes_list":
            return "Sales quotes"
        case "setup_printers":
            return "Configure printers"
        case "save_basket":
            return "Save basket"
        case "saved_baskets_list":
            return "Show saved baskets"
        case "support":
            return "Support"
        case "employee_purchase":
            return "Employee purchase"
        case "manual_return_manual_product":
            return "Manual return (manual product)"
        case "manual_return_find_product":
            return "Manual return (find product)"
        case "coupon_use":
            return "Use coupon"
        case "coupon_issue":
            return "Issue coupon"
        case "about":
            return "About"
        case "app_mode":
            return "App mode"
        case "close_register":
            return "Close register"
        case "configure_zebra_scanner":
            return "Configure Zebra scanner"
        case "customer_signup":
            return "Customer signup"
        case "edit_register_name":
            return "Edit register name"
        case "electronic_journal":
            return "Show Electronic Journal"
        case "external_register_id":
            return "External register id (InfoSec)"
        case "generate_x_report":
            return "Generate X-report"
        case "insights":
            return "Insights"
        case "izettle_configuration":
            return "Zettle configuration"
        case "label_printing":
            return "Label printing"
        case "log_out":
            return "Log out"
        case "mobilepay_configuration":
            return "MobilePay configuration"
        case "nets_baxi_operations":
            return "Nets/baxi operations"
        case "payex_commands":
            return "PayEx commands"
        case "payex_configuration":
            return "PayEx configuration"
        case "previous_register_close_statements":
            return "Previous register close statements"
        case "register_add_remove_cash":
            return "Add remove cash"
        case "select_cashier":
            return "Select cashier"
        case "select_language":
            return "Select language"
        case "shop_selection":
            return "Shop selection"
        case "show_logs":
            return "Show logs"
        case "verifone_commands":
            return "Verifone commands"
        case "deposit_from_deposit_box":
            return "Add or remove cash from deposit box"
        case "reconcile_deposit_box":
            return "Reconcile deposit box"
        case "show_deposit_box_info":
            return "Show deposit box info"
        case "add_offer":
            return "Add offer"
    }
}

export const allShortcuts: ShortcutItem[] = [
    "add_giftcard",
    "basket_discount",
    "basket_comment",
    "cashier_dashboard",
    "check_giftcard",
    "clear_basket",
    "customer_lookup",
    "expenses",
    "generate_sales_quote",
    "open_cash_drawer",
    "manual_product",
    "previous_sales",
    "sales_quotes_list",
    "setup_printers",
    "save_basket",
    "saved_baskets_list",
    "support",
    "orders",
    "employee_purchase",
    "placed_orders",
    "redeem_giftcard",
    "manual_return_find_product",
    "manual_return_manual_product",
    "coupon_use",
    "coupon_issue",
    "select_cashier",
    "customer_signup",
    "insights",
    "close_register",
    "generate_x_report",
    "previous_register_close_statements",
    "register_add_remove_cash",
    "electronic_journal",
    "edit_register_name",
    "app_mode",
    "select_language",
    "show_logs",
    "mobilepay_configuration",
    "nets_baxi_operations",
    "label_printing",
    "configure_zebra_scanner",
    "shop_selection",
    "log_out",
    "izettle_configuration",
    "payex_configuration",
    "payex_commands",
    "verifone_commands",
    "external_register_id",
    "about",
    "deposit_from_deposit_box",
    "show_deposit_box_info",
    "reconcile_deposit_box",
    "add_offer"
]

export class FrontPageSection {
    type: FrontPageSectionType
    shortcutsSection?: ShortcutsSection
    productsSection?: ProductsSection
    mostSoldProducts?: MostSoldProductsSection
    id: string

    title(language: LanguageCode = LanguageCode.da): string {
        switch (this.type) {
            case "curated_products":
                return this.productsSection?.title.localized(language) ?? "-"
            case "most_sold_products":
                return this.mostSoldProducts?.title.localized(language) ?? "-"
            case "shortcuts":
                return this.shortcutsSection?.title.localized(language) ?? "-"
        }
    }

    constructor(type: FrontPageSectionType) {
        this.type = type
        this.id = uuid()
    }

    static fromJSON(json: any) {
        if (!_.isNil(json["shortcuts"])) {
            const entry = new FrontPageSection("shortcuts")
            entry.shortcutsSection = ShortcutsSection.fromJSON(json["shortcuts"])
            return entry
        } else if (!_.isNil(json["curated_products"])) {
            const entry = new FrontPageSection("curated_products")
            entry.productsSection = ProductsSection.fromJSON(json["curated_products"])
            return entry
        } else if (!_.isNil(json["most_sold_products"])) {
            const entry = new FrontPageSection("most_sold_products")
            entry.mostSoldProducts = MostSoldProductsSection.fromJSON(json["most_sold_products"])
            return entry
        } else {
            throw new Error("Error in front page section data")
        }
    }

    static withType(type: FrontPageSectionType): FrontPageSection {
        const section = new FrontPageSection(type)
        switch (type) {
            case "shortcuts":
                section.shortcutsSection = new ShortcutsSection(new L10nString({ en: "Shortcuts", da: "Genveje" }), [])
                break
            case "curated_products":
                section.productsSection = new ProductsSection(new L10nString({ en: "Popular products", da: "Populære varer" }), [])
                break
            case "most_sold_products":
                section.mostSoldProducts = new MostSoldProductsSection(new L10nString({ en: "Most sold products", da: "Mest solgte varer" }), 8, 14)
                break
        }
        return section
    }

    toJSON(): any {
        switch (this.type) {
            case "shortcuts": {
                return { [this.type]: this.shortcutsSection!.toJSON() }
            }
            case "most_sold_products": {
                return { [this.type]: this.mostSoldProducts!.toJSON() }
            }
            case "curated_products": {
                return { [this.type]: this.productsSection!.toJSON() }
            }
        }
    }
}

export class MostSoldProductsSection {
    numberOfProducts: number
    timeIntervalDays: number
    title: L10nString

    constructor(title: L10nString, numberOfProducts: number, timeIntervalDays: number) {
        this.title = title
        this.numberOfProducts = numberOfProducts
        this.timeIntervalDays = timeIntervalDays
    }

    static fromJSON(json: any) {

        if (_.isNil(json.number_of_products)) {
            throw new Error("Missing key: 'number_of_products'")
        }
        if (!_.isNumber(json.number_of_products)) {
            throw new Error("Key: 'number_of_products' expected to be a number")
        }

        if (_.isNil(json.time_interval_days)) {
            throw new Error("Missing key: 'time_interval_days'")
        }
        if (!_.isNumber(json.time_interval_days)) {
            throw new Error("Key: 'time_interval_days' expected to be a number")
        }
        const entry = new MostSoldProductsSection(new L10nString(json.title), json.number_of_products, json.time_interval_days)

        return entry
    }

    toJSON(): any {
        return {
            title: this.title.json(),
            number_of_products: this.numberOfProducts,
            time_interval_days: this.timeIntervalDays
        }
    }
}

export class ProductEntry {
    id: string
    uuid: string

    constructor(id: string) {
        this.id = id
        this.uuid = uuid()
    }

    static fromJSON(json: any) {
        if (_.isNil(json.id)) {
            throw new Error("Missing key: 'id'")
        }
        if (!_.isString(json.id)) {
            throw new Error("Key: 'id' expected to be a string")
        }
        const entry = new ProductEntry(json.id)
        return entry
    }

    toJSON(): any {
        return {
            id: this.id
        }
    }
}

export class ProductsSection {
    products: ProductEntry[]
    title: L10nString

    constructor(title: L10nString, products: ProductEntry[]) {
        this.title = title
        this.products = products
    }

    static fromJSON(json: any) {
        const products: ProductEntry[] = []
        for (const productJson of json.products ?? []) {
            const product = ProductEntry.fromJSON(productJson)
            products.push(product)
        }
        const entry = new ProductsSection(new L10nString(json.title), products)
        return entry
    }

    toJSON(): any {
        return {
            title: this.title.json(),
            products: this.products.map(product => { return product.toJSON() })
        }
    }
}

export class Shortcut {
    shortcutItem: ShortcutItem
    id: string
    label?: L10nString
    parameters?: _.Dictionary<any>

    title(language: LanguageCode | null): string {
        if (!_.isNil(this.label)) {
            return `${this.label.localized(language)} (${shortcutName(this.shortcutItem)})`
        } else {
            return shortcutName(this.shortcutItem)
        }
    }

    constructor(shortcutItem: ShortcutItem) {
        this.shortcutItem = shortcutItem
        this.id = uuid()
    }

    static fromJSON(json: any) {
        if (_.isNil(json.shortcut_item)) {
            throw new Error("Missing key: 'shortcut_item'")
        }
        if (!_.isString(json.shortcut_item)) {
            throw new Error("Key: 'shortcut_item' expected to be a string")
        }
        let item = json.shortcut_item
        if (item === "reserve_and_collect" || item === "click_and_collect") {
            item = "orders"
        }
        const entry = new Shortcut(item as ShortcutItem)
        if (!_.isNil(json.label)) {
            entry.label = new L10nString(json.label)
        }
        if (!_.isNil(json.parameters)) {
            entry.parameters = json.parameters
        }
        return entry
    }

    toJSON(): any {
        const value: any = {
            shortcut_item: this.shortcutItem
        }
        if (!_.isNil(this.label)) {
            value.label = this.label?.json()
        }
        if (!_.isNil(this.parameters)) {
            value.parameters = this.parameters
        }
        return value
    }
}

export class ShortcutsSection {
    shortcuts: Shortcut[]
    title: L10nString

    constructor(title: L10nString, shortcuts: Shortcut[]) {
        this.title = title
        this.shortcuts = shortcuts
    }

    static fromJSON(json: any) {
        const shortcuts: Shortcut[] = []
        const jsonShortcuts = json.shortcuts ?? []
        if (!_.isArray(jsonShortcuts)) {

            throw new Error("Expecting a JSON array")
        }
        for (const shortcutJson of jsonShortcuts) {
            const shortcut = Shortcut.fromJSON(shortcutJson)
            shortcuts.push(shortcut)
        }
        const entry = new ShortcutsSection(new L10nString(json.title), shortcuts)
        return entry
    }

    toJSON(): any {
        return {
            title: this.title.json(),
            shortcuts: this.shortcuts.map(shortcut => { return shortcut.toJSON() })
        }
    }
}
