import React, { FC, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { compileTemplate } from '../EmailTemplateEditor/compileTemplate';
import { EmailTemplate } from 'src/models/email_template';

interface ComposeEmailProps {
    emailTemplate: EmailTemplate
}

export const ComposeEmail: FC<ComposeEmailProps> = (props) => {
  return (
    <Container style={styles.container}>
      <Form>
        <Form.Group as={Row} className="mb-3">
          <Form.Label style={styles.label} column md="2">
            To:
          </Form.Label>
          <Col md="10">
            <Form.Control
              type="email"
              disabled={true}
              name="to"
              value={'email@template.com'}
              placeholder="Recipient's email"
              style={styles.input}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label style={styles.label} column md="2">
            Subject:
          </Form.Label>
          <Col md="10">
            <Form.Control
              type="text"
              name="subject"
              disabled={true}
              value={'Awesome email template!'}
              placeholder="Subject"
              style={styles.input}
            />
          </Col>
        </Form.Group>
        <br></br>
        <Form.Group as={Row} className="mb-3">
            <div dangerouslySetInnerHTML={{ __html: compileTemplate(props.emailTemplate, "wallet.com").html }} />
        </Form.Group>
      </Form>
    </Container>
  );
};

const styles = {
  container: {
    minWidth: "600px"
  },
  input: {
    borderBottom: '1px solid #ccc',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderRadius: '0',
    fontSize: "small",
    opacity: "60%",
    backgroundColor: "white"
  },
  label: {
    fontSize: "small",
    opacity: "60%"
  },
};
