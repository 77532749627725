import * as _ from "lodash"
import * as React from "react"
import firebase from "firebase/compat/app"
import {
    Button,
    ButtonToolbar,
    FormControl,
    Modal,
    Pager,
    Card,
    ToggleButton,
    ToggleButtonGroup,
    InputGroup
} from "../wrappers"
import {
    FormattedDate,
    FormattedRelativeTime,
    FormattedTime
} from "react-intl"
import { PageState } from "../PageState"
import { StripedTable } from "../StripedTable"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ConfirmDeleteButton } from "../ConfirmDeleteButton"
import { firestore, ref } from "../../config/constants"
import { RoleRouterProps, withRoleRouter } from "src/routes"
import { Pagination, Spinner } from "react-bootstrap"
import { token } from "src/helpers/auth"

const FileDownload = require("js-file-download")

interface GiftcardListState {
    giftcards: any[]
    lastGiftcardId?: string
    firstGiftcardId?: string
    loaded: boolean
    lastPage?: boolean
    firstPage?: boolean
    errorMessage?: string,
    status?: string
    showConfig?: boolean
    exportRecipients?: string[]
    exportStatus?: string
    exportEnabled?: boolean
    exportLoading?: boolean
    exportEditing?: boolean
    exportRecipientEditIndex?: number
    allowGiftcardConsume: boolean
    allowGiftcardDownload: boolean
    authToken?: string
    searchTerm?: string
    searching: boolean
}

const limit = 25

interface ConfirmButtonProps {
    title: string
    message: string
    onAction(): Promise<void>
}

export class ConfirmButton extends React.Component<ConfirmButtonProps, {}> {
    onClick() {
        if (window.confirm(this.props.message) === true) {
            this.props.onAction()
        }
    }

    render() {
        return <Button variant="danger" onClick={(event) => { this.onClick(); event.stopPropagation() }}>{this.props.title}</Button>
    }
}

class GiftcardList extends React.Component<RoleRouterProps, GiftcardListState> {
    constructor(props: RoleRouterProps) {
        super(props)

        this.state = {
            giftcards: [],
            lastGiftcardId: undefined,
            loaded: false,
            status: "all",
            allowGiftcardConsume: false,
            allowGiftcardDownload: false,
            searching: false,
        }
    }

    async loadConfiguration() {
        const configSnap = await ref().child(`v1/accounts/${this.props.role.account_id}/configuration/giftcard_service_config`).get()
        const t = await token() ?? undefined
        const allowConsume = configSnap.val()?.allow_giftcard_consume ?? false
        const allowDownload = configSnap.val()?.allow_giftcard_download ?? false
        this.setState({ allowGiftcardConsume: allowConsume, allowGiftcardDownload: allowDownload, authToken: t })
    }

    async componentDidMount() {
        await this.loadConfiguration()
        await this.loadGiftcards(this.earlierThan)
    }

    // Helpers

    private set earlierThan(key: string | undefined) {
        const params = new URLSearchParams(this.props.router.location.search)
        if (params.get("earlierThan") === key) {
            return
        }
        if (key) {
            params.set("earlierThan", key)
        } else {
            params.delete("earlierThan")
        }

        this.props.router.navigate(`?${params.toString()}`)
    }

    private get earlierThan(): string | undefined {
        const params = new URLSearchParams(this.props.router.location.search)
        const key = params.get("earlierThan")

        return key === null ? undefined : key
    }

    loadNext = async () => {
        await this.loadGiftcards(this.state.lastGiftcardId)
    }

    loadPrevious = async () => {
        await this.loadGiftcards(undefined, this.state.firstGiftcardId)
    }

    triggerExport = async () => {
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            trigger_export: true
        }

        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
    }

    performExport = async () => {
        const status = this.state.status ?? "all"
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            export: true,
            status: status
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        FileDownload(result.data, `${status}.csv`)
    }

    enableExport = async () => {
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            enable: true,
            recipients: this.state.exportRecipients ?? [],
            status: this.state.exportStatus ?? "all"
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        this.setState({ exportEnabled: true, exportEditing: undefined, exportLoading: undefined, exportRecipients: result.data.recipients, exportStatus: result.data.export_status })
    }

    disableExport = async () => {
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id,
            disable: true
        }

        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
        this.setState({ exportEnabled: false, exportLoading: undefined, exportRecipients: undefined, exportStatus: undefined })
    }

    loadConfig = async () => {
        this.setState({ exportLoading: true })
        const args: any = {
            action: "giftcard-export",
            account: this.props.role.account_id
        }

        const client = firebase.functions().httpsCallable("clientApi")
        try {
            const result = await client(args)
            this.setState({ exportEnabled: true, exportLoading: undefined, exportRecipients: result.data.recipients, exportStatus: result.data.export_status, exportRecipientEditIndex: undefined })
        } catch {
            this.setState({ exportEnabled: false, exportLoading: undefined, exportRecipients: undefined, exportStatus: undefined, exportRecipientEditIndex: undefined })
        }
    }

    checkoutsRef() {
        const account = this.props.role.account_id
        return firestore.collection(`accounts/${account}/checkouts`)
    }

    activateGiftcard = async (code: string) => {
        const args: any = {
            action: "giftcard-activate",
            account: this.props.role.account_id,
            id: code
        }
        const client = firebase.functions().httpsCallable("clientApi")
        await client(args)
        await this.loadGiftcards(this.earlierThan)
    }

    readGiftcard = async (code: string) => {
        const args: any = {
            action: "giftcard-read",
            account: this.props.role.account_id,
            code: code
        } 
        this.setState({searching: true})
        const client = firebase.functions().httpsCallable("clientApi")
        try {
            const giftcard = await client(args)
            this.setState({ giftcards: [giftcard.data] })
            
        } catch {
            this.setState({ giftcards: [] })
        }
    }
    

    consumeGiftcard = async (code: string, amount: number) => {
        const args: any = {
            action: "giftcard-pay",
            account: this.props.role.account_id,
            id: code,
            amount: amount
        }
        const client = firebase.functions().httpsCallable("clientApi")
        await client(args) 
        await this.loadGiftcards(this.earlierThan)
    }

    loadGiftcards = async (earlierThan?: string, laterThan?: string) => {
        this.setState({ loaded: false })
        const args: any = {
            action: "giftcard-list",
            account: this.props.role.account_id,
            limit: limit
        }

        let firstPage = false
        if (!_.isNil(earlierThan)) {
            args.before = earlierThan
        } else if (!_.isNil(laterThan)) {
            args.after = laterThan
        } else {
            firstPage = true
        }

        if (!_.isNil(this.state.status) && this.state.status !== "all") {
            args.status = this.state.status
        }

        const client = firebase.functions().httpsCallable("clientApi")
        const result = await client(args)
        console.log(result)
        const giftcards: any[] = result.data
        let lastGiftcardId: string | undefined = undefined
        let firstGiftcardId: string | undefined = undefined
        if (giftcards.length > 0) {
            firstGiftcardId = giftcards[0].id
            if (!firstPage) {
                this.earlierThan = firstGiftcardId
            }
            lastGiftcardId = giftcards[giftcards.length - 1].id

            // Find all giftcards with 'created' state and check if we are allowed to reactivate.
            // We are allowed to reactivate if we have a non-voided sale including the giftcard
            for (const giftcard of giftcards) {
                if (giftcard.status !== "created") {
                    continue
                }
                const checkoutSnap = await this.checkoutsRef().where("index.giftcard_codes", "array-contains", giftcard.code).get()
                if (checkoutSnap.empty) {
                    continue
                }
                let nonVoidCheckout = false
                for (const doc of checkoutSnap.docs) {
                    if (doc.data().voided === false) {
                        nonVoidCheckout = true
                    }
                }
                if (nonVoidCheckout) {
                    giftcard.allowActivation = true
                }

            }
        } else {
            this.earlierThan = undefined
        }
        this.setState({ loaded: true, firstPage: firstPage, giftcards: result.data, firstGiftcardId: firstGiftcardId, lastGiftcardId: lastGiftcardId, lastPage: giftcards.length < limit })
    }

    setFilter(status: string) {
        this.setState({ status: status }, () => {
            this.loadGiftcards(undefined)
        })
    }

    renderPager() {
        return <Pagination>
            <Pagination.Prev disabled={!this.state.loaded || this.state.firstPage} onClick={async () => await this.loadPrevious()}>&larr; Newer</Pagination.Prev>
            <Pagination.Item active={this.state.status === "all"} onClick={() => { this.setFilter("all") }}>All</Pagination.Item>
            <Pagination.Item active={this.state.status === "activated"} onClick={() => { this.setFilter("activated") }}>Active</Pagination.Item>
            <Pagination.Item active={this.state.status === "used"} onClick={() => { this.setFilter("used") }}>Used</Pagination.Item>
            <Pagination.Next disabled={!this.state.loaded || this.state.lastPage} onClick={async () => { this.loadNext() }}>Older &rarr;</Pagination.Next>
        </Pagination>
    }

    setExportFilter(status: string) {
        this.setState({ exportStatus: status })
    }

    get editedRecipientValue() {
        if (_.isNil(this.state.exportRecipientEditIndex)) {
            return ""
        }
        if (_.isNil(this.state.exportRecipients)) {
            return ""
        }
        if (this.state.exportRecipients.length <= this.state.exportRecipientEditIndex) {
            return ""
        }
        return this.state.exportRecipients[this.state.exportRecipientEditIndex]
    }

    updateEditedRecipient(value?: string) {
        if (_.isNil(this.state.exportRecipientEditIndex)) {
            return
        }
        if (_.isNil(this.state.exportRecipients)) {
            return
        }
        if (this.state.exportRecipients.length <= this.state.exportRecipientEditIndex) {
            return
        }
        const clone = _.cloneDeep(this.state.exportRecipients)
        clone[this.state.exportRecipientEditIndex] = value ?? ""
        this.setState({ exportRecipients: clone })
    }

    removeRecipient(index: number) {
        if (_.isNil(this.state.exportRecipients)) {
            return
        }
        if (this.state.exportRecipients.length <= index) {
            return
        }

        const clone = _.cloneDeep(this.state.exportRecipients)
        clone.splice(index, 1)
        this.setState({ exportRecipients: clone })
    }
    addRecipient() {
        const clone = _.cloneDeep(this.state.exportRecipients ?? [])
        clone.push("")
        this.setState({ exportRecipients: clone, exportRecipientEditIndex: clone.length - 1 })
    }

    private handleKeyPress(value: any) {
        if (value.charCode === 13) {
            this.setState({ exportRecipientEditIndex: undefined })
        }
    }

    get submitDisabled() {
        if (this.state.exportRecipients === undefined) {
            return true
        }
        if (this.state.exportRecipients.length === 0) {
            return true
        }
        if (this.state.exportRecipients.length === 1 && this.state.exportRecipients[0] === "") {
            return true
        }
        return false
    }

    renderExportConfigBody() {
        if (this.state.exportEditing) {
            return <span>
                <StripedTable>
                    <thead>
                        <tr><th>Recipients</th></tr>
                    </thead>
                    <tbody>
                        {this.state.exportRecipients && this.state.exportRecipients.map((r, index) => {
                            return <tr key={index}>
                                <td onClick={() => {
                                    if (this.state.exportRecipientEditIndex !== index) {
                                        this.setState({ exportRecipientEditIndex: index })
                                    }
                                }}
                                >
                                    {this.state.exportRecipientEditIndex === index ? <FormControl
                                        type="text"
                                        value={this.editedRecipientValue}
                                        placeholder="Enter email address"
                                        onChange={(e: any) => { this.updateEditedRecipient(e.target.value) }}
                                        onKeyPress={(value) => { this.handleKeyPress(value) }}
                                    /> : <span>{r}</span>}
                                </td>
                                <td className="narrow">
                                    <ConfirmDeleteButton
                                        message={`Remove recipient: ${r}`}
                                        onDelete={async () => { this.removeRecipient(index) }}
                                    />
                                </td>
                            </tr>
                        })}
                    </tbody>
                </StripedTable>
                <Button onClick={async () => { this.addRecipient() }}>Add recipient</Button>

                <span>
                    <br /><br />
                    <div>
                        Which giftcards do you wish to export?
                    </div>
                    <br />
                    <ToggleButtonGroup type="radio" name="options" value={this.state.exportStatus ?? "all"} onChange={async (value) => { this.setExportFilter(value) }}>
                        <ToggleButton id="all" value="all">All</ToggleButton>
                        <ToggleButton id="activated" value="activated">Active</ToggleButton>
                        <ToggleButton id="used" value="used">Used</ToggleButton>
                    </ToggleButtonGroup>
                    <br /><br />
                    <Button variant="danger" disabled={this.submitDisabled} onClick={async () => await this.enableExport()}>Submit</Button>
                </span>
                <br />
            </span>
        } else if (this.state.exportEnabled) {
            return <span>
                <div>
                    A daily export of <b>{this.state.exportStatus}</b> gift cards is currently configured to be received by:
                </div>

                <StripedTable>
                    <thead>
                        <tr><th>Recipients</th></tr>
                    </thead>
                    <tbody>
                        {this.state.exportRecipients && this.state.exportRecipients.map((r, index) => {
                            return <tr key={index}>
                                <td>{r}</td>
                            </tr>
                        })}
                    </tbody>
                </StripedTable>

                <ButtonToolbar>
                    <Button onClick={async () => await this.setState({ exportEditing: true })}>Edit configuration</Button>
                    <Button onClick={async () => await this.triggerExport()}>Trigger export</Button>
                    <Button variant="danger" onClick={async () => await this.disableExport()}>Disable export</Button>
                </ButtonToolbar>
            </span>
        } else {
            return <span>
                Export not enabled
                <Button onClick={async () => await this.setState({ exportEditing: true, exportRecipients: [""], exportRecipientEditIndex: 0 })}>Enable export</Button>
            </span>
        }
    }

    renderModal() {
        return (
            <Modal show={this.state.showConfig} onHide={() => { /* */ }}>
                <Modal.Header>Gift card export configuration</Modal.Header>
                <Modal.Body>
                    {this.state.exportLoading ? <span>Loading...</span> : this.renderExportConfigBody()}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => { this.hideConfig() }}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    async hideConfig() {
        this.setState({ showConfig: undefined, exportEnabled: undefined, exportRecipients: undefined, exportStatus: undefined })
    }

    async showConfig() {
        this.setState({ showConfig: true })
        await this.loadConfig()
    }
    
    private handleSearchTermChange = (event: any) => {
        const value: string = event.target.value

        this.setState({ searchTerm: value })
    }
    private handleSearchKeyPress(value: any) {
        if (value.charCode === 13) {
            
            this.readGiftcard(this.state.searchTerm ?? "")
        }
    }
    private handleCloseSearch() {
        this.loadGiftcards()
        this.setState({ searchTerm: "", searching: false})

    }

    private get showSearchDetails(): boolean {
        return this.state.searching
    }

    renderSearchField() {
        return <InputGroup>
        <FormControl
            type="text"
            name="search_term"
            value={this.state.searchTerm ?? ""}
            placeholder={`Look up a gift card by gift card code`}
            onChange={(event: any) => { this.handleSearchTermChange(event) }}
            onKeyPress={ value => { this.handleSearchKeyPress(value) }}
        />

        <Button variant="primary" onClick={() => { this.readGiftcard(this.state.searchTerm ?? "") }} >Search</Button>
        { this.showSearchDetails &&
            <Button variant="danger" onClick={() => { this.handleCloseSearch() }}>Close</Button>
        }

    </InputGroup>
    }

    render() {

        return (
            <PageState loading={false} typeName="giftcards">
                <Button style={{ marginLeft: "10px" }} className="float-sm-end" onClick={() => { 
                    let path = `/configuration/gift_card_types`
                    this.props.router.navigate(path) 
                }}>Gift card configuration</Button>
                {this.state.loaded && <Button style={{ marginLeft: "10px" }} className="float-sm-end" onClick={async () => this.performExport()}>Export {this.state.status ?? "all"} gift cards</Button>}
                <Button className="float-sm-end" onClick={() => { this.showConfig() }}><FontAwesomeIcon icon={faCog} /></Button><br />
                {this.renderModal()}
                <br></br>
                {this.renderSearchField()}
                <br></br>
                {this.renderPager()}    
                <Card className="my-4">
                    <Card.Header>Gift cards</Card.Header>
                    <Card.Body>
                        {this.state.loaded ?
                            <StripedTable>
                                <thead>
                                    <tr>
                                        <th>Date/Time</th>
                                        <th>Type</th>
                                        <th>Code</th>
                                        <th>Status</th>
                                        <th>Sent to</th>
                                        <th>Used date</th>
                                        <th style={{ textAlign: "right" }}>Amount</th>
                                        {
                                            this.state.allowGiftcardDownload &&
                                            <th>Download</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.giftcards.map(listValue => {
                                        return (
                                            <tr style={{ color: listValue.voided ? "#cccccc" : "black" }} key={listValue.id} >
                                                <td>
                                                    <FormattedTime
                                                        value={new Date(listValue.create_date * 1000)}
                                                        day="numeric"
                                                        month="long"
                                                        year="numeric"
                                                    /><br />
                                                    <i style={{ fontSize: "smaller" }}>(expires&nbsp;
                                                        <FormattedDate
                                                            value={new Date(listValue.expiry_date * 1000)}
                                                            day="numeric"
                                                            month="long"
                                                            year="numeric"
                                                        />)</i>
                                                </td>
                                                <td>{listValue.type === "voucher" ? "Voucher" : "Gift card"}</td>
                                                <td><a href={`/sales?giftcard_code=${listValue.code}`}>{listValue.code}</a></td>
                                                <td>
                                                    {listValue.status}&nbsp;
                                                    {listValue.status === "created" && listValue.allowActivation === true && <Button onClick={async () => { this.activateGiftcard(listValue.code) }}>Activate</Button>}
                                                    {listValue.status === "activated" && this.state.allowGiftcardConsume && <ConfirmButton title="Consume" message="Are you certain that you want to mark this gift card as consumed? The action cannot be undone." onAction={async () => { this.consumeGiftcard(listValue.code, listValue.amount) }} />}
                                                </td>
                                                <td>{listValue.email}</td>
                                                <td>
                                                    {!_.isNil(listValue.used_date) && <span>
                                                        <FormattedTime
                                                            value={new Date(listValue.used_date * 1000)}
                                                            day="numeric"
                                                            month="long"
                                                            year="numeric"
                                                            hour="numeric"
                                                            minute="numeric"
                                                        /><br /><i style={{ fontSize: "smaller" }}>
                                                            {/* (<FormattedRelative value={new Date(listValue.used_date * 1000)} />) */}
                                                        </i></span>}
                                                </td>
                                                <td style={{ textAlign: "right" }}>
                                                    {`${listValue.amount} ${listValue.currency_code}`}
                                                    {!_.isNil(listValue.tax_type) && <i style={{ fontSize: "smaller" }}><br />(VAT incl.)</i>}
                                                </td>
                                                {
                                                    this.state.allowGiftcardDownload &&
                                                    <td>
                                                        <AuthenticatedLink code={listValue.code} account={this.props.role.account_id} authToken={this.state.authToken} />
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </StripedTable>
                            : <div>Loading...</div>}
                    </Card.Body>
                </Card >
                {this.renderPager()}
            </PageState >
        )
    }
}

export function AuthenticatedLink({ code, account, authToken, children }: any) {
    const link = React.useMemo(() => { return React.createRef<HTMLAnchorElement>() }, [])
    const [downloading, setDownloading] = React.useState(false)

    const downloadPDF = React.useCallback(async () => {
        const baseUrl = `${process.env.REACT_APP_FIREBASE_HTTP_FUNCTIONS_BASE}/app/giftcard_pdf/${account}`
        const url = `${baseUrl}/${code}`
        if (_.isNil(link.current)) {
            return
        }
        if (link.current.href !== "") {
            link.current.click()
            return
        }
        setDownloading(true)

        const result = await fetch(url, {
            headers: {
                "Authorization": "Bearer " + (authToken ?? "-")
            }
        })

        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)

        link.current.download = `${code}.pdf`
        link.current.href = href

        link.current.click()
        setDownloading(false)
    }, [code, account, authToken])

    return (
        <>
            {<Button disabled={downloading} onClick={() => { downloadPDF() }}>
                {downloading ? <span><Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                /></span>
                    : <span>Download</span>
                }
            </Button>}
            <a ref={link} />
        </>
    )
}


export default withRoleRouter(GiftcardList)
