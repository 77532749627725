import React, { useState } from "react";
import firebase from "firebase/compat/app"
import { Button } from "../wrappers";


enum SendState {
    Send, Sent, Sending, Error
}

async function sendLoyaltyCardEmail(customerId: string, account: string) {
    const args: any = {
        action: "loyalty-card-email",
        account: account,
        customer_id: customerId
    };

    const client = firebase.functions().httpsCallable("clientApi");
    return await client(args);
}

interface EmailSendingProps {
    customerId: string;
    account: string;
}

export function SendEmailButton(props: EmailSendingProps) {
    const [sendState, setSendState] = useState<SendState>(SendState.Send);
    return <Button variant={buttonColor(sendState)} onClick={async (event) => {
        event.stopPropagation();

        setSendState(SendState.Sending);
        try {
            await sendLoyaltyCardEmail(props.customerId, props.account)
            setSendState(SendState.Sent);
        } catch {
            setSendState(SendState.Error)
        }
    }}> {emailText(sendState)}  </Button>;
}

const emailText = (state: SendState): string => {
    switch (state) {
        case SendState.Send:
            return "Send loyalty card";
        case SendState.Sending:
            return "Sending...";
        case SendState.Sent:
            return "Loyalty card sent";
        case SendState.Error:
            return "Error sending";
    }
};

const buttonColor = (state: SendState): string => {
    switch (state) {
        case SendState.Send:
            return "primary";
        case SendState.Sending:
            return "info";
        case SendState.Sent:
            return "success";
        case SendState.Error:
            return "danger";
    }
};
