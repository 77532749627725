import * as _ from "lodash"
import * as React from "react"
import ImageDropControl from "../ImageDropControl"
import { AttributeSelection } from "./AttributeSelection"
import {
    Button,
    Col,

    FormControl,
    FormControlStatic,
    // FormGroup,
    HelpBlock,
    Modal,
    // Row
} from "../wrappers"
import { Row, FormGroup, Stack, Card, Form } from "react-bootstrap"
import { L10nFormControl } from "../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import { Market } from "../../models/MarketModels"
import { MarketAmount } from "../../models/MarketAmount"
import { MarketAmountFormControl } from "../MarketAmountFormControl"
import {
    Product,
    Variant
} from "../../models/Product"
import { Role } from "../../config/role"
import { v4 as uuidv4 } from "uuid"
import { MarketPicker } from "../MarketPicker"
import { LabelledControl } from "./ProductEdit"

const pushid = require("pushid")

interface VariantEditProps {
    role: Role
    editedVariant: Variant | null
    product: () => Product
    setLanguage: (language: any) => any
    currentLanguage: () => any
    resolveLanguages: () => LanguageCode[]
    handleCurrentMarketChange(market: Market | null): void
    currentMarket: Market | null
    markets: string[]
    currency: string,
    cancelDialog: () => any
    publishDialog: (variant: Variant) => void
    addMarket: (market: string) => void
    removeMarket: (market: string) => void
    isProductRepo: () => boolean
}

interface VariantEditState {
    validId: boolean
    variant: Variant
    isNew: boolean
    dirty: boolean
}

export class VariantEdit extends React.Component<VariantEditProps, VariantEditState> {

    constructor(props: VariantEditProps) {
        super(props)
        this.state = {
            validId: true,
            variant: this.props.editedVariant || new Variant({ id: uuidv4().toUpperCase() }),
            isNew: _.isNil(this.props.editedVariant),
            dirty: false
        }
    }
    cancel = () => {
        //TODO: remove uploaded image if needed
        this.props.cancelDialog()
    }

    publish = () => {
        this.props.publishDialog(this.state.variant)
    }

    product = (): Product => {
        return this.props.product()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.props.setLanguage(language)
    }

    currentLanguage = () => {
        return this.props.currentLanguage()
    }

    handleVariantInputChange = (event: any) => {
        this.editVariant(variant => {
            const target = event.target
            let value = target.value
            const name = target.name

            if (name !== "id" && value === "") {
                delete variant[name]
            } else {

                if (target.type === "number") {
                    value = Number(value)
                }

                variant[name] = value
            }
        })
    }

    resolveLanguages() {
        return this.props.resolveLanguages()
    }

    // Helper methods

    onVariantLocalizationChanged = (name: string, l10n: L10nString | null) => {
        this.editVariant(variant => {
            if (!l10n || l10n.value === "") {
                // NOTE: All variant L10nString fields are optional
                // so it's ok to delete them
                delete variant[name]
            } else {
                variant[name] = l10n
            }
        })
    }

    onVariantAmountChanged = (property: string, amount: MarketAmount | null) => {
        this.editVariant(variant => {
            if (amount === null) {
                delete variant[property]
            } else {
                variant[property] = amount
            }
        })
    }

    onVariantDimensionValueChanged = (event: any) => {
        this.editVariant(variant => {
            const target = event.target
            const value = target.value
            const name = target.name

            const dimensionValues = variant.dimension_values || {}
            if (value === "undefined" || value === "") {
                delete dimensionValues[name]
            } else {
                dimensionValues[name] = value
            }
            variant.dimension_values = dimensionValues
        })
    }

    setVariantImage = (url: string | null) => {
        this.editVariant(variant => {
            variant.image_url = url || undefined
        })
    }

    generatedIdentifier(input: string): string {
        let trimmedInput = input.trim()
        if (!trimmedInput) {
            return ""
        }
        const invalidCharacters = [".", "[", "]", "/", "#", "$"]
        for (const character of invalidCharacters) {
            trimmedInput = trimmedInput.replace(new RegExp(`\\${character}`, "g"), "_")
        }
        return trimmedInput
    }

    isValidIdentifier(identifier: string): boolean {
        const generatedId = this.generatedIdentifier(identifier)
        const index = _.find((this.product().variants || []), variant => {
            return variant.id === generatedId
        })
        return index === undefined && generatedId !== ""
    }

    editVariant = (closure: ((variant: Variant) => any | null | undefined)) => {
        const variant = _.cloneDeep(this.state.variant)

        closure(variant)

        this.setState({ variant: variant, dirty: true })
    }

    handleAttributesChange = (attributes: _.Dictionary<any>) => {
        this.editVariant(variant => { variant.attributes = attributes })
    }

    isPublishEnabled = () => {
        if (this.state.dirty === false) {
            return false
        }

        if ((this.product().dimensions || []).length > 0) {
            const dimensionValues = this.state.variant.dimension_values
            if (Object.keys(dimensionValues || {}).length !== (this.product().dimensions || []).length) {
                return false
            }
        }

        return this.state.validId
    }

    // React component

    render() {
        return (
            <Modal show={true} size="lg">
                <Modal.Header>
                    <Modal.Title>Edit Variant</Modal.Title>

                    <Stack direction="horizontal" className="pb-2" gap={2}>
                        <div className="ms-auto" />
                        {this.props.isProductRepo() &&
                            <MarketPicker
                                role={this.props.role}
                                currentMarket={this.props.currentMarket}
                                resolveMarkets={() => { return this.props.markets }}
                                typeName="product"
                                onMarketSelect={market => { this.props.handleCurrentMarketChange(market) }}
                                addMarket={this.props.addMarket}
                                removeMarket={this.props.removeMarket}
                            />
                        }
                        <LanguagePicker
                            typeName="product"
                            initialLanguage={this.currentLanguage()}
                            resolveLanguages={() => { return this.resolveLanguages() }}
                            onChange={this.setLanguage}
                            hideRemove={true}
                        />
                    </Stack>

                </Modal.Header>

                <Modal.Body>
                    <Form onSubmit={e => e.preventDefault()}>

                        <Card className="mb-4">
                            <Card.Body>

                                <LabelledControl label="Id">
                                    {this.state.isNew ? <>
                                        <Form.Control
                                            key="a"
                                            type="text"
                                            isValid={this.state.validId}
                                            name="id"
                                            value={this.state.variant.id}
                                            placeholder={`Enter variant identifier`}
                                            onChange={(event: any) => {
                                                const generatedId = this.generatedIdentifier(event.target.value)
                                                this.setState({ validId: this.isValidIdentifier(generatedId) })
                                                const newEvent = _.cloneDeep(event)
                                                newEvent.target.value = generatedId
                                                this.handleVariantInputChange(newEvent)
                                            }}
                                            autoComplete="off"
                                        />
                                        {!this.state.validId && <HelpBlock key="b">Identifier is not valid</HelpBlock>}
                                    </>
                                        : <FormControlStatic>{this.state.variant.id}</FormControlStatic>
                                    }

                                </LabelledControl>

                                <LabelledControl label="Name">
                                    <L10nFormControl
                                        l10n={this.state.variant.name || null}
                                        placeholder="Enter a name if you wish for this variant to override the name of the product"
                                        language={this.currentLanguage()}
                                        onLocalizationChanged={l10n => {
                                            this.onVariantLocalizationChanged("name", l10n)
                                        }}
                                    />
                                </LabelledControl>

                                <LabelledControl label="Image (max size:  2048×2048)">
                                    <ImageDropControl
                                        fileName={pushid()}
                                        filePath={this.props.role.account_id + "/public/products/" + this.product().id + "/images/"}
                                        imageURL={this.state.variant.image_url}
                                        dropTitle=""
                                        validateSize={(width: number, height: number) => {
                                            return width <= 2048 && height <= 2048
                                        }}
                                        isPublic={true}
                                        deleteAction={() => { this.setVariantImage(null) }}
                                        uploadAction={(url) => { this.setVariantImage(url) }}
                                    />
                                </LabelledControl>


                                <LabelledControl label="Bar code">
                                    <FormControl
                                        type="text"
                                        name="barcode"
                                        value={this.state.variant.barcode || ""}
                                        placeholder="Enter bar code"
                                        onChange={this.handleVariantInputChange}
                                        autoComplete="off"
                                    />
                                </LabelledControl>

                            </Card.Body>
                        </Card>

                        <Card className="mb-4">
                            <Card.Body>
                                <Card.Title>Pricing</Card.Title>

                                <LabelledControl label="Retail price">
                                    <MarketAmountFormControl
                                        amount={this.state.variant.retail_price || null}
                                        market={this.props.currentMarket}
                                        allMarkets={this.props.markets}
                                        onAmountChanged={amount => {
                                            this.onVariantAmountChanged("retail_price", amount)
                                        }}
                                        placeholder="Enter Retail Price"
                                        placeholderAmount={this.product().retail_price}
                                        currency={this.props.currency}
                                    />
                                </LabelledControl>

                                <LabelledControl label="Sale price">
                                    <MarketAmountFormControl
                                        amount={this.state.variant.sale_price || null}
                                        allMarkets={this.props.markets}
                                        market={this.props.currentMarket}
                                        onAmountChanged={amount => {
                                            this.onVariantAmountChanged("sale_price", amount)
                                        }}
                                        placeholder="Enter Sale Price"
                                        placeholderAmount={this.product().sale_price}
                                        currency={this.props.currency}
                                    />
                                </LabelledControl>

                                <LabelledControl label="Cost price">
                                    <MarketAmountFormControl
                                        amount={this.state.variant.cost_price || null}
                                        market={this.props.currentMarket}
                                        allMarkets={this.props.markets}
                                        onAmountChanged={amount => {
                                            this.onVariantAmountChanged("cost_price", amount)
                                        }}
                                        placeholder="Enter Cost Price"
                                        placeholderAmount={this.product().cost_price}
                                        currency={this.props.currency}
                                    />
                                </LabelledControl>
                            </Card.Body>
                        </Card>

                        <AttributeSelection
                            attributeType="product"
                            account={this.props.role.account_id}
                            selectedAttributes={this.state.variant.attributes || {}}
                            currentLanguage={this.currentLanguage()}
                            onChange={(attributes) => { this.handleAttributesChange(attributes) }}
                        />

                        {(this.product().dimensions || []).map(dimension => {
                            return (
                                <LabelledControl key={dimension.id} label={dimension.name.localized(this.currentLanguage())}>
                                    <FormControl
                                        name={dimension.id}
                                        as="select"
                                        defaultValue={(this.state.variant.dimension_values || {})[dimension.id]}
                                        onChange={this.onVariantDimensionValueChanged}
                                    >
                                        <option value="undefined">No value selected</option>

                                        {dimension.values.map(value => {
                                            return <option key={value.id} value={value.id}>{value.name.localized(this.currentLanguage())}</option>
                                        })}
                                    </FormControl>
                                </LabelledControl>
                            )
                        })}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={this.cancel}>Cancel</Button>
                    <Button onClick={this.publish} disabled={!this.isPublishEnabled()}>Done</Button>
                </Modal.Footer>

            </Modal>
        )
    }
}