import * as React from "react"

import ReceiptConfiguration from "./ShopConfiguration/ReceiptConfiguration"
import OrderHandlingConfiguration from "./ShopConfiguration/OrderHandlingConfiguration"

import { ref } from "../../config/constants"
import firebase from "firebase/compat"
import { RoleProps, withRole } from "src/routes"
import VippsMobilePayConfiguration from "./ShopConfiguration/VippsMobilePayConfiguration"

interface ShopConfigurationState {
    mobile_pay_module_enabled: boolean
    orders_module_enabled: boolean
}

class ShopConfiguration extends React.Component<RoleProps, ShopConfigurationState> {

    constructor(props: RoleProps) {
        super(props)
        this.state = {
            mobile_pay_module_enabled: false,
            orders_module_enabled: false
        }
    }

    listeningToModulesRef: firebase.database.Reference | null = null
    listeningToOrdersRef: firebase.database.Reference | null = null

    mobilePayEnabledRef(accountId: string) {
        return ref().child(`v1/accounts/${accountId}/configuration/modules/mobile_pay/enabled`)
    }

    ordersEnabledRef(accountId: string) {
        return ref().child(`v1/accounts/${accountId}/configuration/modules/orders/enabled`)
    }

    observeModulesEnabled(accountId: string) {
        if (this.listeningToModulesRef) {
            return
        }
        this.listeningToModulesRef = this.mobilePayEnabledRef(accountId)

        this.listeningToModulesRef.on("value", (snapshot) => {
            if (snapshot && snapshot.exists() && snapshot.val()) {
                this.setState({
                    mobile_pay_module_enabled: true
                })
            } else {
                this.setState({
                    mobile_pay_module_enabled: false
                })
            }
        })
    }

    observeOrdersEnabled(accountId: string) {
        if (this.listeningToOrdersRef) {
            return
        }
        this.listeningToOrdersRef = this.ordersEnabledRef(accountId)

        this.listeningToOrdersRef.on("value", (snapshot) => {
            if (snapshot && snapshot.exists() && snapshot.val()) {
                this.setState({
                    orders_module_enabled: true
                })
            } else {
                this.setState({
                    orders_module_enabled: false
                })
            }
        })
    }

    componentDidMount() {
        this.observeModulesEnabled(this.props.role.account_id)
        this.observeOrdersEnabled(this.props.role.account_id)
    }

    componentWillUnmount() {
        if (this.listeningToModulesRef) {
            this.listeningToModulesRef.off()
            this.listeningToModulesRef = null
        }
    }

    render() {
        return (
            <div>
                <ReceiptConfiguration
                    key="receipt_configuration"
                    role={this.props.role}
                />
                {this.state.mobile_pay_module_enabled ? [
                    <VippsMobilePayConfiguration
                        key="vipps_mobile_pay_configuration"
                        role={this.props.role}
                    />]
                    : null}
                {this.state.orders_module_enabled ?
                    (
                        <OrderHandlingConfiguration
                            role={this.props.role}
                        />
                    )
                    : null}
            </div>
        )
    }
}

export default withRole(ShopConfiguration)