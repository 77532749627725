import * as _ from "lodash"
import firebase from "firebase/compat/app"
import * as JSMapping from "../../../helpers/jsMapValue"
import { buildStockCountLines } from "../../../helpers/StockCountHelpers"
import { ProductCatalogService } from "../../../services/ProductCatalogService"
import { ref } from "../../../config/constants"
import { sortLikeFirebaseOrderByKey } from "../../../helpers/sorting"
import {
    StockCountDevice,
    StockCountLine,
    StockCountLineItem,
    StockCountProductInfo
    } from "../../../models/StockCountModels"
import {
    StockCountFilterType,
    StockCountLinesQueryService
    } from "../../../services/StockCountLinesQueryService"
import { StockCountReportBuilder } from "../../../services/StockCountReportBuilder"
 
const pageLimit = 25

enum StockCountCurrentUpdateType {
    Next,
    Previous,
    ObservationChange
}

class StockCountCurrentViewModelSearch {
    text: string
    constructor(text: string) {
        this.text = text
    }
}

export class StockCountCurrentViewModel {

    // Props

    static get backofficeDeviceId(): string { return "_portal" }

    private accountId: string
    private stockCountQueryservice: StockCountLinesQueryService
    private currentSearch?: StockCountCurrentViewModelSearch
    private devices: StockCountDevice[]
    private filter: StockCountFilterType
    private lineItemIdPagingStack: StockCountLineItem[]
    private nextDisabled: boolean
    private page: number
    private previousDisabled: boolean
    private searchText: string
    private shopId: string
    private stockCountId: string
    private productCatalogService: ProductCatalogService

    // Outputs
    cancelStockCountCompleted: (errorMessage?: string) => void = () => { }
    closeStockCountCompleted: (errorMessage?: string) => void = () => { }
    csvReportCreationCompleted: (report?: string, fileName?: string, errorMessage?: string) => void = () => { }
    devicesChanged:(devices: StockCountDevice[]) => void = () => { }
    nameLoaded: (name: string) => void = () => { }
    searchCompleted: (lines?: StockCountLine[], errorMessage?: string) => void = () => { }
    setUncountedToZeroCompleted: (errorMessage?: string) => void = () => { }
    setDisconnectDeletedCompleted: (errorMessage?: string) => void = () => { }
    stockCountLinesChanged: (lines: StockCountLine[]) => void = () => { }
    countProductsWithZeroStockCompleted:  (errorMessage?: string) => void = () => { }

    // Constructor

    constructor(accountId: string, shopId: string, stockCountId: string) {
        this.accountId = accountId
        this.devices = []
        this.filter = StockCountFilterType.ALL
        this.lineItemIdPagingStack = []
        this.nextDisabled = true
        this.page = 0
        this.previousDisabled = true
        this.searchText = ""
        this.shopId = shopId
        this.stockCountId = stockCountId
        this.productCatalogService = new ProductCatalogService()
        this.stockCountQueryservice = new StockCountLinesQueryService(pageLimit, this.filter, this.accountId, this.shopId, this.stockCountId)
    }

    // Public Methods

    cancelStockCount() {
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "cancel"
        }
        const cancel = firebase.functions().httpsCallable("StockCount-client")
        cancel(args)
            .then((value) => {
                const success = value.data.success
                if (!success) {
                    throw new Error("Could not cancel current stock count")
                }
                if (this.cancelStockCountCompleted) {
                    this.cancelStockCountCompleted()
                }
            })
            .catch((error: Error) => {
                if (this.cancelStockCountCompleted) {
                    this.cancelStockCountCompleted(error.message)
                }
            })
    }

    closeSearch() {
        this.currentSearch = undefined
    }

    closeStockCount() {
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "close"
        }
        const close = firebase.functions().httpsCallable("StockCount-client")
        close(args)
            .then((value) => {
                const success = value.data.success
                if (!success) {
                    throw new Error("Could not close current stock count")
                }

                if (this.closeStockCountCompleted) {
                    this.closeStockCountCompleted()
                }
            })
            .catch((error: Error) => {
                if (this.closeStockCountCompleted) {
                    this.closeStockCountCompleted(error.message)
                }
            })
    }

    createCSVReport(type: StockCountFilterType, progress: (loaded: number, aggregated: number) => void) {
        const builder = new StockCountReportBuilder(this.accountId, this.shopId, this.stockCountId, this.productCatalogService)
        builder.buildStockCountReport(type, progress)
            .then(([report, fileName]) => {
                if (!report) {
                    throw new Error("Internal error")
                }
                if (this.csvReportCreationCompleted) {
                    this.csvReportCreationCompleted(report, fileName, undefined)
                }
            })
            .catch((error) => {
                if (this.csvReportCreationCompleted) {
                    this.csvReportCreationCompleted(undefined, undefined, error.message)
                }
            })
    }

    isNextDisabled(): boolean {
        return this.nextDisabled
    }

    isPreviousDisabled(): boolean {
        return this.previousDisabled
    }

    isSearchButtonDisabled(): boolean {
        return (this.searchText || "").length < 3
    }

    isShowingSearchResults(): boolean {
        return this.currentSearch !== undefined
    }

    filterValue(): StockCountFilterType {
        return this.filter
    }

    filterValueSelected(data: any): boolean {
        // If not new
        if (data.length === 0) {
            return false
        }

        // Remove already selected
        const strings: string[] = data
        _.remove(strings, (val) => { return val === this.filter })

        // get remaining
        const identifier = _.head(strings) as StockCountFilterType
        if (identifier === this.filter) {
            return false
        }

        // reset state and start load
        this.page = 0
        this.filter = identifier
        this.lineItemIdPagingStack = []
        this.observeNextStockCountLines()

        return true
    }

    performSearch() {
        // turn off query service
        this.stockCountQueryservice.stop()

        const text = this.searchText
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            count_id: this.stockCountId,
            search_term: text,
            action: "search"
        }
        const search = firebase.functions().httpsCallable("StockCount-client")
        search(args)
            .then((value) => {
                const success = value.data.success
                const result = value.data.result
                if (!success || typeof result !== "object") {
                    throw new Error("Could not perform search")
                }
                if (result.length === 0) {
                    throw new Error(`No lines found matching: ${text}`)
                }
                if (this.searchCompleted) {
                    this.currentSearch = new StockCountCurrentViewModelSearch(text)
                    const lineItems: StockCountLineItem[] = result.map((item: any) => { 
                        const lineItem = new StockCountLineItem(item) 
                        lineItem.product = new StockCountProductInfo(item.product_data, lineItem.product.productId, lineItem.product.variantId)
                        return lineItem
                    })
                    this.searchCompleted(buildStockCountLines(lineItems, pageLimit))
                }
            })
            .catch((error: Error) => {
                if (this.searchCompleted) {
                    this.searchCompleted(undefined, error.message)
                }
            })
    }

    searchTextChanged(text: string) {
        this.searchText = text
    }

    searchTextValue(): string {
        return this.searchText
    }

    disconnectDeleted() {
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "disconnect_deleted"
        }
        const reset = firebase.functions().httpsCallable("StockCount-client")
        reset(args)
            .then((result: any) => {
                if (!result.data.success) {
                    throw new Error("Could not perform operation")
                }
                if (this.setDisconnectDeletedCompleted) {
                    this.setDisconnectDeletedCompleted()
                }
            })
            .catch((error) => {
                if (this.setDisconnectDeletedCompleted) {
                    this.setDisconnectDeletedCompleted(error.message)
                }
            })
    }

    countProductsWithZeroStock() {
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "reset_zero_counts"
        }
        const reset = firebase.functions().httpsCallable("StockCount-client")
        reset(args)
            .then((result: any) => {
                if (!result.data.success) {
                    throw new Error("Could not perform operation")
                }
                if (this.countProductsWithZeroStockCompleted) {
                    this.countProductsWithZeroStockCompleted()
                }
            })
            .catch((error) => {
                if (this.countProductsWithZeroStockCompleted) {
                    this.countProductsWithZeroStockCompleted(error.message)
                }
            })
    }

    setUncountedToZero() {
        const args = {
            account_id: this.accountId,
            shop_id: this.shopId,
            stock_location_id: this.shopId,
            action: "reset_uncounted"
        }
        const reset = firebase.functions().httpsCallable("StockCount-client")
        reset(args)
            .then((result: any) => {
                if (!result.data.success) {
                    throw new Error("Could not perform operation")
                }
                if (this.setUncountedToZeroCompleted) {
                    this.setUncountedToZeroCompleted()
                }
            })
            .catch((error) => {
                if (this.setUncountedToZeroCompleted) {
                    this.setUncountedToZeroCompleted(error.message)
                }
            })
    }

    startLoadOfName() {
        const path = `v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/count_index/${this.stockCountId}/name`
        ref().child(path).once("value")
            .then((snapshot) => {
                if (!snapshot.exists()) {
                    return
                }
                if (this.nameLoaded) {
                    this.nameLoaded(snapshot.val())
                }
            })
            .catch((error) => {
                console.error(error)
            })
    }

    stopObserving() {
        this.stockCountQueryservice.stop()
        this.devicesRef().off()
    }

    observeCurrentStockCountLines() {
        this.lineItemIdPagingStack.pop()
        const fromLineItemId = _.last(this.lineItemIdPagingStack)
        this.observeBatchOfStockCountLineItems(fromLineItemId, StockCountCurrentUpdateType.ObservationChange)
    }

    observeNextStockCountLines() {
        const fromLineItemId = _.last(this.lineItemIdPagingStack)
        this.observeBatchOfStockCountLineItems(fromLineItemId, StockCountCurrentUpdateType.Next)
    }

    observePreviousStockCountLines() {
        this.lineItemIdPagingStack.pop()
        this.lineItemIdPagingStack.pop()
        this.observeBatchOfStockCountLineItems(_.last(this.lineItemIdPagingStack), StockCountCurrentUpdateType.Previous)
    }

    observeDevices() {
        this.devicesRef().on("value", (snapshot: firebase.database.DataSnapshot) => {
            const backofficeDeviceJSON = {
                active: true,
                device_id: StockCountCurrentViewModel.backofficeDeviceId,
                device_name: "Back Office",
                sessions: []
            }
            if (!snapshot.exists()) {
                this.devices = [new StockCountDevice(backofficeDeviceJSON)]
            } else {
                const devicesData = snapshot.val()
                this.devices = Object.values(devicesData).map((d: any) => {
                    return new StockCountDevice(d)
                })
                const backofficeDevice = new StockCountDevice(backofficeDeviceJSON)
                this.devices.push(backofficeDevice)
            }
            this.devicesChanged(this.devices)
        })
    }

    devicesRef(): firebase.database.Reference {
        const path = `/v1/accounts/${this.accountId}/stock_locations/${this.shopId}/inventory/stock_counts/counts/${this.stockCountId}/devices`
        return ref().child(path)
    }

    // Private methods

    private observeBatchOfStockCountLineItems(fromLineItem: StockCountLineItem | undefined, type: StockCountCurrentUpdateType) {
        // turn off current query service and create new
        this.stockCountQueryservice.stop() 
        this.stockCountQueryservice = new StockCountLinesQueryService(pageLimit, this.filter, this.accountId, this.shopId, this.stockCountId)

        // capture update type
        let updateType = type

        this.stockCountQueryservice.linesChangedCallback = (values) => {
            if (_.isNil(values)) {
                if (this.stockCountLinesChanged) {
                    this.stockCountLinesChanged([])
                }
                return
            }

            // build lines with captured update type and "reset" it to ObservationChange afterwards
            const lines = this.buildStockCountLinesAndUpdatePaging(values, updateType)
            updateType = StockCountCurrentUpdateType.ObservationChange

            if (this.stockCountLinesChanged) {
                this.stockCountLinesChanged(lines)
            }
        }

        // start it up
        this.stockCountQueryservice.start(fromLineItem)
    }

    private buildStockCountLinesAndUpdatePaging(lineItems: StockCountLineItem[], type: StockCountCurrentUpdateType): StockCountLine[] {
        // side effect - updating paging state if the update comes from pressing next or previous
        if (type !== StockCountCurrentUpdateType.ObservationChange) {
            this.updateIndexIdPagingStack(lineItems, type === StockCountCurrentUpdateType.Next)
        }

        return buildStockCountLines(lineItems, pageLimit)
    }

    private updateIndexIdPagingStack(lineItems: StockCountLineItem[], isNext: boolean) {
        this.page += isNext ? 1 : -1
        this.nextDisabled = lineItems.length < pageLimit
        if (!this.nextDisabled) {
            const lastLineItem = _.last(lineItems)
            if (lastLineItem) {
                this.lineItemIdPagingStack.push(lastLineItem)
            }
        }
        this.previousDisabled = this.page === 1
    }

}
