import * as _ from "lodash"
import * as React from "react"
import {
    Alert,
    Button,
    ButtonToolbar,
    Checkbox,
    Col,

    DescriptionCol,
    Form,
    FormControlStatic,
    FormGroup,
    Card,
} from "../wrappers"
import {
    Attribute,
    AttributeGroup
} from "../../models/Product"
import firebase from "firebase/compat"
import { L10nFormControl } from "../L10nFormControl"
import {
    L10nString,
    LanguageCode
} from "../../helpers/L10n"
import { LanguagePicker } from "../LanguagePicker"
import { PageState } from "../PageState"
import { publish } from "../../helpers/ModelPublisher"
import { ref } from "../../config/constants"
import { Role } from "../../config/role"
import { ValidatingIdEntryControl } from "../ValidatingIdEntryControl"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { StripedTable } from "../StripedTable"
import { RoleRouterProps, withRoleRouter } from "src/routes"
import { Description } from "./DiscountRules/Customizable/CustomizableFormDescription"
import { Row } from "react-bootstrap"

// these css styles are a feeble attempt to make the table rows not look too bad while being dragged....

const narrowStyle = {
    width: "10%",
    textAlign: "center" as "center"
}

const nameStyle = {
    width: "80%",
    textAlign: "left" as "left"
}

interface AttributeGroupEditProps extends RoleRouterProps {
    currentLanguage?: LanguageCode
}

interface AttributeGroupEditState {
    attributeGroup: AttributeGroup
    identifier: string
    loaded: boolean
    dirty: boolean
    publishing: boolean
    currentLanguage: LanguageCode | null
    error: string | null
    attributes: Attribute[]
    attributeDict: _.Dictionary<Attribute>
}

class AttributeGroupEdit extends React.Component<AttributeGroupEditProps, AttributeGroupEditState> {
    constructor(props: AttributeGroupEditProps) {
        super(props)
        this.state = {
            attributeGroup: new AttributeGroup({
                id: "",
                name: "",
                initiallyCollapsed: false
            }),
            identifier: "",
            currentLanguage: props.currentLanguage || null,
            loaded: false,
            dirty: false,
            publishing: false,
            error: null,
            attributes: [],
            attributeDict: {}
        }
    }

    // State

    loadState() {
        this.attributesRef()
            .once("value", snapshot => {
                if (!snapshot || (snapshot && !snapshot.exists())) {
                    this.setState({ attributes: [], loaded: true })
                    return
                }

                const attributes: Attribute[] = []
                const attributeDict: _.Dictionary<Attribute> = {}
                _.forEach(snapshot.val(), (value) => {
                    const attribute = new Attribute(value)
                    attributes.push(attribute)
                    attributeDict[attribute.id] = attribute
                })

                const sorted = attributes.sort((lhs, rhs) => {
                    return lhs.name.localized(this.state.currentLanguage) < rhs.name.localized(this.state.currentLanguage) ? -1 : 1
                })

                this.setState({
                    attributes: sorted,
                    attributeDict: attributeDict,
                    loaded: true
                })
            })
    }

    attributesRef(): firebase.database.Reference {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/attributes")
    }

    pop() {
        const path = `/attribute_groups`
        this.props.router.navigate(path)
    }

    attributeGroupKey() {
        return this.props.router.params.attributeGroupKey
    }

    isNewAttributeGroup() {
        return this.attributeGroupKey() === "new"
    }

    isPublishEnabled() {
        if (!this.state.dirty) {
            return false
        }
        if (this.state.attributeGroup.name.hasEmptyLocalizations()) {
            return false
        }

        return true
    }

    attributeGroupsRef(): firebase.database.Reference {
        return ref().child(`v1/accounts/${this.props.role.account_id}`).child("inventory/attribute_groups")
    }

    async publish() {
        const json = this.state.attributeGroup.json()
        this.setState({ publishing: true })

        try {
            await publish(json, "id", this.state.identifier, this.isNewAttributeGroup(), this.attributeGroupsRef())
        } catch (error) {
            this.setState({ error: (error as Error).message, publishing: false })
            return
        }

        this.pop()
    }

    async componentDidMount() {
        this.setState({ loaded: false })

        if (!this.isNewAttributeGroup()) {
            const snapshot = await this.attributeGroupsRef()
                .child(this.attributeGroupKey())
                .once("value")
            const attributeGroup = new AttributeGroup(snapshot.val())
            this.setState({ attributeGroup: attributeGroup, identifier: attributeGroup.id })
        }

        this.loadState()
    }

    resolveLanguages = (): LanguageCode[] => {
        return this.state.attributeGroup.name.localizations()
    }

    setLanguage = (language: LanguageCode | null) => {
        this.setState({ currentLanguage: language })
    }

    removeLanguage = (language: LanguageCode) => {
        const attributeGroup = _.cloneDeep(this.state.attributeGroup)
        attributeGroup.name.removeLocalization(language)
        this.setState({ attributeGroup: attributeGroup, dirty: true, currentLanguage: null })
    }

    update(performUpdate: (group: AttributeGroup) => void) {
        const clone = _.cloneDeep(this.state.attributeGroup)
        performUpdate(clone)
        this.setState({ attributeGroup: clone, dirty: true })
    }

    handleInputChange = (l10n: L10nString | null) => {
        this.update(group => {
            group.name = l10n || new L10nString("")
        })
    }

    handleIdChange(identifier: string) {
        this.update(group => {
            group.id = identifier
        })
        this.setState({ error: null, identifier: identifier })
    }

    handleInitiallyCollapsedChanged(newValue: boolean) {
        this.update(group => {
            group.initiallyCollapsed = newValue
        })
    }

    onRemoveAttribute = (attributeId: string) => {
        this.update(group => {
            const index = group.attributes.indexOf(attributeId)
            if (index >= 0) {
                group.attributes.splice(index, 1)
            }
        })
    }

    onDragEnd = (reorder: any) => {
        // dropped outside the list
        if (!reorder.destination || reorder.destination.index === reorder.source.index) {
            return
        }

        // no movement
        if (reorder.destination.index === reorder.source.index) {
            return
        }

        this.update(group => {
            const [removed] = group.attributes.splice(reorder.source.index, 1)
            group.attributes.splice(reorder.destination.index, 0, removed)
        })
    }

    onAttributeSelect = (attributeId: string) => {
        this.update(group => {
            group.attributes.push(attributeId)
        })
    }

    render() {
        return (
            <PageState
                loading={!this.state.loaded}
                publishing={this.state.publishing}
                dirty={this.state.dirty}
                typeName="attribute group"
            >
                <Form>

                    <div className="float-sm-end">
                        <LanguagePicker
                            typeName="attribute group"
                            initialLanguage={this.state.currentLanguage}
                            resolveLanguages={this.resolveLanguages}
                            onChange={this.setLanguage}
                            onRemove={this.removeLanguage}
                        />
                    </div>
                    <br />

                    <Card className="my-4">
                        <Card.Header>{this.isNewAttributeGroup() ? "Create new attribute group" : `Edit attribute group '${this.state.attributeGroup.name.localized(this.state.currentLanguage)}'`}</Card.Header>
                        <Card.Body>
                            <span key="a">
                                <FormGroup className="mb-3" as={Row}>
                                    <DescriptionCol sm={2}>Name</DescriptionCol>
                                    <Col sm={10}>
                                        <L10nFormControl
                                            l10n={this.state.attributeGroup.name}
                                            placeholder="Enter localized name"
                                            language={this.state.currentLanguage}
                                            onLocalizationChanged={l10n => { this.handleInputChange(l10n) }}
                                        />
                                    </Col>
                                </FormGroup>
                                {
                                    !this.isNewAttributeGroup()
                                        ? (
                                            <FormGroup className="mb-3" as={Row}>
                                                <DescriptionCol sm={2}>Identifier</DescriptionCol>
                                                <Col sm={10}>
                                                    <FormControlStatic>{this.state.attributeGroup.id}</FormControlStatic>
                                                </Col>
                                            </FormGroup>
                                        ) : (
                                            <ValidatingIdEntryControl
                                                collectionRef={this.attributeGroupsRef()}
                                                isNew={this.isNewAttributeGroup()}
                                                typeName="attribute group"
                                                identifierSource={this.state.attributeGroup.name.localized(this.state.currentLanguage)}
                                                existingIdentifier={this.state.identifier}
                                                handleIdChange={(id, valid) => { this.handleIdChange(id) }}
                                            />
                                        )
                                }
                                <FormGroup className="mb-3" as={Row}>
                                    <DescriptionCol sm={2}>Initially collapsed</DescriptionCol>
                                    <Col sm={10}>
                                        <Checkbox
                                            checked={this.state.attributeGroup.initiallyCollapsed}
                                            onChange={event => {
                                                this.handleInitiallyCollapsedChanged((event as any).target.checked)
                                            }}
                                            label={"Let the group be collapsed by default"}
                                        />
                                    </Col>
                                </FormGroup>

                            </span>
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(droppableProvided) => (
                                        <div ref={droppableProvided.innerRef}>
                                            <StripedTable>
                                                <thead>
                                                    <tr>
                                                        <th style={narrowStyle}>Order</th>
                                                        <th style={nameStyle}>Name</th>
                                                        <th style={narrowStyle}>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.attributeGroup.attributes.map((attributeId, index) => {
                                                        const attribute = this.state.attributeDict[attributeId]
                                                        const dataToDisplay = !_.isNil(attribute) ? attribute.name.localized(LanguageCode.da) : attributeId
                                                        return (
                                                            <Draggable key={`${attributeId}`} draggableId={`${attributeId}`} index={index}>
                                                                {(draggableProvided: any) => (
                                                                    <tr
                                                                        ref={draggableProvided.innerRef}
                                                                        key={`${attributeId}`}
                                                                        {...draggableProvided.draggableProps}
                                                                        {...draggableProvided.dragHandleProps}
                                                                    >
                                                                        <td style={narrowStyle}>{index + 1}</td>
                                                                        <td style={nameStyle}>
                                                                            {dataToDisplay}
                                                                        </td>
                                                                        <td style={narrowStyle}>
                                                                            <Button
                                                                                variant="danger"
                                                                                onClick={(event) => {
                                                                                    event.stopPropagation()
                                                                                    this.onRemoveAttribute(attributeId)
                                                                                }}
                                                                            >
                                                                                X
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    })
                                                    }
                                                </tbody>
                                            </StripedTable>
                                            {droppableProvided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            {
                                this.state.attributes.length > 0 ? (
                                    <ButtonToolbar>
                                        {
                                            this.state.attributes.map((attribute, index) => {
                                                if (this.state.attributeGroup.attributes.includes(attribute.id)) {
                                                    return null
                                                }
                                                return (
                                                    <Button
                                                        style={{ marginBottom: "6px", marginRight: "6px" }}
                                                        key={attribute.id}
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            this.onAttributeSelect(attribute.id)
                                                        }}
                                                    >
                                                        {attribute.name.localized(LanguageCode.da)}
                                                    </Button>
                                                )
                                            })
                                        }
                                    </ButtonToolbar>
                                ) : (
                                    <div>
                                        {
                                            this.state.attributeGroup.attributes.length > 0
                                                ?
                                                "All attributes are used"
                                                :
                                                "No attributes defined"
                                        }
                                    </div>
                                )
                            }
                        </Card.Body>
                        <Card.Footer><Button onClick={() => this.publish()} disabled={!this.isPublishEnabled()}>Publish</Button></Card.Footer>
                    </Card>
                </Form>
                {
                    this.state.error ? (
                        <Alert variant="danger">
                            <strong>Error publishing attribute group:</strong>
                            {this.state.error}
                        </Alert>
                    ) : []
                }
            </PageState>
        )
    }
}

export default withRoleRouter(AttributeGroupEdit)
